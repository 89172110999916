import Calendar from '../../ui/Calendar';

interface DateSelectorProps {
  dateOption: 'custom' | 'suggested';
  customDates: {
    checkIn: string;
    checkOut: string;
  };
  suggestedDates: {
    start: string;
    end: string;
    reason: string;
  };
  onDateOptionChange: (option: 'custom' | 'suggested') => void;
  onCustomDateChange: (type: 'checkIn' | 'checkOut', value: string) => void;
}

export default function DateSelector({
  dateOption,
  customDates,
  suggestedDates,
  onDateOptionChange,
  onCustomDateChange,
}: DateSelectorProps) {
  return (
    <div className="space-y-4">
      <div className="flex gap-4">
        <button
          onClick={() => onDateOptionChange('suggested')}
          className={`
            flex-1 p-3 rounded-lg border transition-all duration-200
            ${dateOption === 'suggested'
              ? 'border-accent-500 bg-accent-500/10'
              : 'border-white/10 hover:border-accent-500/50'
            }
          `}
        >
          <h5 className="font-semibold text-white mb-1">Suggested Dates</h5>
          <p className="text-sm text-gray-400">{suggestedDates.reason}</p>
        </button>
        <button
          onClick={() => onDateOptionChange('custom')}
          className={`
            flex-1 p-3 rounded-lg border transition-all duration-200
            ${dateOption === 'custom'
              ? 'border-accent-500 bg-accent-500/10'
              : 'border-white/10 hover:border-accent-500/50'
            }
          `}
        >
          <h5 className="font-semibold text-white mb-1">Custom Dates</h5>
          <p className="text-sm text-gray-400">Choose your own travel dates</p>
        </button>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <label className="block text-sm text-gray-400">Check-in</label>
          {dateOption === 'suggested' ? (
            <input
              type="text"
              value={new Date(suggestedDates.start).toLocaleDateString()}
              readOnly
              className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white"
            />
          ) : (
            <Calendar
              value={customDates.checkIn}
              onChange={(date) => onCustomDateChange('checkIn', date)}
              min={new Date().toISOString().split('T')[0]}
            />
          )}
        </div>
        <div className="space-y-2">
          <label className="block text-sm text-gray-400">Check-out</label>
          {dateOption === 'suggested' ? (
            <input
              type="text"
              value={new Date(suggestedDates.end).toLocaleDateString()}
              readOnly
              className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white"
            />
          ) : (
            <Calendar
              value={customDates.checkOut}
              onChange={(date) => onCustomDateChange('checkOut', date)}
              min={customDates.checkIn || new Date().toISOString().split('T')[0]}
            />
          )}
        </div>
      </div>
    </div>
  );
}