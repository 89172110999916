import { DollarSign, Calendar, Hotel, Clock, Activity, MapPin } from 'lucide-react';
import type { DestinationDetails } from '../../types/itinerary';

interface ItineraryDetailsProps {
  destination: DestinationDetails;
}

export default function ItineraryDetails({ destination }: ItineraryDetailsProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      {/* Budget & Best Time */}
      <div className="space-y-4">
        <div className="flex items-center gap-2 text-accent-400">
          <DollarSign className="h-5 w-5" />
          <span className="font-medium">Budget</span>
        </div>
        <p className="text-gray-300 pl-7">{destination.estimatedBudget}</p>
        
        <div className="flex items-center gap-2 text-accent-400">
          <Calendar className="h-5 w-5" />
          <span className="font-medium">Best Time to Visit</span>
        </div>
        <p className="text-gray-300 pl-7">{destination.bestTimeToVisit}</p>
      </div>

      {/* Accommodation & Duration */}
      <div className="space-y-4">
        <div className="flex items-center gap-2 text-accent-400">
          <Hotel className="h-5 w-5" />
          <span className="font-medium">Accommodation</span>
        </div>
        <p className="text-gray-300 pl-7">{destination.accommodation}</p>

        <div className="flex items-center gap-2 text-accent-400">
          <Clock className="h-5 w-5" />
          <span className="font-medium">Recommended Duration</span>
        </div>
        <p className="text-gray-300 pl-7">{destination.recommendedDuration}</p>
      </div>
    </div>
  );
}