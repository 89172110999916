import { CheckCircle, XCircle, Info, AlertTriangle, X } from 'lucide-react';
import { useEffect, useState } from 'react';

interface AlertProps {
  type: 'success' | 'error' | 'info' | 'warning';
  message: string;
  onClose?: () => void;
  autoClose?: boolean;
  duration?: number;
}

const icons = {
  success: CheckCircle,
  error: XCircle,
  info: Info,
  warning: AlertTriangle
};

const styles = {
  success: 'bg-emerald-500/90 border-emerald-500 text-white',
  error: 'bg-red-500/90 border-red-500 text-white',
  info: 'bg-blue-500/90 border-blue-500 text-white',
  warning: 'bg-yellow-500/90 border-yellow-500 text-white'
};

export default function Alert({ 
  type, 
  message, 
  onClose, 
  autoClose = true, 
  duration = 5000 
}: AlertProps) {
  const [isVisible, setIsVisible] = useState(true);
  const Icon = icons[type];

  useEffect(() => {
    if (autoClose) {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose?.();
      }, duration);

      return () => clearTimeout(timer);
    }
  }, [autoClose, duration, onClose]);

  if (!isVisible) return null;

  return (
    <div 
      className="fixed left-1/2 top-4 -translate-x-1/2 z-[9999] w-full max-w-sm px-4"
      style={{ position: 'fixed' }}
    >
      <div className={`flex items-center gap-3 p-4 rounded-lg border backdrop-blur-md shadow-lg ${styles[type]}`}>
        <Icon className="h-5 w-5 flex-shrink-0" />
        <p className="flex-1 font-medium text-sm">{message}</p>
        {onClose && (
          <button
            onClick={() => {
              setIsVisible(false);
              onClose();
            }}
            className="p-1 hover:bg-black/10 rounded-lg transition-colors"
          >
            <X className="h-4 w-4" />
          </button>
        )}
      </div>
    </div>
  );
}