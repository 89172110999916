import { useState, useEffect } from 'react';
import { getArticles, getCategoryArticles, getArticle } from '../services/blog';
import type { IArticle } from '../types/blog';

interface UseBlogParams {
  initialPage?: number;
  pageSize?: number;
  category?: string | null;
}

interface BlogState {
  articles: IArticle[];
  total: number;
  isLoading: boolean;
  error: string | null;
}

export function useBlog({ initialPage = 0, pageSize = 10, category = null }: UseBlogParams = {}) {
  const [state, setState] = useState<BlogState>({
    articles: [],
    total: 0,
    isLoading: true,
    error: null
  });
  const [currentPage, setCurrentPage] = useState(initialPage);

  useEffect(() => {
    loadArticles();
  }, [currentPage, category]);

  const loadArticles = async () => {
    try {
      setState(prev => ({ ...prev, isLoading: true, error: null }));
      
      const response = category
        ? await getCategoryArticles(category, currentPage, pageSize)
        : await getArticles(currentPage, pageSize);

      // Load full article content for each article
      const fullArticles = await Promise.all(
        response.articles.map(async article => {
          try {
            const fullArticle = await getArticle(article.slug);
            return fullArticle;
          } catch (error) {
            console.error(`Error loading full article for ${article.slug}:`, error);
            return article;
          }
        })
      );
      
      setState(prev => ({
        articles: currentPage === 0 ? fullArticles : [...prev.articles, ...fullArticles],
        total: response.total,
        isLoading: false,
        error: null
      }));
    } catch (err) {
      setState(prev => ({
        ...prev,
        isLoading: false,
        error: err instanceof Error ? err.message : 'Failed to load articles'
      }));
    }
  };

  const loadMore = () => {
    if (!state.isLoading && hasMorePages) {
      setCurrentPage(prev => prev + 1);
    }
  };

  const refresh = () => {
    setCurrentPage(0);
    setState(prev => ({ ...prev, articles: [] }));
    loadArticles();
  };

  const hasMorePages = state.articles.length < state.total;

  return {
    ...state,
    hasMore: hasMorePages,
    loadMore,
    refresh,
    currentPage
  };
}