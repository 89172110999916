import { Link } from 'react-router-dom';
import { List, ArrowRight } from 'lucide-react';
import type { IRelatedPost, ITag } from '../../types/blog';

interface ArticleSidebarProps {
  outline: string;
  relatedPosts: IRelatedPost[];
  tags: ITag[];
}

export default function ArticleSidebar({ outline, relatedPosts, tags }: ArticleSidebarProps) {
  return (
    <aside className="space-y-8">
      {/* Table of Contents */}
      <div className="bg-white/5 rounded-xl p-6">
        <div className="flex items-center gap-2 text-accent-400 mb-4">
          <List className="h-5 w-5" />
          <h2 className="font-medium">Table of Contents</h2>
        </div>
        <div
          className="prose prose-invert max-w-none prose-a:text-gray-400 prose-a:no-underline hover:prose-a:text-white prose-a:transition-colors"
          dangerouslySetInnerHTML={{ __html: outline }}
        />
      </div>

      {/* Related Posts */}
      {relatedPosts.length > 0 && (
        <div className="bg-white/5 rounded-xl p-6">
          <h2 className="font-medium text-white mb-4">Related Articles</h2>
          <div className="space-y-4">
            {relatedPosts.map(post => (
              <Link
                key={post.id}
                to={`/blog/${post.slug}`}
                className="block group"
              >
                <h3 className="text-gray-300 group-hover:text-white transition-colors">
                  {post.headline}
                </h3>
              </Link>
            ))}
          </div>
        </div>
      )}

      {/* Tags */}
      {tags.length > 0 && (
        <div className="bg-white/5 rounded-xl p-6">
          <h2 className="font-medium text-white mb-4">Tags</h2>
          <div className="flex flex-wrap gap-2">
            {tags.map(tag => (
              <Link
                key={tag.id}
                to={`/blog/tag/${tag.slug}`}
                className="px-3 py-1 bg-accent-500/10 text-accent-400 rounded-full text-sm hover:bg-accent-500/20 transition-colors"
              >
                {tag.title}
              </Link>
            ))}
          </div>
        </div>
      )}
    </aside>
  );
}