import { useState } from 'react';
import { Utensils, X, ChevronDown, ChevronUp, MapPin, ExternalLink } from 'lucide-react';
import type { DiningResult } from '../../types/dining';
import StarRating from '../ui/StarRating';

interface SavedDiningListProps {
  dining: DiningResult[];
  onDelete?: (diningId: string) => void;
}

export default function SavedDiningList({ dining, onDelete }: SavedDiningListProps) {
  const [expandedId, setExpandedId] = useState<string | null>(null);

  if (!dining?.length) return null;

  return (
    <div className="mt-2 space-y-2">
      {dining.map((venue) => (
        <div
          key={venue._id || `${venue.name}-${venue.savedAt}`}
          className="bg-white/5 rounded-lg hover:bg-white/10 transition-colors group"
        >
          {/* Header */}
          <div className="flex items-center justify-between p-2">
            <button
              onClick={() => setExpandedId(expandedId === venue._id ? null : venue._id)}
              className="flex items-center gap-2 flex-1"
            >
              <Utensils className="h-4 w-4 text-accent-400" />
              <span className="text-gray-300 group-hover:text-white transition-colors">
                {venue.name}
              </span>
              {expandedId === venue._id ? (
                <ChevronUp className="h-4 w-4 text-gray-400" />
              ) : (
                <ChevronDown className="h-4 w-4 text-gray-400" />
              )}
            </button>
            {onDelete && (
              <button
                onClick={() => onDelete(venue._id)}
                className="p-1 text-gray-400 hover:text-red-400 hover:bg-red-500/10 rounded transition-colors"
                title="Remove dining venue"
              >
                <X className="h-3.5 w-3.5" />
              </button>
            )}
          </div>

          {/* Expanded Content */}
          {expandedId === venue._id && (
            <div className="px-4 pb-4 space-y-4 border-t border-white/10">
              {/* Basic Info */}
              <div className="flex items-center justify-between pt-4">
                <div className="space-y-1">
                  <div className="flex items-center gap-2">
                    <span className="text-accent-400">{venue.cuisine}</span>
                    <span className="text-gray-400">•</span>
                    <span className="text-white font-medium">{venue.priceRange}</span>
                  </div>
                  <StarRating value={venue.rating} readonly size="sm" onChange={() => {}} />
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-400">
                  <MapPin className="h-4 w-4" />
                  <span>{venue.location}</span>
                </div>
              </div>

              {/* Description */}
              <p className="text-sm text-gray-300">{venue.description}</p>

              {/* Specialties */}
              <div className="space-y-2">
                <h4 className="text-sm font-medium text-white">Signature Dishes:</h4>
                <div className="flex flex-wrap gap-2">
                  {venue.specialties.map((specialty, index) => (
                    <span
                      key={`${venue._id}-specialty-${index}`}
                      className="px-2 py-1 bg-accent-500/10 text-accent-400 rounded-lg text-sm"
                    >
                      {specialty}
                    </span>
                  ))}
                </div>
              </div>

              {/* Links */}
              <div className="space-y-2">
                <h4 className="text-sm font-medium text-white">Reservations & Reviews:</h4>
                <div className="grid gap-2">
                  {venue.links.map((link, index) => (
                    <a
                      key={`${venue._id}-link-${index}`}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-between p-2 bg-white/5 hover:bg-white/10 rounded-lg transition-colors group"
                    >
                      <span className="text-sm text-gray-300 group-hover:text-white">
                        {link.title}
                      </span>
                      <ExternalLink className="h-3.5 w-3.5 text-gray-500 group-hover:text-accent-400" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}