import { Star } from 'lucide-react';
import { useState } from 'react';

interface StarRatingProps {
  value: number;
  onChange: (rating: number) => void;
  size?: 'sm' | 'md' | 'lg';
  readonly?: boolean;
}

export default function StarRating({ 
  value, 
  onChange, 
  size = 'md',
  readonly = false 
}: StarRatingProps) {
  const [hoverRating, setHoverRating] = useState(0);

  const sizes = {
    sm: 'h-4 w-4',
    md: 'h-5 w-5',
    lg: 'h-6 w-6'
  };

  return (
    <div className="flex items-center gap-1">
      {[1, 2, 3, 4, 5].map((rating) => (
        <button
          key={rating}
          type="button"
          onClick={() => !readonly && onChange(rating)}
          onMouseEnter={() => !readonly && setHoverRating(rating)}
          onMouseLeave={() => !readonly && setHoverRating(0)}
          disabled={readonly}
          className={`
            transition-all duration-200
            ${readonly ? 'cursor-default' : 'cursor-pointer hover:scale-110'}
          `}
        >
          <Star
            className={`
              ${sizes[size]}
              ${rating <= (hoverRating || value)
                ? 'fill-yellow-400 text-yellow-400'
                : 'text-gray-400'
              }
              transition-colors duration-200
            `}
          />
        </button>
      ))}
    </div>
  );
}