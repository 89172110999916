import { MapPin } from 'lucide-react';

interface LocalTipsProps {
  tips: string[];
}

export default function LocalTips({ tips }: LocalTipsProps) {
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2 text-yellow-400">
        <MapPin className="h-5 w-5" />
        <span className="font-medium">Local Tips</span>
      </div>
      <div className="grid gap-3">
        {tips.map((tip, index) => (
          <div
            key={index}
            className="flex items-start gap-3 p-3 bg-white/5 rounded-lg"
          >
            <span className="h-2 w-2 rounded-full bg-yellow-400 mt-2 flex-shrink-0" />
            <p className="text-gray-300">{tip}</p>
          </div>
        ))}
      </div>
    </div>
  );
}