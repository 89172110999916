import { ExternalLink, X, Trash2 } from 'lucide-react';
import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';

interface SavedActivity {
  _id: string;
  title: string;
  description: string;
  price?: string;
  duration?: string;
  images: string[];
  links: Array<{
    title: string;
    url: string;
    _id: string;
  }>;
  savedAt: string;
}

interface SavedActivitiesProps {
  activities: SavedActivity[];
  onClose: () => void;
  onDelete?: (activityId: string) => void;
}

export default function SavedActivities({ activities, onClose, onDelete }: SavedActivitiesProps) {
  const { user } = useAuth();
  const [deletingId, setDeletingId] = useState<string | null>(null);

  const handleDelete = async (activityId: string) => {
    if (!user?.googleId) return;
    
    setDeletingId(activityId);
    try {
      await axios.delete(`/api/users/activities/${activityId}`, {
        headers: { Authorization: `Bearer ${user.googleId}` }
      });
      onDelete?.(activityId);
    } catch (error) {
      console.error('Error deleting activity:', error);
    } finally {
      setDeletingId(null);
    }
  };

  return (
    <div className="mt-3 space-y-4 bg-gray-800/50 rounded-xl p-4 border border-accent-500/20">
      <div className="flex items-center justify-between">
        <h4 className="text-sm font-medium text-accent-400">Saved Activity Suggestions</h4>
        <button
          onClick={onClose}
          className="p-1.5 text-gray-400 hover:text-white transition-colors rounded-lg hover:bg-white/10"
        >
          <X className="h-4 w-4" />
        </button>
      </div>

      <div className="space-y-6">
        {activities.map((activity) => (
          <div key={activity._id} className="bg-white/5 rounded-lg overflow-hidden">
            {/* Images */}
            <div className="relative h-32 overflow-hidden">
              <div className="flex h-full">
                {activity.images.slice(0, 3).map((image, imgIndex) => (
                  <div
                    key={imgIndex}
                    className="flex-1 relative first:rounded-tl-lg last:rounded-tr-lg overflow-hidden"
                  >
                    <img
                      src={image}
                      alt={`${activity.title} - Image ${imgIndex + 1}`}
                      className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 hover:scale-110"
                    />
                  </div>
                ))}
              </div>
            </div>

            {/* Content */}
            <div className="p-4 space-y-3">
              <div className="flex items-start justify-between gap-4">
                <div>
                  <h5 className="font-medium text-white mb-1">{activity.title}</h5>
                  <p className="text-sm text-gray-300">{activity.description}</p>
                </div>
                <button
                  onClick={() => handleDelete(activity._id)}
                  disabled={deletingId === activity._id}
                  className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-500/10 rounded-lg transition-colors"
                  title="Delete saved activity"
                >
                  <Trash2 className={`h-4 w-4 ${deletingId === activity._id ? 'animate-pulse' : ''}`} />
                </button>
              </div>

              {/* Additional Info */}
              <div className="flex flex-wrap gap-2">
                {activity.price && (
                  <span className="px-2 py-0.5 bg-emerald-500/10 text-emerald-400 rounded text-xs">
                    {activity.price}
                  </span>
                )}
                {activity.duration && (
                  <span className="px-2 py-0.5 bg-blue-500/10 text-blue-400 rounded text-xs">
                    {activity.duration}
                  </span>
                )}
              </div>

              {/* Links */}
              <div className="space-y-2">
                <h6 className="text-xs font-medium text-gray-400">Booking Options:</h6>
                <div className="grid gap-1">
                  {activity.links.map((link) => (
                    <a
                      key={link._id}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center justify-between p-2 bg-white/5 hover:bg-white/10 rounded transition-colors group text-sm"
                    >
                      <span className="text-gray-300 group-hover:text-white">
                        {link.title}
                      </span>
                      <ExternalLink className="h-3.5 w-3.5 text-gray-500 group-hover:text-accent-400" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}