import { ExternalLink, BookmarkPlus, Check } from 'lucide-react';
import { useState } from 'react';
import type { ActivityResult } from '../../../types/activities';
import { useAuth } from '../../../contexts/AuthContext';
import axios from 'axios';
import Alert from '../../ui/Alert';

interface ActivityCardProps {
  activity: ActivityResult;
  destinationName?: string;
  originalActivity?: string;
}

export default function ActivityCard({ activity, destinationName, originalActivity }: ActivityCardProps) {
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { isAuthenticated, user } = useAuth();

  const handleSaveActivity = async () => {
    if (!isAuthenticated || !user?.googleId) return;

    setIsSaving(true);
    try {
      // If we have a destinationName and it's not "nearby", save to that destination
      if (destinationName && destinationName !== 'nearby') {
        await axios.post(
          '/api/users/activities/save',
          {
            destinationName,
            originalActivity,
            activity: {
              title: activity.title,
              description: activity.description,
              price: activity.price,
              duration: activity.duration,
              images: activity.images,
              links: activity.links
            }
          },
          {
            headers: { Authorization: `Bearer ${user.googleId}` }
          }
        );
      } else {
        // Otherwise, save as a general search activity
        await axios.post(
          '/api/users/activities/search/save',
          {
            activity: {
              title: activity.title,
              description: activity.description,
              price: activity.price,
              duration: activity.duration,
              images: activity.images,
              links: activity.links
            }
          },
          {
            headers: { Authorization: `Bearer ${user.googleId}` }
          }
        );
      }
      setIsSaved(true);
      setShowAlert(true);
    } catch (error) {
      console.error('Error saving activity:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="bg-gray-900/60 backdrop-blur-sm rounded-xl overflow-hidden border border-white/10 hover:border-accent-500/30 transition-all duration-300">
      {showAlert && (
        <Alert
          type="success"
          message="Activity saved successfully!"
          onClose={() => setShowAlert(false)}
        />
      )}

      {/* Images */}
      <div className="w-full h-48 relative">
        <div className="flex h-full">
          {activity.images.slice(0, 3).map((image, imgIndex) => (
            <div 
              key={imgIndex} 
              className="flex-1 relative first:rounded-tl-xl last:rounded-tr-xl overflow-hidden"
            >
              <img
                src={image}
                alt={`${activity.title} - Image ${imgIndex + 1}`}
                className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 hover:scale-110"
              />
            </div>
          ))}
        </div>
      </div>

      {/* Content */}
      <div className="p-6 space-y-4">
        <div className="flex items-start justify-between gap-4">
          <div>
            <h4 className="text-lg font-semibold text-white mb-2">{activity.title}</h4>
            <p className="text-gray-300">{activity.description}</p>
          </div>
          <button
            onClick={handleSaveActivity}
            disabled={!isAuthenticated || isSaving || isSaved}
            className={`
              p-2 rounded-lg transition-all duration-200
              ${!isAuthenticated
                ? 'bg-gray-800/50 text-gray-600 cursor-not-allowed'
                : isSaved
                ? 'bg-emerald-500/20 text-emerald-400'
                : 'bg-accent-500/20 text-accent-400 hover:bg-accent-500/30 hover:text-accent-300'
              }
            `}
            title={
              !isAuthenticated
                ? 'Sign in to save activities'
                : isSaved
                ? 'Activity saved'
                : 'Save activity'
            }
          >
            {isSaved ? (
              <Check className="h-5 w-5" />
            ) : (
              <BookmarkPlus className={`h-5 w-5 ${isSaving ? 'animate-pulse' : ''}`} />
            )}
          </button>
        </div>

        {/* Additional Info */}
        <div className="flex flex-wrap gap-2">
          {activity.price && (
            <span className="px-3 py-1 bg-emerald-500/10 text-emerald-400 rounded-full text-sm">
              {activity.price}
            </span>
          )}
          {activity.duration && (
            <span className="px-3 py-1 bg-blue-500/10 text-blue-400 rounded-full text-sm">
              {activity.duration}
            </span>
          )}
        </div>

        {/* Links */}
        <div className="space-y-2">
          <h5 className="text-sm font-medium text-gray-400">Book or Learn More:</h5>
          <div className="grid gap-2">
            {activity.links.map((link, linkIndex) => (
              <a
                key={linkIndex}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-between p-3 bg-gray-900/60 hover:bg-gray-900/80 rounded-lg transition-colors group"
              >
                <span className="text-gray-300 group-hover:text-white">
                  {link.title}
                </span>
                <ExternalLink className="h-4 w-4 text-gray-500 group-hover:text-accent-400" />
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}