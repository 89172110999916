import { LucideIcon, X, RefreshCcw, Plus, Info, Search, Hotel, BookmarkPlus, Utensils } from 'lucide-react';
import { ReactNode, isValidElement, Children, useState, useEffect } from 'react';
import { useTripSlip } from '../../contexts/TripSlipContext';
import { generateAlternativeActivity, generateNewActivity } from '../../services/openai';
import ActivitySearch from './ActivitySearch';
import HotelSearch from './HotelSearch';
import DiningSearch from './DiningSearch';
import SavedActivities from './SavedActivities';
import SavedDining from './SavedDining';
import SavedDiningList from './SavedDiningList';
import Alert from '../ui/Alert';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import type { SavedDining as SavedDiningType } from '../../types/dining';

interface TripSlipSectionProps {
  icon: LucideIcon;
  title: string;
  content: ReactNode;
  destinationName: string;
  suggestedDates?: {
    start: string;
    end: string;
    reason: string;
  };
}

export default function TripSlipSection({
  icon: Icon,
  title,
  content,
  destinationName,
  suggestedDates
}: TripSlipSectionProps) {
  const { destinations, updateDestination, addDiningToDestination, removeDiningFromDestination } = useTripSlip();
  const { isAuthenticated, user } = useAuth();
  const destination = destinations.find(d => d.name === destinationName);
  const [selectedActivity, setSelectedActivity] = useState<string | null>(null);
  const [refreshingActivity, setRefreshingActivity] = useState<string | null>(null);
  const [isAddingActivity, setIsAddingActivity] = useState(false);
  const [showHotelSearch, setShowHotelSearch] = useState(false);
  const [showDiningSearch, setShowDiningSearch] = useState(false);
  const [showSavedActivities, setShowSavedActivities] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [savedActivities, setSavedActivities] = useState<string[]>([]);

  const isActivities = title === "Activities" && isValidElement(content) && content.type === "ul";
  const isAccommodation = title === "Accommodation";
  const isDining = title === "Dining";

  useEffect(() => {
    if (showSavedActivities && isAuthenticated && user?.googleId) {
      loadSavedActivities();
    }
  }, [showSavedActivities, isAuthenticated, user?.googleId, destinationName]);

  const loadSavedActivities = async () => {
    try {
      const response = await axios.get(`/api/users/activities/${destinationName}`, {
        headers: { Authorization: `Bearer ${user?.googleId}` }
      });
      if (response.data.length === 0) {
        setShowAlert(true);
        setShowSavedActivities(false);
      } else {
        setSavedActivities(response.data);
      }
    } catch (error) {
      console.error('Error loading saved activities:', error);
    }
  };

  const handleRemoveActivity = (activity: string) => {
    if (!destination) return;

    const updatedDestination = {
      ...destination,
      activities: destination.activities?.filter(a => a !== activity) || []
    };

    updateDestination(destinationName, updatedDestination);
  };

  const handleRefreshActivity = async (activity: string) => {
    if (!destination) return;

    setRefreshingActivity(activity);
    try {
      const newActivity = await generateAlternativeActivity(
        destinationName,
        activity,
        destination.activities || []
      );

      const updatedDestination = {
        ...destination,
        activities: destination.activities.map(a => 
          a === activity ? newActivity.replace(/["']/g, '') : a
        )
      };

      updateDestination(destinationName, updatedDestination);
    } catch (error) {
      console.error('Error refreshing activity:', error);
    } finally {
      setRefreshingActivity(null);
    }
  };

  const handleAddActivity = async () => {
    if (!destination) return;

    setIsAddingActivity(true);
    try {
      const newActivity = await generateNewActivity(
        destinationName,
        destination.activities || []
      );

      const updatedDestination = {
        ...destination,
        activities: [...(destination.activities || []), newActivity.replace(/["']/g, '')]
      };

      updateDestination(destinationName, updatedDestination);
    } catch (error) {
      console.error('Error adding new activity:', error);
    } finally {
      setIsAddingActivity(false);
    }
  };

  const handleDeleteSavedDining = async (diningId: string) => {
    try {
      await removeDiningFromDestination(destinationName, diningId);
      setShowAlert(true);
    } catch (error) {
      console.error('Error deleting dining option:', error);
    }
  };

  if (isActivities && isValidElement(content)) {
    const activitiesArray = destination?.activities || [];
    
    return (
      <>
        {showAlert && (
          <Alert
            type="info"
            message="Click the search icon on any activity to find and save detailed suggestions!"
            onClose={() => setShowAlert(false)}
            duration={5000}
          />
        )}
        <div className="space-y-3 group">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="p-2.5 rounded-xl bg-gradient-to-br from-accent-500/20 to-primary-500/20 shadow-lg group-hover:shadow-accent-500/20 transition-all duration-300">
                <Icon className="h-5 w-5 text-accent-400 group-hover:text-accent-300 transition-colors" />
              </div>
              <div className="flex items-center gap-2">
                <h4 className="text-lg font-semibold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                  {title}
                </h4>
                <div className="relative group/tooltip">
                  <Info className="h-4 w-4 text-gray-400" />
                  <div className="absolute left-1/2 -translate-x-1/2 bottom-full mb-2 px-3 py-1.5 bg-gray-800 text-xs text-gray-200 rounded-lg opacity-0 group-hover/tooltip:opacity-100 transition-opacity whitespace-nowrap pointer-events-none">
                    Tap or hover over activities to edit or remove
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2">
              {isAuthenticated && (
                <div className="relative inline-block">
                  <button
                    onClick={() => setShowSavedActivities(!showSavedActivities)}
                    className={`
                      p-1.5 rounded-lg transition-all duration-200
                      ${showSavedActivities
                        ? 'bg-accent-500/20 text-accent-400'
                        : 'text-gray-400 hover:text-accent-400 hover:bg-accent-500/10'
                      }
                    `}
                  >
                    <BookmarkPlus className="h-4 w-4" />
                  </button>
                  <div className="absolute right-0 bottom-full mb-2 w-max">
                    <div className="px-3 py-1.5 bg-gray-800 text-xs text-gray-200 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap pointer-events-none">
                      View saved activity suggestions
                    </div>
                  </div>
                </div>
              )}
              <button
                onClick={handleAddActivity}
                disabled={isAddingActivity}
                className="p-1.5 text-emerald-400 hover:text-emerald-300 hover:bg-emerald-500/10 rounded-lg transition-all duration-200 active:scale-95"
                title="Add new activity"
              >
                <Plus className={`h-4 w-4 ${isAddingActivity ? 'animate-spin' : ''}`} />
              </button>
            </div>
          </div>

          <div className="grid gap-2">
            {activitiesArray.map((activity, index) => (
              <div key={activity} className="space-y-2">
                <div 
                  className="relative flex items-center gap-3 p-3 bg-white/5 rounded-lg group/item hover:bg-white/10 transition-all duration-200"
                >
                  <span className="flex items-center justify-center w-5 h-5 rounded-full bg-gradient-to-br from-accent-400/90 to-primary-600/90 text-white text-xs font-medium shadow-lg flex-shrink-0">
                    {index + 1}
                  </span>

                  <span className="text-gray-300 group-hover/item:text-white transition-colors flex-grow pr-20">
                    {activity}
                  </span>

                  <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-0.5 bg-gray-900/80 backdrop-blur-sm rounded-lg px-0.5">
                    <button
                      onClick={() => {
                        if (selectedActivity === activity) {
                          setSelectedActivity(null);
                        } else {
                          setSelectedActivity(activity);
                        }
                      }}
                      className={`
                        p-1.5 rounded-lg transition-all duration-200
                        ${selectedActivity === activity 
                          ? 'text-blue-500 bg-blue-500/10' 
                          : 'text-gray-400 hover:text-blue-500 hover:bg-blue-500/10'
                        }
                      `}
                      title="Search for more information"
                    >
                      <Search className="h-3.5 w-3.5" />
                    </button>
                    <button
                      onClick={() => handleRefreshActivity(activity)}
                      className="p-1.5 text-gray-400 hover:text-accent-500 hover:bg-accent-500/10 rounded-lg transition-all duration-200"
                      title="Get alternative activity"
                      disabled={refreshingActivity === activity}
                    >
                      <RefreshCcw className={`h-3.5 w-3.5 ${refreshingActivity === activity ? 'animate-spin' : ''}`} />
                    </button>
                    <button
                      onClick={() => handleRemoveActivity(activity)}
                      className="p-1.5 text-gray-400 hover:text-red-500 hover:bg-red-500/10 rounded-lg transition-all duration-200"
                      title="Remove activity"
                    >
                      <X className="h-3.5 w-3.5" />
                    </button>
                  </div>
                </div>

                {selectedActivity === activity && (
                  <ActivitySearch
                    activity={activity}
                    destination={destinationName}
                    onClose={() => setSelectedActivity(null)}
                  />
                )}
              </div>
            ))}
          </div>

          {showSavedActivities && (
            <SavedActivities
              activities={savedActivities}
              onClose={() => setShowSavedActivities(false)}
            />
          )}
        </div>
      </>
    );
  }

  if (isAccommodation && suggestedDates) {
    return (
      <div className="space-y-4 group">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="p-2.5 rounded-xl bg-gradient-to-br from-accent-500/20 to-primary-500/20 shadow-lg group-hover:shadow-accent-500/20 transition-all duration-300">
              <Icon className="h-5 w-5 text-accent-400 group-hover:text-accent-300 transition-colors" />
            </div>
            <div className="flex items-center gap-2">
              <h4 className="text-lg font-semibold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                {title}
              </h4>
            </div>
          </div>
          <button
            onClick={() => setShowHotelSearch(true)}
            className="flex items-center gap-2 px-4 py-2 bg-accent-500/20 hover:bg-accent-500/30 text-accent-300 rounded-lg transition-all duration-200"
          >
            <Hotel className="h-4 w-4" />
            Book Stay
          </button>
        </div>
        <div className="text-gray-300 group-hover:text-white transition-colors">
          {content}
        </div>

        {showHotelSearch && (
          <HotelSearch
            destination={destinationName}
            onClose={() => setShowHotelSearch(false)}
            suggestedDates={suggestedDates}
            accommodation={content as string}
          />
        )}
      </div>
    );
  }

  if (isDining) {
    return (
      <div className="space-y-4 group">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-3">
            <div className="p-2.5 rounded-xl bg-gradient-to-br from-accent-500/20 to-primary-500/20 shadow-lg group-hover:shadow-accent-500/20 transition-all duration-300">
              <Icon className="h-5 w-5 text-accent-400 group-hover:text-accent-300 transition-colors" />
            </div>
            <div className="flex items-center gap-2">
              <h4 className="text-lg font-semibold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
                {title}
              </h4>
            </div>
          </div>
          <button
            onClick={() => setShowDiningSearch(!showDiningSearch)}
            className={`
              flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-200
              ${showDiningSearch
                ? 'bg-accent-500 text-white'
                : 'bg-accent-500/20 hover:bg-accent-500/30 text-accent-300'
              }
            `}
          >
            <Utensils className="h-4 w-4" />
            Find Dining
          </button>
        </div>

        <div className="text-gray-300 group-hover:text-white transition-colors">
          {content}
        </div>

        {showDiningSearch && (
          <DiningSearch
            destination={destinationName}
            onClose={() => setShowDiningSearch(false)}
            suggestedDates={suggestedDates!}
          />
        )}

        {/* Display saved dining venues */}
        {destination?.savedDining && destination.savedDining.length > 0 && (
          <SavedDiningList
            dining={destination.savedDining}
            onDelete={handleDeleteSavedDining}
          />
        )}
      </div>
    );
  }

  return (
    <div className="space-y-4 group">
      <div className="flex items-center gap-3">
        <div className="p-2.5 rounded-xl bg-gradient-to-br from-accent-500/20 to-primary-500/20 shadow-lg group-hover:shadow-accent-500/20 transition-all duration-300">
          <Icon className="h-5 w-5 text-accent-400 group-hover:text-accent-300 transition-colors" />
        </div>
        <h4 className="text-lg font-semibold bg-gradient-to-r from-white to-gray-300 bg-clip-text text-transparent">
          {title}
        </h4>
      </div>
      <div className="text-gray-300 group-hover:text-white transition-colors">
        {content}
      </div>
    </div>
  );
}