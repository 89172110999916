import { Menu, X, Compass, MapPin, Utensils, Calendar, BookOpen } from 'lucide-react';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import GoogleSignIn from './auth/GoogleSignIn';
import UserMenu from './auth/UserMenu';
import MobileNavigation from './MobileNavigation';

export default function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const navLinks = [
    { to: '/destinations', label: 'Destinations', icon: MapPin },
    { to: '/activities', label: 'Activities', icon: Compass },
    { to: '/dining', label: 'Dining', icon: Utensils },
    { to: '/itineraries', label: 'Itineraries', icon: Calendar },
    //{ to: '/blog', label: 'Blog', icon: BookOpen },
    //...(!isAuthenticated ? [{ to: '/pricing', label: 'Pricing' }] : [])
  ];

  return (
    <>
      <nav className="bg-background/90 backdrop-blur-md fixed w-full z-50 shadow-lg border-b border-border">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <Link to="/" className="flex items-center space-x-2 group">
                <div className="relative">
                  <div className="absolute inset-0 bg-gradient-to-r from-accent-500 to-primary-600 blur-lg opacity-50 group-hover:opacity-75 transition-opacity duration-300"></div>
                  <Compass className="h-8 w-8 relative text-accent-400 group-hover:text-accent-300 transition-all duration-300 animate-spin-slow" />
                </div>
                <span className="text-xl font-bold bg-gradient-to-r from-accent-400 to-primary-400 text-transparent bg-clip-text bg-size-200 animate-gradient">
                  WanderWise
                </span>
              </Link>
            </div>

            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center space-x-8">
              {navLinks.map((link) => (
                <Link
                  key={link.to}
                  to={link.to}
                  className={`
                    text-gray-300 hover:text-white font-medium transition-colors duration-200
                    ${location.pathname === link.to ? 'text-accent-400' : ''}
                  `}
                >
                  {link.label}
                </Link>
              ))}
              {isAuthenticated ? (
                <UserMenu />
              ) : (
                <GoogleSignIn />
              )}
            </div>

            {/* Mobile Menu Button */}
            <div className="md:hidden flex items-center">
              {isAuthenticated ? (
                <UserMenu />
              ) : (
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="text-gray-300 p-2"
                >
                  {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Mobile Menu Dropdown */}
        {isMenuOpen && !isAuthenticated && (
          <div className="md:hidden animate-fade-in">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-background shadow-lg">
              <div className="px-3 py-2">
                <GoogleSignIn />
              </div>
            </div>
          </div>
        )}
      </nav>

      {/* Mobile Bottom Navigation */}
      <div className="md:hidden">
        <MobileNavigation currentPath={location.pathname} />
      </div>
    </>
  );
}