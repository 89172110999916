import { useState } from 'react';
import type { Deal } from '../../../../types/agency';

export type ColumnType = 'inquiry' | 'proposal' | 'negotiation' | 'booked';
export type Columns = Record<ColumnType, Deal[]>;

export function useDealColumns(initialDeals: Deal[]) {
  const [columns, setColumns] = useState<Columns>(() => {
    const initialColumns: Columns = {
      inquiry: [],
      proposal: [],
      negotiation: [],
      booked: []
    };

    // Sort deals into columns
    initialDeals.forEach(deal => {
      if (deal.status in initialColumns) {
        initialColumns[deal.status as ColumnType].push(deal);
      }
    });

    return initialColumns;
  });

  const moveDeal = (dealId: string, fromColumn: ColumnType, toColumn: ColumnType) => {
    setColumns(prev => {
      // Find the deal in the source column
      const deal = prev[fromColumn].find(d => d.id === dealId);
      if (!deal) return prev;

      // Create new arrays for both columns
      const sourceColumn = prev[fromColumn].filter(d => d.id !== dealId);
      const destinationColumn = [...prev[toColumn], { ...deal, status: toColumn }];

      // Return new state with updated columns
      return {
        ...prev,
        [fromColumn]: sourceColumn,
        [toColumn]: destinationColumn
      };
    });
  };

  const getTotalValue = (columnId: ColumnType): number => {
    return columns[columnId].reduce((sum, deal) => sum + deal.budget.total, 0);
  };

  const getDealById = (dealId: string): Deal | undefined => {
    for (const columnType of Object.keys(columns) as ColumnType[]) {
      const deal = columns[columnType].find(d => d.id === dealId);
      if (deal) return deal;
    }
    return undefined;
  };

  return {
    columns,
    moveDeal,
    getTotalValue,
    getDealById
  };
}