import { useEffect } from 'react';

interface ArticleContentProps {
  html: string;
}

export default function ArticleContent({ html }: ArticleContentProps) {
  useEffect(() => {
    // Add IDs to headings for table of contents
    const article = document.querySelector('.article-content');
    if (article) {
      const headings = article.querySelectorAll('h2, h3');
      headings.forEach((heading) => {
        if (!heading.id) {
          heading.id = heading.textContent!
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/(^-|-$)/g, '');
        }
      });
    }
  }, [html]);

  return (
    <div 
      className="article-content prose prose-invert max-w-none"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
}