import Hero from '@/components/Hero';
import Features from '@/components/Features';
import Testimonials from '@/components/Testimonials';
import FAQ from '@/components/FAQ';
import HomePricing from '@/components/HomePricing';
import SEOHead from '@/components/SEO/SEOHead';
import StructuredData from '@/components/SEO/StructuredData';

export default function Home() {
  const organizationData = {
    name: 'WanderWise',
    url: 'https://wanderwise.com',
    logo: 'https://wanderwise.com/logo.png',
    description: 'AI-powered travel planning and recommendations platform',
    sameAs: [
      'https://twitter.com/wanderwise',
      'https://facebook.com/wanderwise',
      'https://instagram.com/wanderwise'
    ]
  };

  return (
    <>
      <SEOHead
        title="AI-Powered Travel Planning"
        description="Plan your perfect trip with WanderWise. Get personalized travel recommendations, curated itineraries, and the best deals using advanced AI technology."
        canonicalUrl="https://wanderwise.com"
      />
      <StructuredData type="Organization" data={organizationData} />
      <Hero />
      <Features />
      <Testimonials />
      <FAQ />
      <HomePricing />
    </>
  );
}