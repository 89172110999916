import { Calendar, Clock, DollarSign, Bed } from 'lucide-react';
import InfoCard from './InfoCard';

interface InfoGridProps {
  bestTimeToVisit: string;
  recommendedDuration: string;
  estimatedBudget: string;
  accommodation: string;
}

export default function InfoGrid({
  bestTimeToVisit,
  recommendedDuration,
  estimatedBudget,
  accommodation
}: InfoGridProps) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
      <InfoCard
        icon={Calendar}
        title="Best Time to Visit"
        value={bestTimeToVisit}
        gradient="from-emerald-500 to-teal-500"
        delay={100}
      />
      <InfoCard
        icon={Clock}
        title="Recommended Duration"
        value={recommendedDuration}
        gradient="from-violet-500 to-purple-500"
        delay={200}
      />
      <InfoCard
        icon={DollarSign}
        title="Estimated Budget"
        value={estimatedBudget}
        gradient="from-amber-500 to-orange-500"
        delay={300}
      />
      <InfoCard
        icon={Bed}
        title="Accommodation"
        value={accommodation}
        gradient="from-blue-500 to-indigo-500"
        delay={400}
      />
    </div>
  );
}