import { useState } from 'react';
import { ChevronDown, ChevronUp, Trash2, Plus, MapPin, Globe } from 'lucide-react';
import { useTripSlip } from '../../contexts/TripSlipContext';
import DestinationCard from './DestinationCard';

interface CollapsibleDestinationProps {
  destination: {
    name: string;
    description: string;
    activities: string[];
    estimatedBudget: string;
    bestTimeToVisit: string;
    accommodation: string;
    recommendedDuration: string;
    localTips: string[];
    tripDescription?: string;
    savedAt: string;
    surveyResponses?: any;
  };
  onDelete: (name: string) => void;
  onAddToTripSlip: (destination: CollapsibleDestinationProps['destination']) => void;
}

export default function CollapsibleDestination({ 
  destination, 
  onDelete,
  onAddToTripSlip 
}: CollapsibleDestinationProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { destinations } = useTripSlip();
  const isInTripSlip = destinations.some(d => d.name === destination.name);

  return (
    <div className="group bg-gradient-to-br from-gray-900/90 to-gray-900/70 backdrop-blur-sm rounded-2xl overflow-hidden transition-all duration-300 border border-white/10 hover:border-accent-500/30">
      <div 
        className="relative flex flex-col sm:flex-row sm:items-center justify-between p-4 sm:p-5 cursor-pointer transition-colors gap-4 sm:gap-0"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {/* Left side with icon and title */}
        <div className="flex items-center gap-4">
          <div className="relative hidden sm:block">
            <div className="absolute inset-0 bg-gradient-to-br from-accent-500/20 to-primary-500/20 blur-lg opacity-0 group-hover:opacity-100 transition-opacity" />
            <div className="relative p-2 rounded-xl bg-gradient-to-br from-accent-500/20 to-primary-500/20">
              <Globe className="h-5 w-5 text-accent-400" />
            </div>
          </div>
          <div>
            <div className="flex items-center gap-2 text-sm text-accent-400 mb-1">
              <MapPin className="h-4 w-4" />
              <span className="font-medium">Destination</span>
            </div>
            <h3 className="text-lg sm:text-xl font-semibold text-white group-hover:text-accent-300 transition-colors">
              {destination.name}
            </h3>
          </div>
        </div>

        {/* Right side with actions */}
        <div className="flex flex-wrap sm:flex-nowrap items-center gap-3 ml-0 sm:ml-3">
          {/* Plan Trip Button */}
          <button
            onClick={(e) => {
              e.stopPropagation();
              onAddToTripSlip(destination);
            }}
            disabled={isInTripSlip}
            className={`
              flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-200 w-full sm:w-auto justify-center sm:justify-start
              ${isInTripSlip
                ? 'bg-gray-700/50 text-gray-500 cursor-not-allowed'
                : 'bg-accent-500/20 text-accent-400 hover:bg-accent-500/30 hover:text-accent-300'
              }
            `}
            title={isInTripSlip ? 'Already in Trip Slip' : 'Add to Trip Slip'}
          >
            <Plus className="h-4 w-4" />
            <span className="font-medium">Plan Trip</span>
          </button>

          <div className="flex items-center gap-3 w-full sm:w-auto justify-end">
            {/* Delete Button */}
            <button
              onClick={(e) => {
                e.stopPropagation();
                onDelete(destination.name);
              }}
              className="p-2 text-gray-400 hover:text-red-400 hover:bg-red-500/10 rounded-lg transition-colors"
              title="Remove destination"
            >
              <Trash2 className="h-5 w-5" />
            </button>

            {/* Expand/Collapse Button */}
            <div className={`
              p-2 rounded-lg transition-colors
              ${isExpanded 
                ? 'bg-accent-500/20 text-accent-400' 
                : 'text-gray-400 group-hover:text-accent-400'
              }
            `}>
              {isExpanded ? (
                <ChevronUp className="h-5 w-5" />
              ) : (
                <ChevronDown className="h-5 w-5" />
              )}
            </div>
          </div>
        </div>

        {/* Decorative gradient line */}
        <div className="absolute bottom-0 left-4 right-4 h-px bg-gradient-to-r from-transparent via-white/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity" />
      </div>
      
      <div 
        className={`
          transition-all duration-300 ease-in-out
          ${isExpanded 
            ? 'max-h-[2000px] opacity-100' 
            : 'max-h-0 opacity-0 overflow-hidden'
          }
        `}
      >
        <DestinationCard destination={destination} />
      </div>
    </div>
  );
}