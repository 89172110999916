import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  LogOut, 
  Building2, 
  ChevronRight,
  Settings,
  UserCircle,
  BookOpen
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

export default function UserMenu() {
  const { user, logout } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  if (!user) return null;

  const menuItems = [
    {
      label: 'Agency Portal',
      icon: Building2,
      onClick: () => navigate('/agency'),
      isPro: true
    },
    {
      label: 'Blog',
      icon: BookOpen,
      onClick: () => navigate('/blog'),
      isMobileOnly: true
    },
    {
      label: 'Profile',
      icon: UserCircle,
      onClick: () => navigate('/profile')
    },
    {
      label: 'Settings',
      icon: Settings,
      onClick: () => navigate('/settings')
    }
  ];

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 p-2 rounded-lg hover:bg-white/10 transition-colors group"
      >
        {user.picture ? (
          <img
            src={user.picture}
            alt={user.name}
            className="w-8 h-8 rounded-full border-2 border-accent-500"
          />
        ) : (
          <div className="w-8 h-8 rounded-full bg-accent-500 flex items-center justify-center">
            <span className="text-white font-medium">
              {user.name.charAt(0).toUpperCase()}
            </span>
          </div>
        )}
        <span className="text-sm text-white hidden md:block">{user.name}</span>
      </button>

      <AnimatePresence>
        {isOpen && (
          <>
            <div
              className="fixed inset-0 z-40"
              onClick={() => setIsOpen(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: -20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: -20 }}
              transition={{ duration: 0.2 }}
              className="absolute right-0 mt-2 w-64 bg-gray-900/90 backdrop-blur-md rounded-lg shadow-lg z-50 py-1 border border-white/20"
            >
              <div className="px-4 py-3 border-b border-white/10">
                <div className="flex items-center gap-3">
                  {user.picture ? (
                    <img
                      src={user.picture}
                      alt={user.name}
                      className="w-10 h-10 rounded-full border-2 border-accent-500"
                    />
                  ) : (
                    <div className="w-10 h-10 rounded-full bg-accent-500 flex items-center justify-center">
                      <span className="text-white font-medium text-lg">
                        {user.name.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  )}
                  <div>
                    <p className="text-sm font-medium text-white">{user.name}</p>
                    <p className="text-xs text-gray-400">{user.email}</p>
                  </div>
                </div>
              </div>

              <div className="py-1">
                {menuItems
                  .filter(item => window.innerWidth < 768 || !item.isMobileOnly)
                  .map((item) => (
                    <button
                      key={item.label}
                      onClick={() => {
                        item.onClick();
                        setIsOpen(false);
                      }}
                      className="w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-white/10 flex items-center justify-between group transition-colors"
                    >
                      <div className="flex items-center gap-2">
                        <item.icon className="h-4 w-4" />
                        <span>{item.label}</span>
                      </div>
                      {item.isPro && (
                        <span className="text-xs bg-accent-500 text-white px-2 py-0.5 rounded">
                          Pro
                        </span>
                      )}
                    </button>
                  ))}
              </div>

              <div className="border-t border-white/10 py-1">
                <button
                  onClick={() => {
                    logout();
                    setIsOpen(false);
                  }}
                  className="w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-white/10 flex items-center gap-2 group transition-colors"
                >
                  <LogOut className="h-4 w-4" />
                  <span>Sign Out</span>
                  <ChevronRight className="h-4 w-4 ml-auto opacity-0 group-hover:opacity-100 transform group-hover:translate-x-1 transition-all" />
                </button>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}