import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import type { Agency, Agent, Client, AgencyStats } from '../types/agency';

// Mock Data
const mockAgency: Agency = {
  id: '1',
  name: 'WanderWise Travel Agency',
  branding: {
    primaryColor: '#FF5A1F',
    secondaryColor: '#1F2937',
    fontFamily: 'Inter'
  },
  subscription: {
    plan: 'pro',
    aiCreditsUsed: 150,
    aiCreditsLimit: 300,
    activeTrips: 12,
    maxTrips: 200,
    maxAgents: 5
  },
  emailTemplates: [],
  settings: {
    companyInfo: {
      address: '123 Travel Street, New York, NY 10001',
      phone: '+1 (555) 123-4567',
      email: 'contact@wanderwise.com',
      website: 'https://wanderwise.com'
    },
    notifications: {
      emailNotifications: true,
      tripUpdates: true,
      clientMessages: true
    }
  }
};

const mockAgents: Agent[] = [
  {
    id: '1',
    googleId: '1',
    name: 'John Smith',
    email: 'john@wanderwise.com',
    role: {
      id: '1',
      name: 'admin',
      permissions: ['all']
    },
    agencyId: '1',
    performanceMetrics: {
      tripsPlanned: 45,
      clientsSatisfaction: 4.8,
      revenue: 125000,
      activeClients: 12
    },
    status: 'active',
    lastActive: new Date()
  },
  {
    id: '2',
    googleId: '2',
    name: 'Sarah Johnson',
    email: 'sarah@wanderwise.com',
    role: {
      id: '2',
      name: 'agent',
      permissions: ['view', 'edit']
    },
    agencyId: '1',
    performanceMetrics: {
      tripsPlanned: 32,
      clientsSatisfaction: 4.6,
      revenue: 98000,
      activeClients: 8
    },
    status: 'active',
    lastActive: new Date()
  }
];

const mockClients: Client[] = [
  {
    id: '1',
    name: 'Michael Brown',
    email: 'michael@example.com',
    phone: '+1 (555) 987-6543',
    agencyId: '1',
    assignedAgentId: '1',
    status: 'active',
    tripHistory: ['trip1', 'trip2'],
    totalSpent: 15000,
    preferences: {
      travelStyle: ['luxury', 'adventure'],
      preferredDestinations: ['Maldives', 'Switzerland'],
      budget: '$10,000 - $20,000'
    },
    documents: [],
    createdAt: new Date('2024-01-15'),
    updatedAt: new Date('2024-02-28')
  },
  {
    id: '2',
    name: 'Emma Wilson',
    email: 'emma@example.com',
    phone: '+1 (555) 456-7890',
    agencyId: '1',
    assignedAgentId: '2',
    status: 'active',
    tripHistory: ['trip3'],
    totalSpent: 8500,
    preferences: {
      travelStyle: ['cultural', 'foodie'],
      preferredDestinations: ['Japan', 'Italy'],
      budget: '$5,000 - $10,000'
    },
    documents: [],
    createdAt: new Date('2024-02-01'),
    updatedAt: new Date('2024-02-15')
  }
];

const mockStats: AgencyStats = {
  activeTrips: 12,
  totalRevenue: 223000,
  clientCount: 25,
  popularDestinations: [
    { destination: 'Paris', count: 8 },
    { destination: 'Tokyo', count: 6 },
    { destination: 'Maldives', count: 5 },
    { destination: 'New York', count: 4 },
    { destination: 'Barcelona', count: 3 }
  ],
  agentPerformance: [
    {
      agentId: '1',
      agentName: 'John Smith',
      tripsPlanned: 45,
      revenue: 125000,
      satisfaction: 4.8
    },
    {
      agentId: '2',
      agentName: 'Sarah Johnson',
      tripsPlanned: 32,
      revenue: 98000,
      satisfaction: 4.6
    }
  ],
  recentActivity: [
    {
      id: '1',
      type: 'trip_created',
      description: 'New trip created for Emma Wilson to Japan',
      timestamp: new Date('2024-02-28T10:30:00'),
      agentId: '2'
    },
    {
      id: '2',
      type: 'client_added',
      description: 'New client Michael Brown added',
      timestamp: new Date('2024-02-27T15:45:00'),
      agentId: '1'
    },
    {
      id: '3',
      type: 'document_uploaded',
      description: 'Travel itinerary uploaded for Paris trip',
      timestamp: new Date('2024-02-26T09:15:00'),
      agentId: '1'
    }
  ]
};

interface AgencyContextType {
  agency: Agency | null;
  agents: Agent[];
  clients: Client[];
  stats: AgencyStats | null;
  isLoading: boolean;
  error: string | null;
  refreshAgency: () => Promise<void>;
  refreshStats: () => Promise<void>;
  updateAgencySettings: (settings: Partial<Agency>) => Promise<void>;
  addAgent: (agent: Omit<Agent, 'id'>) => Promise<void>;
  updateAgent: (id: string, updates: Partial<Agent>) => Promise<void>;
  removeAgent: (id: string) => Promise<void>;
  addClient: (client: Omit<Client, 'id'>) => Promise<void>;
  updateClient: (id: string, updates: Partial<Client>) => Promise<void>;
  removeClient: (id: string) => Promise<void>;
}

const AgencyContext = createContext<AgencyContextType | undefined>(undefined);

export function AgencyProvider({ children }: { children: ReactNode }) {
  const [agency, setAgency] = useState<Agency | null>(mockAgency);
  const [agents, setAgents] = useState<Agent[]>(mockAgents);
  const [clients, setClients] = useState<Client[]>(mockClients);
  const [stats, setStats] = useState<AgencyStats | null>(mockStats);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const refreshAgency = async () => {
    // Mock refresh - in real app would fetch from API
    return Promise.resolve();
  };

  const refreshStats = async () => {
    // Mock refresh - in real app would fetch from API
    return Promise.resolve();
  };

  const updateAgencySettings = async (settings: Partial<Agency>) => {
    setAgency(prev => prev ? { ...prev, ...settings } : null);
    return Promise.resolve();
  };

  const addAgent = async (agent: Omit<Agent, 'id'>) => {
    const newAgent = {
      ...agent,
      id: Math.random().toString(36).substr(2, 9)
    };
    setAgents(prev => [...prev, newAgent as Agent]);
    return Promise.resolve();
  };

  const updateAgent = async (id: string, updates: Partial<Agent>) => {
    setAgents(prev => prev.map(agent => 
      agent.id === id ? { ...agent, ...updates } : agent
    ));
    return Promise.resolve();
  };

  const removeAgent = async (id: string) => {
    setAgents(prev => prev.filter(agent => agent.id !== id));
    return Promise.resolve();
  };

  const addClient = async (client: Omit<Client, 'id'>) => {
    const newClient = {
      ...client,
      id: Math.random().toString(36).substr(2, 9)
    };
    setClients(prev => [...prev, newClient as Client]);
    return Promise.resolve();
  };

  const updateClient = async (id: string, updates: Partial<Client>) => {
    setClients(prev => prev.map(client => 
      client.id === id ? { ...client, ...updates } : client
    ));
    return Promise.resolve();
  };

  const removeClient = async (id: string) => {
    setClients(prev => prev.filter(client => client.id !== id));
    return Promise.resolve();
  };

  const value = {
    agency,
    agents,
    clients,
    stats,
    isLoading,
    error,
    refreshAgency,
    refreshStats,
    updateAgencySettings,
    addAgent,
    updateAgent,
    removeAgent,
    addClient,
    updateClient,
    removeClient
  };

  return (
    <AgencyContext.Provider value={value}>
      {children}
    </AgencyContext.Provider>
  );
}

export function useAgency() {
  const context = useContext(AgencyContext);
  if (context === undefined) {
    throw new Error('useAgency must be used within an AgencyProvider');
  }
  return context;
}