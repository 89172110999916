import { useState, useEffect } from 'react';
import { MapPin, Plus, Loader2 } from 'lucide-react';
import { getRandomTravelImage, type TravelImage } from '../../services/images';
import TripDescription from './TripDescription';
import InfoGrid from './InfoGrid';
import DestinationActivities from './DestinationActivities';
import DestinationTips from './DestinationTips';

interface DestinationCardProps {
  destination: {
    name: string;
    description: string;
    activities: string[];
    estimatedBudget: string;
    bestTimeToVisit: string;
    accommodation: string;
    recommendedDuration: string;
    localTips: string[];
    tripDescription?: string;
  };
  onSave: () => void;
  isSaving: boolean;
  isAuthenticated: boolean;
}

export default function DestinationCard({ 
  destination, 
  onSave,
  isSaving,
  isAuthenticated 
}: DestinationCardProps) {
  const [backgroundImage, setBackgroundImage] = useState<TravelImage | null>(null);

  useEffect(() => {
    setBackgroundImage(getRandomTravelImage());
  }, []);

  return (
    <div className="overflow-y-auto max-h-[80vh] rounded-2xl bg-gradient-to-b from-gray-900/90 to-gray-900/70 backdrop-blur-sm border border-white/10 shadow-2xl">
      <div className="relative">
        {/* Background Image */}
        <div 
          className="relative h-48 sm:h-64 w-full bg-cover bg-center transition-transform duration-500 group-hover:scale-105"
          style={{ 
            backgroundImage: `url(${backgroundImage?.url})`,
          }}
        >
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-gray-900/50 to-gray-900" />
        </div>

        {/* Content Overlay */}
        <div className="absolute bottom-0 w-full p-4 sm:p-6">
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center gap-2 text-accent-400 mb-2">
                <MapPin className="h-4 w-4" />
                <span className="text-xs sm:text-sm font-medium uppercase tracking-wider">
                  Destination
                </span>
              </div>
              <h3 className="text-2xl sm:text-3xl font-bold text-white mb-2">
                {destination.name}
              </h3>
              <p className="text-gray-200 text-sm sm:text-base leading-relaxed max-w-3xl line-clamp-2 sm:line-clamp-none">
                {destination.description}
              </p>
            </div>

            {isAuthenticated && (
              <button
                onClick={onSave}
                disabled={isSaving}
                className={`
                  flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-200
                  ${isSaving
                    ? 'bg-gray-700/50 text-gray-500 cursor-not-allowed'
                    : 'bg-accent-500/20 text-accent-400 hover:bg-accent-500/30 hover:text-accent-300'
                  }
                `}
              >
                {isSaving ? (
                  <Loader2 className="h-5 w-5 animate-spin" />
                ) : (
                  <Plus className="h-5 w-5" />
                )}
                <span>Save</span>
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="p-4 sm:p-6 space-y-6 sm:space-y-8">
        {destination.tripDescription && (
          <TripDescription description={destination.tripDescription} />
        )}

        <InfoGrid
          bestTimeToVisit={destination.bestTimeToVisit}
          recommendedDuration={destination.recommendedDuration}
          estimatedBudget={destination.estimatedBudget}
          accommodation={destination.accommodation}
        />

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <DestinationActivities activities={destination.activities} />
          <DestinationTips tips={destination.localTips} />
        </div>
      </div>
    </div>
  );
}