import { Calendar, MapPin, Clock, ChevronDown, ChevronUp, Trash2 } from 'lucide-react';
import { format, isValid } from 'date-fns';

interface ItineraryHeaderProps {
  title: string;
  destination: string;
  startDate: string;
  endDate: string;
  daysCount: number;
  isExpanded: boolean;
  isDeleting: boolean;
  onDelete: () => void;
  onToggleExpand: () => void;
}

export default function ItineraryHeader({
  title,
  destination,
  startDate,
  endDate,
  daysCount,
  isExpanded,
  isDeleting,
  onDelete,
  onToggleExpand
}: ItineraryHeaderProps) {
  const getDateRange = (start: string, end: string) => {
    try {
      const startDate = new Date(start);
      const endDate = new Date(end);
      
      if (!isValid(startDate) || !isValid(endDate)) {
        return 'Date range not available';
      }

      return `${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d, yyyy')}`;
    } catch (error) {
      console.error('Error formatting date range:', error);
      return 'Date range not available';
    }
  };

  return (
    <div className="flex items-start justify-between gap-4">
      <div>
        <h3 className="text-xl font-bold text-white mb-2">{title}</h3>
        <div className="flex items-center gap-4 text-sm text-gray-300">
          <div className="flex items-center gap-2">
            <MapPin className="h-4 w-4 text-accent-400" />
            <span>{destination}</span>
          </div>
          <div className="flex items-center gap-2">
            <Calendar className="h-4 w-4 text-accent-400" />
            <span>{getDateRange(startDate, endDate)}</span>
          </div>
          <div className="flex items-center gap-2">
            <Clock className="h-4 w-4 text-accent-400" />
            <span>{daysCount} days</span>
          </div>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <button
          onClick={onDelete}
          disabled={isDeleting}
          className="p-2 text-gray-400 hover:text-red-400 hover:bg-red-500/10 rounded-lg transition-colors"
        >
          <Trash2 className={`h-5 w-5 ${isDeleting ? 'animate-spin' : ''}`} />
        </button>
        <button
          onClick={onToggleExpand}
          className="p-2 text-gray-400 hover:text-white hover:bg-white/10 rounded-lg transition-colors"
        >
          {isExpanded ? (
            <ChevronUp className="h-5 w-5" />
          ) : (
            <ChevronDown className="h-5 w-5" />
          )}
        </button>
      </div>
    </div>
  );
}