import { Plane, Clock, ArrowRight, Leaf, Calendar } from 'lucide-react';
import { format } from 'date-fns';
import type { FlightSearchResponse } from '../../types/flights';

interface FlightSearchResultsProps {
  flights: FlightSearchResponse | null;
  isLoading: boolean;
  error?: string | null;
}

export default function FlightSearchResults({
  flights,
  isLoading,
  error
}: FlightSearchResultsProps) {
  const handleSelectFlight = (googleFlightsUrl: string, departureToken: string) => {
    const url = `${googleFlightsUrl}&departure_token=${departureToken}`;
    window.open(url, '_blank');
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-8">
        <div className="flex flex-col items-center gap-2">
          <Plane className="h-6 w-6 text-accent-400 animate-bounce" />
          <p className="text-sm text-gray-300">Searching for flights...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-4">
        <p className="text-sm text-red-400">{error}</p>
      </div>
    );
  }

  if (!flights?.best_flights?.length) return null;

  return (
    <div className="space-y-4 mt-6">
      <div className="flex items-center justify-between">
        <h3 className="text-base font-medium text-white">Best Available Flights</h3>
        {flights.price_insights && (
          <div className="text-xs text-gray-400">
            Typical range: ${flights.price_insights.typical_price_range[0]} - ${flights.price_insights.typical_price_range[1]}
          </div>
        )}
      </div>

      <div className="grid gap-3">
        {flights.best_flights.map((flight, index) => {
          const firstFlight = flight.flights[0];
          const lastFlight = flight.flights[flight.flights.length - 1];
          const departureTime = new Date(firstFlight.departure_airport.time);
          const arrivalTime = new Date(lastFlight.arrival_airport.time);

          return (
            <div
              key={flight.departure_token || index}
              className="bg-gray-900/60 backdrop-blur-sm rounded-lg border border-white/10 overflow-hidden hover:border-accent-500/30 transition-all duration-300"
            >
              <div className="p-4">
                {/* Header with price and basic info */}
                <div className="flex items-center justify-between mb-3">
                  <div className="flex items-center gap-3">
                    <img
                      src={flight.airline_logo}
                      alt="Airline"
                      className="h-8 w-8 object-contain bg-white/5 rounded-md p-1"
                    />
                    <div>
                      <p className="text-sm text-white font-medium">
                        {flight.flights.map((f) => f.airline).join(' + ')}
                      </p>
                      <div className="flex items-center gap-1 text-xs text-gray-400">
                        <span>{flight.flights.length - 1} stops</span>
                        <span>•</span>
                        <span>
                          {Math.floor(flight.total_duration / 60)}h {flight.total_duration % 60}m
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className="text-xl font-bold text-white">${flight.price}</p>
                    {flights.price_insights?.price_level === 'low' && (
                      <p className="text-xs text-accent-400">Great deal!</p>
                    )}
                  </div>
                </div>

                {/* Flight timeline */}
                <div className="flex items-center justify-between relative py-3">
                  <div className="absolute left-[10%] right-[10%] h-px bg-gray-700"></div>
                  {flight.flights.map((segment, idx) => (
                    <div key={idx} className="relative z-10">
                      <div className="flex flex-col items-center">
                        <div className="w-3 h-3 rounded-full bg-accent-500 mb-1"></div>
                        <p className="text-xs font-medium text-white">{segment.departure_airport.id}</p>
                        <p className="text-[10px] text-gray-400">
                          {format(new Date(segment.departure_airport.time), 'HH:mm')}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>

                {/* Additional info */}
                <div className="flex items-center justify-between mt-3 text-xs">
                  <div className="flex items-center gap-2 text-emerald-400">
                    <Leaf className="h-3 w-3" />
                    <span>
                      {(flight.carbon_emissions.this_flight / 1000).toFixed(1)}kg CO₂e
                      {flight.carbon_emissions.difference_percent > 0 && (
                        <span className="text-gray-400 ml-1">
                          (+{flight.carbon_emissions.difference_percent}%)
                        </span>
                      )}
                    </span>
                  </div>
                  <button 
                    onClick={() => handleSelectFlight(flights.search_metadata.google_flights_url, flight.departure_token)}
                    className="px-3 py-1.5 rounded-md bg-accent-500 text-white hover:bg-accent-600 transition-colors flex items-center gap-1.5 text-xs"
                  >
                    Select
                    <ArrowRight className="h-3 w-3" />
                  </button>
                </div>
              </div>

              {/* Layover info */}
              {flight.layovers?.length > 0 && (
                <div className="bg-gray-800/50 border-t border-white/5 px-4 py-2">
                  <div className="flex items-center gap-1.5 text-[11px] text-gray-400">
                    <Clock className="h-3 w-3" />
                    <span>Layovers:</span>
                    {flight.layovers.map((layover, idx) => (
                      <span key={idx}>
                        {layover.name.split(' ')[0]} ({Math.floor(layover.duration / 60)}h{' '}
                        {layover.duration % 60}m)
                        {idx < flight.layovers.length - 1 && ', '}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}