import { Check, ArrowRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import anime from 'animejs';

const plans = [
  {
    name: 'Free',
    description: 'Perfect for exploring travel possibilities',
    price: '$0',
    period: 'forever',
    features: [
      'Unlimited AI travel recommendations',
      'Basic trip organization',
      'Flight and hotel search',
      'Activity suggestions',
      'Save up to 3 recommendations',
      'Community access'
    ],
    cta: 'Get Started',
    highlight: true,
    freeHighlights: [
      'Get unlimited AI travel recommendations',
      'Explore destinations worldwide',
      'Basic trip research tools'
    ]
  },
  {
    name: 'Pro',
    description: 'For serious travel planners',
    price: '$4.99',
    period: 'per month',
    yearlyPrice: '$47',
    yearlyPeriod: 'per year',
    features: [
      'Everything in Free, plus:',
      'Full trip schedule builder',
      'Unlimited saved trips',
      'Detailed itinerary planning',
      'Advanced customization',
      'Priority AI processing',
      'Export to calendar',
      'Offline access'
    ],
    cta: 'Start Free Trial',
    highlight: false,
    savingsNote: 'Save 21% with annual plan'
  },
  {
    name: 'Enterprise',
    description: 'For travel agencies and organizations',
    price: 'Custom',
    period: 'contact us',
    features: [
      'Full-featured CRM system',
      'White label solutions',
      'Client management portal',
      'Team collaboration tools',
      'Multi-branch support',
      'Custom AI training',
      'Advanced analytics',
      'Dedicated account manager',
      'Premium support SLA',
      'Custom integrations',
      'Booking management',
      'API access'
    ],
    cta: 'Book a Call',
    highlight: false,
    enterprise: true,
    businessHighlights: [
      'Complete travel agency solution',
      'Enterprise-grade features',
      'Customizable workflows'
    ]
  }
];

export default function HomePricing() {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const cardsRef = useRef<(HTMLDivElement | null)[]>([]);
  const headingRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    // Animate heading
    if (headingRef.current) {
      const textWrapper = headingRef.current;
      textWrapper.innerHTML = textWrapper.textContent!.replace(/\S/g, "<span class='letter inline-block'>$&</span>");

      anime.timeline({ loop: true })
        .add({
          targets: '.heading-animation .letter',
          translateY: [-100, 0],
          opacity: [0, 1],
          easing: "easeOutExpo",
          duration: 1400,
          delay: (el, i) => 30 * i
        })
        .add({
          targets: '.heading-animation .letter',
          translateY: [0, 100],
          opacity: [1, 0],
          easing: "easeInExpo",
          duration: 1200,
          delay: (el, i) => 30 * i
        });
    }

    // Animate cards on scroll
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            cardsRef.current.forEach((card, index) => {
              if (!card) return;
              
              anime({
                targets: card,
                translateY: [100, 0],
                opacity: [0, 1],
                scale: [0.9, 1],
                delay: index * 200,
                duration: 1500,
                easing: 'spring(1, 80, 10, 0)'
              });

              // Floating animation
              anime({
                targets: card,
                translateY: [0, -10],
                direction: 'alternate',
                loop: true,
                duration: 2000 + index * 500,
                easing: 'easeInOutSine',
                delay: 1500 + index * 200
              });
            });
          }
        });
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const handlePlanClick = () => {
    navigate('/pricing');
    window.scrollTo(0, 0);
  };

  return (
    <div className="py-24 bg-gradient-to-b from-gray-50 to-white">
      <div ref={containerRef} className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 
            ref={headingRef}
            className="heading-animation text-4xl font-bold mb-4 overflow-hidden"
          >
            Simple, Transparent Pricing
          </h2>
          <p className="text-xl text-gray-600">
            Choose the perfect plan for your travel planning needs
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <div
              key={plan.name}
              ref={el => cardsRef.current[index] = el}
              className="relative transform transition-all duration-300 hover:scale-105"
            >
              <div className={`
                relative bg-white border-4 border-black p-8 rounded-xl
                ${plan.highlight ? 'shadow-[8px_8px_0px_0px_rgba(255,90,31,1)]' : 'shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]'}
                hover:translate-x-1 hover:translate-y-1 hover:shadow-none
                transition-all duration-200
              `}>
                {plan.highlight && (
                  <div className="absolute -top-4 -right-4 bg-black text-white px-4 py-1 text-sm font-bold transform rotate-6">
                    Most Popular
                  </div>
                )}

                <h3 className="text-2xl font-bold mb-2">{plan.name}</h3>
                <p className="text-gray-600 mb-6">{plan.description}</p>

                <div className="mb-8">
                  <span className="text-4xl font-bold">{plan.price}</span>
                  <span className="text-gray-500 ml-2">{plan.period}</span>
                  {plan.yearlyPrice && (
                    <div className="mt-2 text-sm">
                      <span className="font-bold">{plan.yearlyPrice}</span>
                      <span className="text-gray-500 ml-1">{plan.yearlyPeriod}</span>
                      {plan.savingsNote && (
                        <span className="text-accent-500 ml-2">({plan.savingsNote})</span>
                      )}
                    </div>
                  )}
                </div>

                {plan.freeHighlights && (
                  <div className="mb-8 p-4 bg-accent-50 border-2 border-black rounded-lg">
                    <h4 className="text-sm font-bold text-accent-600 mb-3">
                      Free Plan Highlights
                    </h4>
                    <ul className="space-y-2">
                      {plan.freeHighlights.map((highlight) => (
                        <li key={highlight} className="flex items-center gap-2 text-sm">
                          <div className="h-1.5 w-1.5 bg-accent-500 rounded-full" />
                          {highlight}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                <ul className="space-y-4 mb-8">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-start gap-3">
                      <Check className="h-5 w-5 text-accent-500 flex-shrink-0" />
                      <span className="text-gray-600">{feature}</span>
                    </li>
                  ))}
                </ul>

                <button
                  onClick={handlePlanClick}
                  className={`
                    w-full py-3 px-6 rounded-xl font-bold text-lg
                    transition-all duration-200 transform
                    border-4 border-black
                    ${plan.highlight
                      ? 'bg-accent-500 text-white hover:bg-accent-600'
                      : 'bg-black text-white hover:bg-gray-800'
                    }
                    hover:translate-x-1 hover:translate-y-1
                    active:translate-x-2 active:translate-y-2
                  `}
                >
                  {plan.cta}
                </button>

                {plan.includesProFeatures && (
                  <div className="mt-6 p-4 bg-accent-50 border-2 border-black rounded-lg">
                    <div className="flex items-center gap-2">
                      <Check className="h-5 w-5 text-accent-500" />
                      <p className="text-sm font-bold">
                        Includes all Pro plan features
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="mt-16 text-center">
          <Link
            to="/pricing"
            className="inline-flex items-center gap-2 px-6 py-3 bg-black text-white rounded-xl font-bold border-4 border-black hover:translate-x-1 hover:translate-y-1 transition-transform"
          >
            View full pricing details
            <ArrowRight className="h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
}