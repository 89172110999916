import { useState } from 'react';
import { 
  Settings as SettingsIcon, 
  Users, 
  Bell, 
  Lock,
  Palette,
  Globe,
  Mail,
  CreditCard,
  FileText,
  Plus,
  Check,
  X
} from 'lucide-react';
import { Tab } from '@headlessui/react';
import { cn } from '@/lib/utils';

interface SettingSection {
  id: string;
  label: string;
  icon: typeof SettingsIcon;
  component: React.ReactNode;
}

export default function Settings() {
  const [selectedTab, setSelectedTab] = useState(0);

  const sections: SettingSection[] = [
    {
      id: 'general',
      label: 'General',
      icon: SettingsIcon,
      component: (
        <div className="space-y-6">
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Company Information</h4>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Company Name
                </label>
                <input
                  type="text"
                  defaultValue="WanderWise Travel"
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-accent-500 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Website
                </label>
                <input
                  type="url"
                  defaultValue="https://wanderwise.com"
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-accent-500 focus:border-transparent"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Business Address
                </label>
                <textarea
                  rows={3}
                  defaultValue="123 Travel Street&#10;New York, NY 10001&#10;United States"
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-accent-500 focus:border-transparent"
                />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Time Zone Settings</h4>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Default Time Zone
                </label>
                <select className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-accent-500 focus:border-transparent">
                  <option value="America/New_York">Eastern Time (ET)</option>
                  <option value="America/Chicago">Central Time (CT)</option>
                  <option value="America/Denver">Mountain Time (MT)</option>
                  <option value="America/Los_Angeles">Pacific Time (PT)</option>
                </select>
              </div>
              <div className="flex items-center gap-2">
                <input type="checkbox" id="auto-tz" className="rounded text-accent-500" />
                <label htmlFor="auto-tz" className="text-sm text-gray-700">
                  Automatically adjust for client time zones
                </label>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Currency Settings</h4>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Default Currency
                </label>
                <select className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-accent-500 focus:border-transparent">
                  <option value="USD">US Dollar (USD)</option>
                  <option value="EUR">Euro (EUR)</option>
                  <option value="GBP">British Pound (GBP)</option>
                  <option value="JPY">Japanese Yen (JPY)</option>
                </select>
              </div>
              <div className="flex items-center gap-2">
                <input type="checkbox" id="auto-currency" className="rounded text-accent-500" />
                <label htmlFor="auto-currency" className="text-sm text-gray-700">
                  Show prices in client's local currency
                </label>
              </div>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'team',
      label: 'Team',
      icon: Users,
      component: (
        <div className="space-y-6">
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <div className="flex items-center justify-between mb-6">
              <h4 className="text-lg font-semibold">Team Members</h4>
              <button className="flex items-center gap-2 px-4 py-2 bg-accent-500 text-white rounded-lg hover:bg-accent-600 transition-colors">
                <Plus className="h-4 w-4" />
                Add Member
              </button>
            </div>
            
            <div className="space-y-4">
              {[
                {
                  name: 'Sarah Parker',
                  email: 'sarah@wanderwise.com',
                  role: 'Admin',
                  status: 'active'
                },
                {
                  name: 'Michael Chen',
                  email: 'michael@wanderwise.com',
                  role: 'Agent',
                  status: 'active'
                },
                {
                  name: 'Emma Davis',
                  email: 'emma@wanderwise.com',
                  role: 'Agent',
                  status: 'pending'
                }
              ].map((member, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center gap-4">
                    <div className="w-10 h-10 rounded-full bg-accent-500 flex items-center justify-center text-white font-medium">
                      {member.name.charAt(0)}
                    </div>
                    <div>
                      <div className="font-medium text-gray-900">{member.name}</div>
                      <div className="text-sm text-gray-500">{member.email}</div>
                    </div>
                  </div>
                  <div className="flex items-center gap-4">
                    <select
                      defaultValue={member.role}
                      className="px-3 py-1.5 rounded border border-gray-200 text-sm"
                    >
                      <option value="Admin">Admin</option>
                      <option value="Agent">Agent</option>
                      <option value="Viewer">Viewer</option>
                    </select>
                    <div className={cn(
                      'px-2 py-1 text-xs rounded-full',
                      member.status === 'active'
                        ? 'bg-emerald-100 text-emerald-700'
                        : 'bg-yellow-100 text-yellow-700'
                    )}>
                      {member.status.charAt(0).toUpperCase() + member.status.slice(1)}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Team Permissions</h4>
            <div className="space-y-4">
              {[
                { role: 'Admin', permissions: ['Full system access', 'Manage team', 'View analytics'] },
                { role: 'Agent', permissions: ['Create bookings', 'View clients', 'Limited analytics'] },
                { role: 'Viewer', permissions: ['View bookings', 'View reports'] }
              ].map((role, index) => (
                <div key={index} className="p-4 border border-gray-200 rounded-lg">
                  <div className="font-medium text-gray-900 mb-2">{role.role}</div>
                  <div className="space-y-2">
                    {role.permissions.map((permission, pIndex) => (
                      <div key={pIndex} className="flex items-center gap-2 text-sm text-gray-600">
                        <Check className="h-4 w-4 text-accent-500" />
                        {permission}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'notifications',
      label: 'Notifications',
      icon: Bell,
      component: (
        <div className="space-y-6">
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Email Notifications</h4>
            <div className="space-y-4">
              {[
                { label: 'New booking notifications', description: 'Receive emails when new bookings are made' },
                { label: 'Booking updates', description: 'Get notified when bookings are modified or cancelled' },
                { label: 'Payment notifications', description: 'Receive payment confirmations and updates' },
                { label: 'Team mentions', description: 'Get notified when mentioned in comments' }
              ].map((setting, index) => (
                <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div>
                    <div className="font-medium text-gray-900">{setting.label}</div>
                    <div className="text-sm text-gray-500">{setting.description}</div>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" className="sr-only peer" defaultChecked />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-accent-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-accent-500"></div>
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">System Notifications</h4>
            <div className="space-y-4">
              {[
                { label: 'Desktop notifications', description: 'Show desktop notifications for important updates' },
                { label: 'Sound alerts', description: 'Play sound when new notifications arrive' },
                { label: 'Browser notifications', description: 'Show notifications in browser when tab is inactive' }
              ].map((setting, index) => (
                <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div>
                    <div className="font-medium text-gray-900">{setting.label}</div>
                    <div className="text-sm text-gray-500">{setting.description}</div>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" className="sr-only peer" />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-accent-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-accent-500"></div>
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'security',
      label: 'Security',
      icon: Lock,
      component: (
        <div className="space-y-6">
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Password Requirements</h4>
            <div className="space-y-4">
              {[
                { label: 'Minimum length', value: '12 characters' },
                { label: 'Require uppercase letters', value: 'Yes' },
                { label: 'Require numbers', value: 'Yes' },
                { label: 'Require special characters', value: 'Yes' },
                { label: 'Password expiration', value: '90 days' }
              ].map((requirement, index) => (
                <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="font-medium text-gray-900">{requirement.label}</div>
                  <div className="text-gray-600">{requirement.value}</div>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Two-Factor Authentication</h4>
            <div className="space-y-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <div className="font-medium text-gray-900">Enable 2FA</div>
                    <div className="text-sm text-gray-500">Require two-factor authentication for all users</div>
                  </div>
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input type="checkbox" className="sr-only peer" defaultChecked />
                    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-accent-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-accent-500"></div>
                  </label>
                </div>
                <div className="text-sm text-gray-600">
                  Two-factor authentication adds an extra layer of security to your account by requiring more than just a password to sign in.
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Session Settings</h4>
            <div className="space-y-4">
              {[
                { label: 'Session timeout', value: '30 minutes' },
                { label: 'Max concurrent sessions', value: '3 sessions' },
                { label: 'Remember device duration', value: '30 days' }
              ].map((setting, index) => (
                <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div className="font-medium text-gray-900">{setting.label}</div>
                  <select className="px-3 py-1.5 rounded border border-gray-200 text-sm">
                    <option value={setting.value}>{setting.value}</option>
                    <option value="other">Other</option>
                  </select>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'billing',
      label: 'Billing',
      icon: CreditCard,
      component: (
        <div className="space-y-6">
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Current Plan</h4>
            <div className="p-4 bg-gray-50 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <div>
                  <div className="font-medium text-gray-900">Enterprise Plan</div>
                  <div className="text-sm text-gray-500">$499/month</div>
                </div>
                <div className="px-3 py-1 bg-emerald-100 text-emerald-700 rounded-full text-sm">
                  Active
                </div>
              </div>
              <div className="space-y-2">
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <Check className="h-4 w-4 text-accent-500" />
                  Unlimited users
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <Check className="h-4 w-4 text-accent-500" />
                  Advanced analytics
                </div>
                <div className="flex items-center gap-2 text-sm text-gray-600">
                  <Check className="h-4 w-4 text-accent-500" />
                  Priority support
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Payment Method</h4>
            <div className="space-y-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <div className="w-12 h-8 bg-gray-200 rounded"></div>
                    <div>
                      <div className="font-medium text-gray-900">•••• 4242</div>
                      <div className="text-sm text-gray-500">Expires 12/24</div>
                    </div>
                  </div>
                  <button className="text-accent-500 hover:text-accent-600">
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Billing History</h4>
            <div className="space-y-4">
              {[
                { date: 'Mar 1, 2024', amount: '$499.00', status: 'Paid' },
                { date: 'Feb 1, 2024', amount: '$499.00', status: 'Paid' },
                { date: 'Jan 1, 2024', amount: '$499.00', status: 'Paid' }
              ].map((invoice, index) => (
                <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div>
                    <div className="font-medium text-gray-900">{invoice.date}</div>
                    <div className="text-sm text-gray-500">{invoice.amount}</div>
                  </div>
                  <div className="flex items-center gap-3">
                    <div className="px-3 py-1 bg-emerald-100 text-emerald-700 rounded-full text-sm">
                      {invoice.status}
                    </div>
                    <button className="text-accent-500 hover:text-accent-600">
                      Download
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'integrations',
      label: 'Integrations',
      icon: Globe,
      component: (
        <div className="space-y-6">
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Connected Services</h4>
            <div className="space-y-4">
              {[
                { name: 'Google Calendar', status: 'connected', lastSync: '5 minutes ago' },
                { name: 'Slack', status: 'connected', lastSync: '1 hour ago' },
                { name: 'Zoom', status: 'not connected' }
              ].map((service, index) => (
                <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <div>
                    <div className="font-medium text-gray-900">{service.name}</div>
                    {service.lastSync && (
                      <div className="text-sm text-gray-500">Last synced: {service.lastSync}</div>
                    )}
                  </div>
                  <button
                    className={cn(
                      'px-4 py-2 rounded-lg text-sm font-medium',
                      service.status === 'connected'
                        ? 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                        : 'bg-accent-500 text-white hover:bg-accent-600'
                    )}
                  >
                    {service.status === 'connected' ? 'Disconnect' : 'Connect'}
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">API Access</h4>
            <div className="space-y-4">
              <div className="p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center justify-between mb-4">
                  <div className="font-medium text-gray-900">API Key</div>
                  <button className="text-accent-500 hover:text-accent-600">
                    Generate New Key
                  </button>
                </div>
                <div className="font-mono text-sm bg-white p-3 rounded border border-gray-200">
                  sk_test_51ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Webhooks</h4>
            <div className="space-y-4">
              {[
                { url: 'https://api.example.com/webhook1', events: ['booking.created', 'booking.updated'] },
                { url: 'https://api.example.com/webhook2', events: ['payment.succeeded'] }
              ].map((webhook, index) => (
                <div key={index} className="p-4 bg-gray-50 rounded-lg">
                  <div className="flex items-center justify-between mb-2">
                    <div className="font-mono text-sm">{webhook.url}</div>
                    <button className="text-red-500 hover:text-red-600">
                      <X className="h-4 w-4" />
                    </button>
                  </div>
                  <div className="flex gap-2">
                    {webhook.events.map((event, eIndex) => (
                      <div
                        key={eIndex}
                        className="px-2 py-1 bg-gray-200 text-gray-700 rounded text-xs"
                      >
                        {event}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
              <button className="flex items-center gap-2 text-accent-500 hover:text-accent-600">
                <Plus className="h-4 w-4" />
                Add Webhook
              </button>
            </div>
          </div>
        </div>
      )
    },
    {
      id: 'whitelabel',
      label: 'White Label',
      icon: Palette,
      component: (
        <div className="space-y-6">
          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Branding Settings</h4>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Company Logo
                </label>
                <div className="flex items-center gap-4">
                  <div className="w-32 h-32 bg-gray-100 rounded-lg flex items-center justify-center">
                    <Plus className="h-6 w-6 text-gray-400" />
                  </div>
                  <button className="px-4 py-2 border border-accent-500 text-accent-500 rounded-lg hover:bg-accent-50 transition-colors">
                    Upload Logo
                  </button>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Brand Colors
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-xs text-gray-500 mb-1">Primary Color</label>
                    <input type="color" className="h-10 w-full" defaultValue="#FF5A1F" />
                  </div>
                  <div>
                    <label className="block text-xs text-gray-500 mb-1">Secondary Color</label>
                    <input type="color" className="h-10 w-full" defaultValue="#3B82F6" />
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Custom Domain
                </label>
                <input
                  type="text"
                  placeholder="travel.yourcompany.com"
                  className="w-full px-4 py-2 rounded-lg border border-gray-200 focus:ring-2 focus:ring-accent-500 focus:border-transparent"
                />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Email Templates</h4>
            <div className="space-y-4">
              <div className="border border-gray-200 rounded-lg p-4">
                <div className="flex items-center justify-between mb-4">
                  <h5 className="font-medium">Booking Confirmation</h5>
                  <button className="text-accent-500 hover:text-accent-600">Edit Template</button>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <pre className="text-sm text-gray-600 whitespace-pre-wrap">
                    {`Dear {client_name},

Thank you for booking with {company_name}!

Your trip to {destination} has been confirmed.
Dates: {start_date} - {end_date}

{booking_details}

Best regards,
{agent_name}
{company_name}`}
                  </pre>
                </div>
              </div>

              <div className="border border-gray-200 rounded-lg p-4">
                <div className="flex items-center justify-between mb-4">
                  <h5 className="font-medium">Invoice Template</h5>
                  <button className="text-accent-500 hover:text-accent-600">Edit Template</button>
                </div>
                <div className="bg-gray-50 p-4 rounded-lg">
                  <div className="space-y-4">
                    <div className="flex justify-between">
                      <div className="w-32 h-8 bg-gray-200 rounded" />
                      <div className="text-right">
                        <div className="font-medium">INVOICE</div>
                        <div className="text-sm text-gray-500">#{'{invoice_number}'}</div>
                      </div>
                    </div>
                    <div className="border-t border-gray-200 pt-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <div className="text-sm text-gray-500">Bill To:</div>
                          <div>{'{client_details}'}</div>
                        </div>
                        <div className="text-right">
                          <div className="text-sm text-gray-500">Invoice Date:</div>
                          <div>{'{invoice_date}'}</div>
                        </div>
                      </div>
                    </div>
                    <div className="border-t border-gray-200 pt-4">
                      <div className="text-sm text-gray-500 mb-2">Items:</div>
                      <div className="space-y-2">
                        <div className="bg-white p-2 rounded border border-gray-200">
                          {'{item_description}'} - {'{item_amount}'}
                        </div>
                        <div className="bg-white p-2 rounded border border-gray-200">
                          {'{item_description}'} - {'{item_amount}'}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg p-6 shadow-sm">
            <h4 className="text-lg font-semibold mb-4">Portal Customization</h4>
            <div className="space-y-4">
              <div>
                <label className="block text sm font-medium text-gray-700 mb-1">
                  Custom CSS
                </label>
                <textarea
                  className="w-full h-32 px-4 py-2 rounded-lg border border-gray-200 font-mono text-sm focus:ring-2 focus:ring-accent-500 focus:border-transparent"
                  placeholder=".custom-header { ... }"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Custom JavaScript
                </label>
                <textarea
                  className="w-full h-32 px-4 py-2 rounded-lg border border-gray-200 font-mono text-sm focus:ring-2 focus:ring-accent-500 focus:border-transparent"
                  placeholder="// Custom portal scripts"
                />
              </div>
            </div>
          </div>
        </div>
      )
    }
  ];

  return (
    <div className="min-h-screen">
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <div className="flex flex-col lg:flex-row gap-6">
          <Tab.List className="w-full lg:w-64 flex lg:flex-col overflow-x-auto lg:overflow-x-visible gap-1 p-1 bg-gray-50 lg:bg-transparent rounded-lg lg:rounded-none">
            {sections.map((section) => (
              <Tab
                key={section.id}
                className={({ selected }) => cn(
                  'flex items-center gap-3 px-4 py-3 text-sm font-medium rounded-lg transition-colors whitespace-nowrap',
                  'flex-1 lg:flex-none',
                  selected
                    ? 'bg-accent-500 text-white'
                    : 'text-gray-600 hover:bg-gray-100'
                )}
              >
                <section.icon className="h-5 w-5" />
                {section.label}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels className="w-full">
            {sections.map((section) => (
              <Tab.Panel key={section.id} className="px-2 lg:px-0">
                {section.component}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </div>
      </Tab.Group>
    </div>
  );
}