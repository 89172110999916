import { Search } from 'lucide-react';

interface BlogHeaderProps {
  searchQuery: string;
  onSearchChange: (query: string) => void;
}

export default function BlogHeader({ searchQuery, onSearchChange }: BlogHeaderProps) {
  return (
    <div className="text-center mb-12">
      <h1 className="text-4xl font-bold bg-gradient-to-r from-accent-400 to-primary-400 bg-clip-text text-transparent mb-4">
        Travel Insights & Inspiration
      </h1>
      <p className="text-xl text-gray-300 max-w-2xl mx-auto mb-8">
        Discover expert tips, destination guides, and travel stories to help you plan your next adventure.
      </p>
      
      <div className="max-w-2xl mx-auto">
        <div className="relative group">
          <div className="absolute -inset-0.5 bg-gradient-to-r from-accent-500 to-primary-600 rounded-xl blur opacity-30 group-hover:opacity-50 transition duration-200" />
          <div className="relative">
            <Search className="absolute left-4 top-1/2 -translate-y-1/2 h-5 w-5 text-accent-400" />
            <input
              type="text"
              placeholder="Search articles..."
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
              className="w-full pl-12 pr-4 py-3 bg-gray-900/60 border border-white/10 rounded-xl text-white placeholder-gray-400 focus:ring-2 focus:ring-accent-400 focus:border-transparent backdrop-blur-sm"
            />
          </div>
        </div>
      </div>
    </div>
  );
}