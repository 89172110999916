import axios from 'axios';

interface HotelSearchParams {
  location: string;
  checkIn: string;
  checkOut: string;
  currency?: string;
  language?: string;
  country?: string;
}

interface HotelSearchResponse {
  search_metadata: {
    id: string;
    status: string;
    json_endpoint: string;
    created_at: string;
    processed_at: string;
    google_hotels_url: string;
    total_time_taken: number;
  };
  search_parameters: {
    engine: string;
    q: string;
    check_in_date: string;
    check_out_date: string;
    currency: string;
    hl: string;
    gl: string;
  };
  properties: Array<{
    name: string;
    link: string;
    description?: string;
    rating: number;
    reviews: number;
    price: {
      total: {
        value: number;
        currency: string;
      };
      per_night: {
        value: number;
        currency: string;
      };
    };
    images: string[];
    amenities: string[];
    location: {
      address: string;
      coordinates: {
        lat: number;
        lng: number;
      };
    };
  }>;
}

export async function searchHotels(params: HotelSearchParams & { accommodation?: string }): Promise<HotelSearchResponse> {
    try {
      const searchQuery = params.accommodation
        ? `stay at ${params.accommodation} in ${params.location}`
        : `hotels in ${params.location}`;
  
      const response = await axios.post('/api/hotels/search', {
        q: searchQuery,
        check_in_date: params.checkIn,
        check_out_date: params.checkOut,
        currency: params.currency || 'USD',
        hl: params.language || 'en',
        gl: params.country || 'us'
      });
  
      return response.data;
    } catch (error) {
      console.error('Error searching hotels:', error);
      throw new Error('Failed to search hotels');
    }
  }