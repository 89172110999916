import { useState } from 'react';
import { Hotel, X } from 'lucide-react';
import { searchHotels } from '../../services/hotels';
import type { Hotel as HotelType } from '../../types/hotels';
import DateSelector from './HotelSearchModal/DateSelector';
import HotelCard from './HotelSearchModal/HotelCard';

interface HotelSearchProps {
  destination: string;
  onClose: () => void;
  suggestedDates: {
    start: string;
    end: string;
    reason: string;
  };
  accommodation?: string;
}

export default function HotelSearch({
  destination,
  onClose,
  suggestedDates,
  accommodation
}: HotelSearchProps) {
  const [dateOption, setDateOption] = useState<'custom' | 'suggested'>('suggested');
  const [customDates, setCustomDates] = useState({
    checkIn: '',
    checkOut: ''
  });
  const [hotels, setHotels] = useState<HotelType[] | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDateChange = (type: 'checkIn' | 'checkOut', value: string) => {
    setCustomDates(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const handleSearchHotels = async () => {
    setIsSearching(true);
    setError(null);

    const dates = dateOption === 'suggested'
      ? { checkIn: suggestedDates.start, checkOut: suggestedDates.end }
      : customDates;

    try {
      const response = await searchHotels({
        location: destination,
        checkIn: dates.checkIn,
        checkOut: dates.checkOut,
        currency: 'USD',
        language: 'en',
        country: 'us',
        accommodation
      });
      setHotels(response.properties || []);
    } catch (error) {
      console.error('Error searching hotels:', error);
      setError('Unable to find hotels. Please try different dates.');
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <div className="p-4 rounded-xl bg-gray-800/50 border border-accent-500/20 space-y-4 mb-6">
      <div className="flex justify-between items-center">
        <h4 className="text-lg font-semibold text-white">Find Hotels</h4>
        <button
          onClick={onClose}
          className="p-2 text-gray-400 hover:text-white transition-colors rounded-lg hover:bg-white/10"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      <DateSelector
        dateOption={dateOption}
        customDates={customDates}
        suggestedDates={suggestedDates}
        onDateOptionChange={setDateOption}
        onCustomDateChange={handleDateChange}
      />

      <button
        onClick={handleSearchHotels}
        disabled={dateOption === 'custom' && (!customDates.checkIn || !customDates.checkOut)}
        className={`
          w-full py-3 px-4 rounded-lg font-medium transition-all duration-200
          ${dateOption === 'suggested' || (customDates.checkIn && customDates.checkOut)
            ? 'bg-accent-500 hover:bg-accent-600 text-white'
            : 'bg-gray-700 text-gray-400 cursor-not-allowed'
          }
        `}
      >
        Search Hotels
      </button>

      {/* Results */}
      {isSearching ? (
        <div className="flex flex-col items-center justify-center py-12">
          <div className="w-16 h-16 border-4 border-accent-500 border-t-transparent rounded-full animate-spin" />
          <p className="mt-4 text-gray-400">Searching for hotels...</p>
        </div>
      ) : error ? (
        <div className="text-center py-4">
          <p className="text-red-400">{error}</p>
        </div>
      ) : hotels ? (
        <div className="space-y-6">
          {hotels.map((hotel, index) => (
            <HotelCard key={index} hotel={hotel} />
          ))}
        </div>
      ) : null}
    </div>
  );
}