interface DealSummaryProps {
    stage: {
      id: string;
      label: string;
      color: string;
    };
    deals: any[];
    totalValue: number;
  }
  
  export default function DealSummary({ stage, deals, totalValue }: DealSummaryProps) {
    return (
      <div className="bg-white rounded-lg p-3 sm:p-4 shadow-sm">
        <div className="flex items-center gap-1.5 sm:gap-2 mb-1.5 sm:mb-2">
          <div className={`w-2 sm:w-3 h-2 sm:h-3 rounded-full ${stage.color}`} />
          <h3 className="font-medium text-gray-900 text-sm sm:text-base">{stage.label}</h3>
        </div>
        <div className="text-lg sm:text-2xl font-bold text-gray-900">
          ${totalValue.toLocaleString()}
        </div>
        <div className="text-xs sm:text-sm text-gray-500 mt-0.5 sm:mt-1">
          {deals.length} deals
        </div>
      </div>
    );
  }