import { Wifi, Coffee, Car, Building } from 'lucide-react';

interface HotelAmenitiesProps {
  amenities: string[];
}

export default function HotelAmenities({ amenities }: HotelAmenitiesProps) {
  const amenityIcons: { [key: string]: any } = {
    'Free Wi-Fi': Wifi,
    'Breakfast': Coffee,
    'Parking': Car,
    'Air conditioning': Building,
  };

  return amenities.slice(0, 4).map((amenity, index) => {
    const Icon = amenityIcons[amenity] || Building;
    return (
      <div
        key={index}
        className="flex items-center gap-1.5 px-2 py-1 bg-white/5 rounded-lg text-xs text-gray-300"
      >
        <Icon className="h-3 w-3" />
        <span>{amenity}</span>
      </div>
    );
  });
}