import { useState, useEffect } from 'react';
import { X, Clock, MapPin, Plus, Search, Activity, Utensils } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import axios from 'axios';

interface SavedItem {
  _id: string;
  title?: string;
  name?: string;
  type: 'activity' | 'dining';
  location?: string;
  description?: string;
}

interface ScheduleItemInput {
  type: 'activity' | 'dining' | 'custom' | 'travel' | 'accommodation';
  title: string;
  description?: string;
  startTime: string;
  endTime: string;
  location?: string;
  notes?: string;
  reference?: string;
}

interface AddScheduleItemModalProps {
  onClose: () => void;
  onAdd: (item: ScheduleItemInput) => void;
  destinationName?: string;
}

export default function AddScheduleItemModal({ onClose, onAdd, destinationName }: AddScheduleItemModalProps) {
  const { user } = useAuth();
  const [selectedType, setSelectedType] = useState<ScheduleItemInput['type']>('custom');
  const [savedItems, setSavedItems] = useState<SavedItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<SavedItem | null>(null);
  const [isLoadingItems, setIsLoadingItems] = useState(false);
  const [formData, setFormData] = useState<ScheduleItemInput>({
    type: 'custom',
    title: '',
    description: '',
    startTime: '',
    endTime: '',
    location: '',
    notes: ''
  });

  useEffect(() => {
    if (selectedType === 'custom' || !destinationName || !user?.googleId) return;

    const loadSavedItems = async () => {
      setIsLoadingItems(true);
      try {
        let items: SavedItem[] = [];

        // Load activities
        if (selectedType === 'activity') {
          const activityResponse = await axios.get(`/api/users/activities/${destinationName}`, {
            headers: { Authorization: `Bearer ${user.googleId}` }
          });
          items = activityResponse.data.map((item: any) => ({
            _id: item._id,
            title: item.title,
            type: 'activity',
            location: item.location,
            description: item.description
          }));
        }
        // Load dining options
        else if (selectedType === 'dining') {
          const diningResponse = await axios.get(`/api/users/dining/${destinationName}`, {
            headers: { Authorization: `Bearer ${user.googleId}` }
          });
          items = diningResponse.data.map((item: any) => ({
            _id: item._id,
            title: item.name,
            type: 'dining',
            location: item.location,
            description: item.description
          }));
        }
        
        // Sort alphabetically by title
        setSavedItems(items.sort((a, b) => (a.title || '').localeCompare(b.title || '')));
      } catch (error) {
        console.error('Error loading saved items:', error);
      } finally {
        setIsLoadingItems(false);
      }
    };

    loadSavedItems();
  }, [selectedType, destinationName, user?.googleId]);

  const handleTypeChange = (type: ScheduleItemInput['type']) => {
    setSelectedType(type);
    setSelectedItem(null);
    setFormData(prev => ({
      ...prev,
      type,
      title: '',
      description: '',
      location: ''
    }));
  };

  const handleItemSelect = (item: SavedItem | null) => {
    setSelectedItem(item);
    if (item) {
      setFormData(prev => ({
        ...prev,
        title: item.title || item.name || '',
        description: item.description || '',
        location: item.location || '',
        reference: item._id
      }));
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAdd(formData);
  };

  return (
    <div className="fixed inset-0 z-50">
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm" onClick={onClose} />
      
      <div className="fixed inset-0 pointer-events-none flex items-center justify-center p-4">
        <div className="relative w-full max-w-lg bg-gray-900 rounded-2xl shadow-xl pointer-events-auto">
          <div className="flex items-center justify-between p-6 border-b border-gray-800">
            <h3 className="text-lg font-semibold text-white">Add Schedule Item</h3>
            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-white rounded-lg transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="p-6 space-y-6">
            {/* Type Selection */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-300">
                Type
              </label>
              <div className="grid grid-cols-2 sm:grid-cols-5 gap-2">
                <button
                  type="button"
                  onClick={() => handleTypeChange('activity')}
                  className={`
                    flex items-center justify-center gap-2 p-3 rounded-lg transition-all duration-200
                    ${selectedType === 'activity'
                      ? 'bg-emerald-500/20 text-emerald-400 border border-emerald-500/30'
                      : 'bg-white/5 text-gray-400 hover:bg-white/10 border border-transparent'
                    }
                  `}
                >
                  <Activity className="h-4 w-4" />
                  <span>Activity</span>
                </button>
                <button
                  type="button"
                  onClick={() => handleTypeChange('dining')}
                  className={`
                    flex items-center justify-center gap-2 p-3 rounded-lg transition-all duration-200
                    ${selectedType === 'dining'
                      ? 'bg-orange-500/20 text-orange-400 border border-orange-500/30'
                      : 'bg-white/5 text-gray-400 hover:bg-white/10 border border-transparent'
                    }
                  `}
                >
                  <Utensils className="h-4 w-4" />
                  <span>Dining</span>
                </button>
                <button
                  type="button"
                  onClick={() => handleTypeChange('travel')}
                  className={`
                    flex items-center justify-center gap-2 p-3 rounded-lg transition-all duration-200
                    ${selectedType === 'travel'
                      ? 'bg-purple-500/20 text-purple-400 border border-purple-500/30'
                      : 'bg-white/5 text-gray-400 hover:bg-white/10 border border-transparent'
                    }
                  `}
                >
                  <MapPin className="h-4 w-4" />
                  <span>Travel</span>
                </button>
                <button
                  type="button"
                  onClick={() => handleTypeChange('accommodation')}
                  className={`
                    flex items-center justify-center gap-2 p-3 rounded-lg transition-all duration-200
                    ${selectedType === 'accommodation'
                      ? 'bg-blue-500/20 text-blue-400 border border-blue-500/30'
                      : 'bg-white/5 text-gray-400 hover:bg-white/10 border border-transparent'
                    }
                  `}
                >
                  <Clock className="h-4 w-4" />
                  <span>Stay</span>
                </button>
                <button
                  type="button"
                  onClick={() => handleTypeChange('custom')}
                  className={`
                    flex items-center justify-center gap-2 p-3 rounded-lg transition-all duration-200
                    ${selectedType === 'custom'
                      ? 'bg-accent-500/20 text-accent-400 border border-accent-500/30'
                      : 'bg-white/5 text-gray-400 hover:bg-white/10 border border-transparent'
                    }
                  `}
                >
                  <Plus className="h-4 w-4" />
                  <span>Custom</span>
                </button>
              </div>
            </div>

            {/* Saved Items Selection */}
            {(selectedType === 'activity' || selectedType === 'dining') && (
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-300">
                  Select {selectedType === 'activity' ? 'an Activity' : 'a Restaurant'}
                </label>
                <div className="relative">
                  <select
                    value={selectedItem?._id || ''}
                    onChange={(e) => {
                      if (e.target.value === 'new') {
                        handleItemSelect(null);
                      } else {
                        const item = savedItems.find(i => i._id === e.target.value);
                        if (item) handleItemSelect(item);
                      }
                    }}
                    className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:ring-2 focus:ring-accent-400 focus:border-transparent"
                  >
                    <option value="">Select {selectedType === 'activity' ? 'an activity' : 'a restaurant'}...</option>
                    {savedItems.map(item => (
                      <option key={item._id} value={item._id}>
                        {item.title || item.name}
                      </option>
                    ))}
                    <option value="new">+ Add New {selectedType === 'activity' ? 'Activity' : 'Restaurant'}</option>
                  </select>
                  {isLoadingItems && (
                    <div className="absolute right-3 top-1/2 -translate-y-1/2">
                      <div className="w-5 h-5 border-2 border-accent-500 border-t-transparent rounded-full animate-spin" />
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Title */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-300">
                Title
              </label>
              <input
                type="text"
                value={formData.title}
                onChange={(e) => setFormData(prev => ({ ...prev, title: e.target.value }))}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:ring-2 focus:ring-accent-400 focus:border-transparent"
                required
              />
            </div>

            {/* Time Selection */}
            <div className="grid grid-cols-2 gap-4">
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-300">
                  Start Time
                </label>
                <input
                  type="time"
                  value={formData.startTime}
                  onChange={(e) => setFormData(prev => ({ ...prev, startTime: e.target.value }))}
                  className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:ring-2 focus:ring-accent-400 focus:border-transparent"
                  required
                />
              </div>
              <div className="space-y-2">
                <label className="block text-sm font-medium text-gray-300">
                  End Time
                </label>
                <input
                  type="time"
                  value={formData.endTime}
                  onChange={(e) => setFormData(prev => ({ ...prev, endTime: e.target.value }))}
                  className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:ring-2 focus:ring-accent-400 focus:border-transparent"
                  required
                />
              </div>
            </div>

            {/* Location */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-300">
                Location
              </label>
              <input
                type="text"
                value={formData.location}
                onChange={(e) => setFormData(prev => ({ ...prev, location: e.target.value }))}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:ring-2 focus:ring-accent-400 focus:border-transparent"
              />
            </div>

            {/* Description */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-300">
                Description
              </label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:ring-2 focus:ring-accent-400 focus:border-transparent"
                rows={3}
              />
            </div>

            {/* Notes */}
            <div className="space-y-2">
              <label className="block text-sm font-medium text-gray-300">
                Notes
              </label>
              <textarea
                value={formData.notes}
                onChange={(e) => setFormData(prev => ({ ...prev, notes: e.target.value }))}
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white focus:ring-2 focus:ring-accent-400 focus:border-transparent"
                rows={2}
              />
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end gap-4">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-gray-300 hover:text-white hover:bg-white/10 rounded-lg transition-colors"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-accent-500 hover:bg-accent-600 text-white rounded-lg transition-colors"
              >
                Add to Schedule
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}