import { useState } from 'react';
import { Brain, ArrowLeft, ArrowRight, MapPin } from 'lucide-react';
import Button from './ui/Button';
import MultipleSelectIndicator from './ui/MultipleSelectIndicator';
import { surveyQuestions } from '../data/surveyQuestions';
import type { SurveyResponse } from '../types/survey';
import { generateTravelRecommendations } from '../services/openai';
import TravelRecommendations from './TravelRecommendations';

interface AISurveyProps {
  onStartSearch: () => void;
}

export default function AISurvey({ onStartSearch }: AISurveyProps) {
  const [isStarted, setIsStarted] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [responses, setResponses] = useState<Partial<SurveyResponse>>({});
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [recommendation, setRecommendation] = useState(null);
  const [stayDomestic, setStayDomestic] = useState(false);

  const currentQuestion = surveyQuestions[currentStep];

  const handleOptionSelect = (optionId: string) => {
    if (currentQuestion.type === 'multiple') {
      setSelectedOptions(prev => 
        prev.includes(optionId)
          ? prev.filter(id => id !== optionId)
          : [...prev, optionId]
      );
    } else {
      setResponses(prev => ({
        ...prev,
        [currentQuestion.id]: optionId
      }));
      if (currentStep < surveyQuestions.length - 1) {
        setCurrentStep(prev => prev + 1);
      }
    }
  };

  const handleNext = async () => {
    if (currentQuestion.type === 'multiple') {
      setResponses(prev => ({
        ...prev,
        [currentQuestion.id]: selectedOptions
      }));
      setSelectedOptions([]);
    }

    if (currentStep === surveyQuestions.length - 1) {
      setIsLoading(true);
      try {
        const result = await generateTravelRecommendations({
          ...responses as SurveyResponse,
          stayDomestic
        });
        setRecommendation(result);
      } catch (error) {
        console.error('Error generating recommendations:', error);
      } finally {
        setIsLoading(false);
      }
    } else {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep === 0) {
      setIsStarted(false);
    } else {
      setCurrentStep(prev => prev - 1);
      setSelectedOptions([]);
    }
  };

  const handleStartOver = () => {
    setIsStarted(false);
    setCurrentStep(0);
    setResponses({});
    setSelectedOptions([]);
    setRecommendation(null);
    setStayDomestic(false);
  };

  if (!isStarted) {
    return (
      <div className="max-w-xl mx-auto glass-effect rounded-2xl shadow-lg p-8 border border-white/10 text-center">
        <div className="flex justify-center mb-6">
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-primary-600 to-accent-500 blur-lg opacity-50"></div>
            <Brain className="h-12 w-12 relative text-white animate-float" />
          </div>
        </div>
        <h2 className="text-2xl font-bold text-white mb-4">Discover Your Perfect Trip</h2>
        <p className="text-gray-200 mb-6">Let our AI travel planner design your dream journey</p>
        <div className="space-y-4">
          <Button 
            onClick={() => setIsStarted(true)}
            className="w-full bg-gradient-to-r from-primary-700 to-accent-600 text-white transform hover:scale-105 transition-all duration-200"
            size="lg"
          >
            <Brain className="h-5 w-5 mr-2" />
            Wander Wise
          </Button>
          <div className="relative">
            <div className="absolute inset-x-0 top-1/2 -translate-y-1/2 h-px bg-gradient-to-r from-transparent via-white/10 to-transparent rounded-full shadow-[0_0_5px_rgba(255,255,255,0.1)]"></div>
            <div className="flex justify-center">
              <span className="relative -top-[1px] px-4 text-sm text-gray-400 font-medium">
                or
              </span>
            </div>
          </div>
          <button
            onClick={onStartSearch}
            className="w-full px-6 py-3 bg-accent-500/20 hover:bg-accent-500/30 text-accent-300 hover:text-accent-200 rounded-xl transition-all duration-200 font-medium"
          >
            Search and Plan with AI Directly
          </button>
        </div>
      </div>
    );
  }

  if (recommendation) {
    return (
      <TravelRecommendations
        recommendation={recommendation}
        onStartOver={handleStartOver}
        surveyResponses={{ ...responses as SurveyResponse, stayDomestic }}
      />
    );
  }

  if (isLoading) {
    return (
      <div className="max-w-xl mx-auto glass-effect rounded-2xl shadow-lg p-8 border border-white/10 text-center">
        <div className="flex justify-center mb-6">
          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-primary-600 to-accent-500 blur-lg opacity-50"></div>
            <Brain className="h-12 w-12 relative text-white animate-float" />
          </div>
        </div>
        <h2 className="text-2xl font-bold text-white mb-4">Creating Your Perfect Trip</h2>
        <p className="text-gray-200 mb-6">Our AI is analyzing your preferences and crafting personalized recommendations...</p>
        <div className="w-full h-2 bg-white/10 rounded-full overflow-hidden">
          <div className="h-full bg-gradient-to-r from-primary-600 to-accent-500 animate-pulse" style={{ width: '60%' }}></div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-xl mx-auto glass-effect rounded-2xl shadow-lg p-8 border border-white/10">
      <div className="space-y-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-3">
            <MapPin className="h-5 w-5 text-accent-400" />
            <label className="flex items-center gap-3 text-white cursor-pointer text-base sm:text-base text-sm">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={stayDomestic}
                onChange={(e) => setStayDomestic(e.target.checked)}
              />
              <div className="relative w-10 h-6 bg-white/10 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-accent-300/50 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-accent-500"></div>
              U.S. Only
            </label>
          </div>
          <div className="flex items-center gap-2 text-xs sm:text-sm text-gray-300">
            <span>{currentStep + 1} of {surveyQuestions.length}</span>
            <div className="w-20 h-1 bg-white/10 rounded-full overflow-hidden">
              <div 
                className="h-full bg-gradient-to-r from-primary-600 to-accent-500 transition-all duration-300"
                style={{ width: `${((currentStep + 1) / surveyQuestions.length) * 100}%` }}
              ></div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <h2 className="text-xl sm:text-2xl font-bold text-white mb-2">{currentQuestion.title}</h2>
          {currentQuestion.description && (
            <p className="text-sm sm:text-base text-gray-300">{currentQuestion.description}</p>
          )}
          {currentQuestion.type === 'multiple' && (
            <div className="mt-2 text-xs sm:text-sm text-accent-400">
              Select all that apply
            </div>
          )}
        </div>
        
        <div className="space-y-4">
          <div className={`grid ${currentQuestion.type === 'multiple' ? 'grid-cols-2' : 'grid-cols-1'} gap-4`}>
            {currentQuestion.options.map((option) => (
              <button
                key={option.id}
                onClick={() => handleOptionSelect(option.id)}
                className={`
                  flex items-center gap-3 p-4 rounded-xl border text-left transition-all duration-200 text-sm sm:text-base
                  ${currentQuestion.type === 'multiple'
                    ? selectedOptions.includes(option.id)
                      ? 'border-accent-500 bg-white/10 text-white'
                      : 'border-white/20 text-white hover:bg-white/10'
                    : responses[currentQuestion.id] === option.id
                    ? 'border-accent-500 bg-white/10 text-white'
                    : 'border-white/20 text-white hover:bg-white/10'
                  }
                `}
              >
                {currentQuestion.type === 'multiple' && (
                  <MultipleSelectIndicator isSelected={selectedOptions.includes(option.id)} />
                )}
                <span className="flex-1">{option.label}</span>
              </button>
            ))}
          </div>
        </div>

        <div className="flex justify-between pt-4">
          <Button
            onClick={handleBack}
            variant="secondary"
            className="text-gray-300 hover:text-white bg-white/10"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back
          </Button>
          <Button
            onClick={handleNext}
            disabled={currentQuestion.type === 'multiple' && selectedOptions.length === 0}
            className="bg-gradient-to-r from-primary-700 to-accent-600 text-white transform hover:scale-105 transition-all duration-200 whitespace-nowrap text-sm sm:text-base px-3 sm:px-4"
          >
            <span className="hidden sm:inline">
              {currentStep === surveyQuestions.length - 1 ? 'Get Recommendations' : 'Next'}
            </span>
            <span className="sm:hidden">
              {currentStep === surveyQuestions.length - 1 ? 'Get Results' : 'Next'}
            </span>
            <ArrowRight className="h-4 w-4 ml-2" />
          </Button>
        </div>
      </div>
    </div>
  );
}