import { Check, X, ExternalLink, Calendar } from 'lucide-react';
import SEOHead from '@/components/SEO/SEOHead';
import StructuredData from '@/components/SEO/StructuredData';
import EnterpriseDemo from '@/components/EnterpriseDemo/EnterpriseDemo';
import EnterpriseCRM from '@/components/EnterpriseCRM/EnterpriseCRM';

const plans = [
  {
    name: 'Free',
    description: 'Perfect for exploring travel possibilities',
    price: '$0',
    period: 'forever',
    features: [
      'Unlimited AI travel recommendations',
      'Basic trip organization',
      'Flight and hotel search',
      'Activity suggestions',
      'Save up to 3 recommendations',
      'Community access'
    ],
    limitations: [
      'Limited to 3 saved recommendations',
      'Basic customization only',
      'Standard response time',
      'No schedule builder',
      'No offline access',
      'No priority support'
    ],
    cta: 'Get Started',
    ctaLink: '/signup',
    highlight: true,
    freeHighlights: [
      'Get unlimited AI travel recommendations',
      'Explore destinations worldwide',
      'Basic trip research tools'
    ]
  },
  {
    name: 'Pro',
    description: 'For serious travel planners',
    price: '$4.99',
    period: 'per month',
    yearlyPrice: '$47',
    yearlyPeriod: 'per year',
    features: [
      'Everything in Free, plus:',
      'Full trip schedule builder',
      'Unlimited saved trips',
      'Detailed itinerary planning',
      'Advanced customization',
      'Priority AI processing',
      'Export to calendar',
      'Offline access',
      'Priority support',
      'Early access to new features'
    ],
    limitations: [],
    cta: 'Start Free Trial',
    ctaLink: '/pro-signup',
    highlight: false,
    savingsNote: 'Save 21% with annual plan'
  },
  {
    name: 'Enterprise',
    description: 'Complete travel agency solution',
    price: 'Custom',
    period: 'contact us',
    features: [
      'Full-featured CRM system',
      'White label solutions',
      'Client management portal',
      'Team collaboration tools',
      'Multi-branch support',
      'Custom AI training',
      'Advanced analytics',
      'Dedicated account manager',
      'Premium support SLA',
      'Custom integrations',
      'Booking management',
      'API access'
    ],
    limitations: [],
    cta: 'Book a Call',
    ctaLink: 'https://calendly.com/wanderwise/demo',
    highlight: false,
    enterprise: true,
    businessHighlights: [
      'Complete travel agency solution',
      'Enterprise-grade features',
      'Customizable workflows'
    ]
  }
];

export default function Pricing() {
  const structuredData = {
    '@type': 'Product',
    name: 'WanderWise Travel Planning Platform',
    description: 'AI-powered travel planning solutions for individuals and businesses',
    offers: {
      '@type': 'AggregateOffer',
      offerCount: '3',
      lowPrice: '0',
      highPrice: '4.99',
      priceCurrency: 'USD',
      offers: plans.map(plan => ({
        '@type': 'Offer',
        name: plan.name,
        price: plan.price === 'Custom' ? '200' : plan.price.replace('$', ''),
        priceCurrency: 'USD',
        description: plan.description
      }))
    }
  };

  return (
    <>
      <SEOHead
        title="Pricing Plans"
        description="Choose the perfect WanderWise plan for your travel planning needs. From individual travelers to enterprise solutions."
        canonicalUrl="https://wanderwise.com/pricing"
      />
      <StructuredData type="TravelAgency" data={structuredData} />

      {/* Scroll Banner */}
      <div
        onClick={() => {
          document.querySelector('#enterprise-crm')?.scrollIntoView({
            behavior: 'smooth'
          });
        }}
        className="fixed bottom-8 left-1/2 -translate-x-1/2 z-50 bg-black text-white px-6 py-3 rounded-full shadow-lg animate-bounce cursor-pointer hover:bg-gray-800 transition-colors"
      >
        <div className="flex items-center gap-2">
          <Calendar className="h-5 w-5" />
          <span>Scroll down to see our Enterprise CRM Demo</span>
        </div>
      </div>

      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header */}
          <div className="text-center max-w-3xl mx-auto mb-16">
            <h1 className="text-4xl font-bold text-white mb-4">
              Choose Your Perfect Plan
            </h1>
            <p className="text-xl text-gray-300">
              From solo travelers to enterprise solutions, we've got you covered
            </p>
          </div>

          {/* Pricing Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {plans.map((plan) => (
              <div
                key={plan.name}
                className={`
                  relative rounded-2xl overflow-hidden transition-all duration-300 hover:scale-105
                  ${plan.highlight
                    ? 'bg-gradient-to-b from-accent-500/20 to-primary-900/20 border-4 border-accent-500 shadow-[8px_8px_0px_0px_rgba(255,90,31,0.5)]'
                    : plan.enterprise
                    ? 'bg-gradient-to-b from-primary-700/20 to-primary-900/20 border-4 border-primary-600 shadow-[8px_8px_0px_0px_rgba(73,80,87,0.5)]'
                    : 'bg-gray-900/50 border-4 border-white/10 shadow-[8px_8px_0px_0px_rgba(255,255,255,0.1)]'
                  }
                `}
              >
                {plan.highlight && (
                  <div className="absolute top-0 right-0 bg-accent-500 text-white px-4 py-1 text-sm font-medium rounded-bl-lg">
                    Most Popular
                  </div>
                )}

                <div className="p-8">
                  <h3 className="text-2xl font-bold text-white mb-2">
                    {plan.name}
                  </h3>
                  <p className="text-gray-400 mb-6">
                    {plan.description}
                  </p>

                  <div className="mb-8">
                    <div className="flex items-baseline gap-2">
                      <span className="text-4xl font-bold text-white">
                        {plan.price}
                      </span>
                      <span className="text-gray-400">
                        {plan.period}
                      </span>
                    </div>
                    {plan.yearlyPrice && (
                      <div className="mt-2 text-sm">
                        <span className="text-accent-400">{plan.yearlyPrice}</span>
                        <span className="text-gray-400"> {plan.yearlyPeriod}</span>
                        {plan.savingsNote && (
                          <div className="text-xs text-accent-400 mt-1">
                            {plan.savingsNote}
                          </div>
                        )}
                      </div>
                    )}
                  </div>

                  {plan.freeHighlights && (
                    <div className="mb-8 p-4 bg-accent-500/10 rounded-lg border border-accent-500/20">
                      <h4 className="text-sm font-medium text-accent-400 mb-3">
                        Free Plan Highlights
                      </h4>
                      <ul className="space-y-2">
                        {plan.freeHighlights.map((highlight) => (
                          <li
                            key={highlight}
                            className="flex items-center gap-2 text-sm text-gray-300"
                          >
                            <div className="h-1.5 w-1.5 rounded-full bg-accent-500" />
                            {highlight}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  {plan.businessHighlights && (
                    <div className="mb-8 p-4 bg-primary-700/10 rounded-lg border border-primary-600/20">
                      <h4 className="text-sm font-medium text-primary-400 mb-3">
                        Enterprise Highlights
                      </h4>
                      <ul className="space-y-2">
                        {plan.businessHighlights.map((highlight) => (
                          <li
                            key={highlight}
                            className="flex items-center gap-2 text-sm text-gray-300"
                          >
                            <div className="h-1.5 w-1.5 rounded-full bg-primary-500" />
                            {highlight}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <a
                    href={plan.ctaLink}
                    target={plan.enterprise ? '_blank' : undefined}
                    rel={plan.enterprise ? 'noopener noreferrer' : undefined}
                    className={`
                      flex items-center justify-center gap-2
                      w-full py-3 px-6 rounded-xl font-medium border-4
                      transition-all duration-200
                      ${plan.highlight
                        ? 'bg-accent-500 hover:bg-accent-600 text-white border-black'
                        : plan.enterprise
                        ? 'bg-primary-700 hover:bg-primary-800 text-white border-black'
                        : 'bg-white hover:bg-gray-100 text-black border-black'
                      }
                    `}
                  >
                    {plan.cta}
                    {plan.enterprise && <ExternalLink className="h-4 w-4" />}
                  </a>

                  <div className="mt-8">
                    <h4 className="text-sm font-medium text-white mb-4">
                      Features included:
                    </h4>
                    <ul className="space-y-3">
                      {plan.features.map((feature) => (
                        <li
                          key={feature}
                          className="flex items-start gap-3 text-sm"
                        >
                          <Check className="h-5 w-5 text-emerald-500 flex-shrink-0" />
                          <span className="text-gray-300">{feature}</span>
                        </li>
                      ))}
                    </ul>

                    {plan.limitations.length > 0 && (
                      <>
                        <h4 className="text-sm font-medium text-white mt-6 mb-4">
                          Limitations:
                        </h4>
                        <ul className="space-y-3">
                          {plan.limitations.map((limitation) => (
                            <li
                              key={limitation}
                              className="flex items-start gap-3 text-sm"
                            >
                              <X className="h-5 w-5 text-gray-500 flex-shrink-0" />
                              <span className="text-gray-400">{limitation}</span>
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Enterprise Contact */}
          <div className="mt-16 text-center">
            <p className="text-gray-400">
              Need a custom solution? {' '}
              <a
                href="mailto:enterprise@wanderwise.com"
                className="text-accent-400 hover:text-accent-300 transition-colors"
              >
                Contact our enterprise team
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* Enterprise Demo Section */}
      <EnterpriseDemo />
      <EnterpriseCRM />
    </>
  );
}