import { Clipboard, X } from 'lucide-react';
import TripSlip from './TripSlip';
import { cn } from '@/lib/utils';
import { useTripSlip } from '../../contexts/TripSlipContext';

export default function FloatingTripSlip() {
  const { destinations, isOpen, setIsOpen } = useTripSlip();

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="fixed left-6 bottom-6 z-50 flex items-center gap-2 px-4 py-3 bg-gradient-to-r from-primary-700 to-accent-600 text-white rounded-full shadow-lg hover:scale-105 transition-all duration-200 group md:block hidden"
      >
        <Clipboard className="h-5 w-5" />
        <span className="hidden group-hover:inline">Trip Slip</span>
        {destinations.length > 0 && (
          <span className="absolute -top-2 -right-2 w-6 h-6 bg-accent-500 rounded-full flex items-center justify-center text-sm font-bold">
            {destinations.length}
          </span>
        )}
      </button>

      <div
        className={cn(
          'fixed inset-0 z-50 bg-black/50 backdrop-blur-sm transition-opacity duration-200',
          isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        )}
        onClick={() => setIsOpen(false)}
      />

      <div
        className={cn(
          'fixed right-0 top-0 z-50 h-full w-full max-w-2xl bg-gradient-to-b from-gray-900 to-gray-800 shadow-xl transition-transform duration-300 ease-in-out overflow-y-auto',
          isOpen ? 'translate-x-0' : 'translate-x-full'
        )}
      >
        <div className="sticky top-0 z-10 flex items-center justify-between p-4 bg-gradient-to-b from-gray-900 to-transparent">
          <h2 className="text-xl font-bold text-white">Trip Slip</h2>
          <button
            onClick={() => setIsOpen(false)}
            className="p-2 text-gray-400 hover:text-white transition-colors"
          >
            <X className="h-6 w-6" />
          </button>
        </div>
        
        <div className="p-6">
          <TripSlip />
        </div>
      </div>
    </>
  );
}