import { motion } from 'framer-motion';
import { useAgency } from '../../contexts/AgencyContext';
import DealTracker from '../../components/EnterpriseCRM/DealTracker';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

export default function Trips() {
  const { agency } = useAgency();

  if (!agency) return null;

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="space-y-6"
    >
      <DealTracker />
    </motion.div>
  );
}