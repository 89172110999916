import { useState } from 'react';
import {
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  KeyboardSensor,
  PointerSensor,
  DragEndEvent,
  DragStartEvent,
  DragOverEvent
} from '@dnd-kit/core';
import { sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import DealColumn from './components/DealColumn';
import DealOverlay from './components/DealOverlay';
import DealSummary from './DealSummary';
import { useDealColumns, type ColumnType } from './hooks/useDealColumns';
import { mockDeals, stages } from './mockData';

export default function DealTracker() {
  const { columns, moveDeal, getTotalValue, getDealById } = useDealColumns(mockDeals);
  const [activeDealId, setActiveDealId] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    setActiveDealId(active.id as string);
  };

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over } = event;
    if (!over) return;

    const activeDealId = active.id as string;
    const overId = over.id as string;

    // Find the deal
    const deal = getDealById(activeDealId);
    if (!deal) return;

    // Only move if hovering over a different column
    if (deal.status !== overId && stages.some(stage => stage.id === overId)) {
      moveDeal(activeDealId, deal.status as ColumnType, overId as ColumnType);
    }
  };

  const handleDragEnd = () => {
    setActiveDealId(null);
  };

  const handleDragCancel = () => {
    setActiveDealId(null);
  };

  const activeDeal = activeDealId ? getDealById(activeDealId) : null;

  return (
    <div className="space-y-6">
      {/* Pipeline Summary */}
      <div className="grid grid-cols-2 sm:grid-cols-4 gap-2 sm:gap-4">
        {stages.map((stage) => (
          <DealSummary
            key={stage.id}
            stage={stage}
            deals={columns[stage.id as ColumnType] || []}
            totalValue={getTotalValue(stage.id as ColumnType)}
          />
        ))}
      </div>

      {/* Deal Board */}
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-4">
          {stages.map((stage) => (
            <DealColumn
              key={stage.id}
              id={stage.id}
              title={stage.label}
              deals={columns[stage.id as ColumnType] || []}
              color={stage.color}
            />
          ))}
        </div>

        <DragOverlay>
          {activeDeal && <DealOverlay deal={activeDeal} />}
        </DragOverlay>
      </DndContext>
    </div>
  );
}