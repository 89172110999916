import { MapPin, Compass, Utensils, Calendar, Clipboard } from 'lucide-react';
import { Link } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { useTripSlip } from '../contexts/TripSlipContext';

interface MobileNavigationProps {
  currentPath: string;
}

export default function MobileNavigation({ currentPath }: MobileNavigationProps) {
  const { destinations, setIsOpen } = useTripSlip();

  const navItems = [
    {
      to: '/activities',
      label: 'Activities',
      icon: Compass,
    },
    {
      to: '/destinations',
      label: 'Destinations',
      icon: MapPin,
    },
    {
      to: '/dining',
      label: 'Dining',
      icon: Utensils,
    },
    {
      to: '/itineraries',
      label: 'Itineraries',
      icon: Calendar,
    },
    {
      to: '#',
      label: 'Trip Slip',
      icon: Clipboard,
      onClick: () => setIsOpen(true),
      badge: destinations.length > 0 ? destinations.length : undefined
    }
  ];

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-white/90 backdrop-blur-md border-t border-gray-200 shadow-lg z-50">
      <div className="flex items-center justify-around h-16">
        {navItems.map((item) => {
          const isActive = currentPath === item.to;
          const Component = item.onClick ? 'button' : Link;

          return (
            <Component
              key={item.to}
              to={item.onClick ? undefined : item.to}
              onClick={item.onClick}
              className={cn(
                'flex flex-col items-center justify-center w-full h-full space-y-1 transition-colors relative',
                isActive
                  ? 'text-accent-600'
                  : 'text-gray-500 hover:text-accent-500'
              )}
            >
              <item.icon className={cn(
                'h-5 w-5 transition-all duration-300',
                isActive && 'scale-110'
              )} />
              <span className="text-xs font-medium">{item.label}</span>
              {item.badge && (
                <span className="absolute -top-1 right-1/4 w-5 h-5 bg-accent-500 rounded-full flex items-center justify-center text-xs font-bold text-white">
                  {item.badge}
                </span>
              )}
            </Component>
          );
        })}
      </div>
    </div>
  );
}