import { Droppable } from 'react-beautiful-dnd';
import ScheduleItemComponent from './ScheduleItem';
import type { ScheduleColumnProps } from './types';

export default function ScheduleColumn({ date, items, onItemsChange }: ScheduleColumnProps) {
  const handleDelete = (id: string) => {
    const newItems = items.filter(item => item._id !== id);
    onItemsChange(newItems);
  };

  return (
    <Droppable droppableId={date}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className={`
            space-y-3 min-h-[200px] transition-colors rounded-lg p-4
            ${snapshot.isDraggingOver ? 'bg-accent-500/10' : 'bg-transparent'}
          `}
        >
          {items.map((item, index) => (
            <ScheduleItemComponent
              key={item._id}
              item={item}
              index={index}
              onDelete={handleDelete}
            />
          ))}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
}