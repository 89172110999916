import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTripSlip } from '../contexts/TripSlipContext';
import { MapPin, LogIn } from 'lucide-react';
import SEOHead from '@/components/SEO/SEOHead';
import StructuredData from '@/components/SEO/StructuredData';
import GoogleSignIn from '@/components/auth/GoogleSignIn';
import CollapsibleDestination from '@/components/TravelRecommendations/CollapsibleDestination';
import Alert from '@/components/ui/Alert';
import axios from 'axios';

interface SavedDestination {
  name: string;
  description: string;
  activities: string[];
  estimatedBudget: string;
  bestTimeToVisit: string;
  accommodation: string;
  recommendedDuration: string;
  localTips: string[];
  tripDescription?: string;
  surveyResponses: any;
  savedAt: string;
}

export default function Destinations() {
  const [savedDestinations, setSavedDestinations] = useState<SavedDestination[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const { isAuthenticated, user } = useAuth();
  const { addDestination } = useTripSlip();
  const navigate = useNavigate();

  useEffect(() => {
    loadSavedDestinations();
  }, [isAuthenticated, user?.googleId]);

  const loadSavedDestinations = async () => {
    if (!isAuthenticated || !user?.googleId) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.get('/api/users/destinations', {
        headers: { Authorization: `Bearer ${user.googleId}` }
      });
      // Sort destinations by savedAt date in descending order (newest first)
      const sortedDestinations = response.data.sort((a: SavedDestination, b: SavedDestination) => 
        new Date(b.savedAt).getTime() - new Date(a.savedAt).getTime()
      );
      setSavedDestinations(sortedDestinations);
    } catch (error) {
      console.error('Error loading saved destinations:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddToTripSlip = (destination: SavedDestination) => {
    addDestination(destination, destination.surveyResponses);
    setShowAlert(true);
  };

  const handleDeleteDestination = async (name: string) => {
    if (!isAuthenticated || !user?.googleId) return;

    try {
      await axios.delete(`/api/users/destinations/${encodeURIComponent(name)}`, {
        headers: { Authorization: `Bearer ${user.googleId}` }
      });
      setSavedDestinations(prev => prev.filter(d => d.name !== name));
    } catch (error) {
      console.error('Error deleting destination:', error);
    }
  };

  const destinationsData = {
    '@type': 'ItemList',
    itemListElement: savedDestinations.map((destination, index) => ({
      '@type': 'Place',
      position: index + 1,
      name: destination.name,
      description: destination.description,
      url: `https://wanderwise.com/destinations/${destination.name.toLowerCase().replace(/\s+/g, '-')}`
    }))
  };

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24">
        <div className="max-w-lg mx-auto px-4">
          <div className="text-center space-y-6">
            <div className="bg-accent-500/10 rounded-full p-4 w-16 h-16 mx-auto">
              <MapPin className="w-8 h-8 text-accent-400" />
            </div>
            <h1 className="text-3xl font-bold text-white">Save Your Favorite Destinations</h1>
            <p className="text-gray-300">
              Sign in to save destinations and access them from any device. Create collections of your dream destinations and easily add them to your trip planning.
            </p>
            <div className="flex justify-center">
              <GoogleSignIn />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="animate-pulse space-y-8">
            {[1, 2, 3].map((n) => (
              <div key={n} className="h-24 bg-white/5 rounded-2xl" />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEOHead
        title="Saved Destinations"
        description="View and manage your saved travel destinations. Access personalized recommendations and trip planning tools."
        canonicalUrl="https://wanderwise.com/destinations"
      />
      <StructuredData type="ItemList" data={destinationsData} />

      {showAlert && (
        <Alert
          type="success"
          message="Destination added to Trip Slip successfully!"
          onClose={() => setShowAlert(false)}
        />
      )}

      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-12 md:py-24">
        <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
          <div className="text-center mb-8 md:mb-12">
            <h1 className="text-2xl md:text-3xl font-bold text-white mb-3 md:mb-4">Your Saved Destinations</h1>
            <p className="text-sm md:text-base text-gray-300">
              {savedDestinations.length > 0
                ? 'Manage your saved destinations and quickly add them to your trip planning.'
                : 'Start saving destinations to build your travel wishlist.'}
            </p>
          </div>

          {savedDestinations.length === 0 ? (
            <div className="text-center py-8 md:py-12">
              <div className="bg-white/5 rounded-xl md:rounded-2xl p-6 md:p-8 max-w-lg mx-auto">
                <MapPin className="w-10 h-10 md:w-12 md:h-12 text-gray-400 mx-auto mb-3 md:mb-4" />
                <h3 className="text-lg md:text-xl font-medium text-white mb-2">No saved destinations yet</h3>
                <p className="text-sm md:text-base text-gray-400 mb-4 md:mb-6">
                  Take our AI travel quiz to discover and save destinations that match your travel style.
                </p>
                <button
                  onClick={() => navigate('/')}
                  className="inline-flex items-center px-4 py-2 rounded-lg bg-accent-500 text-white hover:bg-accent-600 transition-colors text-sm md:text-base"
                >
                  Take Travel Quiz
                </button>
              </div>
            </div>
          ) : (
            <div className="space-y-3 md:space-y-4">
              {savedDestinations.map((destination) => (
                <CollapsibleDestination
                  key={`${destination.name}-${destination.savedAt}`}
                  destination={destination}
                  onDelete={handleDeleteDestination}
                  onAddToTripSlip={handleAddToTripSlip}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}