import { useState, useEffect, useRef } from 'react';
import { Users, Building2, Calendar, ChartBar, Settings, Boxes } from 'lucide-react';
import anime from 'animejs';

interface DemoCardProps {
  title: string;
  description: string;
  icon: typeof Users;
  delay: number;
}

export default function DemoCard({ title, description, icon: Icon, delay }: DemoCardProps) {
  const cardRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    if (cardRef.current) {
      anime({
        targets: cardRef.current,
        translateY: [50, 0],
        opacity: [0, 1],
        delay,
        duration: 800,
        easing: 'easeOutElastic(1, .8)'
      });
    }
  }, [delay]);

  return (
    <div
      ref={cardRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className={`
        relative overflow-hidden bg-white border-4 border-black shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]
        p-6 transition-all duration-300
        ${isHovered ? 'translate-x-1 translate-y-1 shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]' : ''}
      `}
    >
      <div className="absolute top-0 right-0 w-20 h-20 bg-accent-500 transform rotate-45 translate-x-10 -translate-y-10" />
      
      <div className="relative">
        <div className="flex items-center gap-3 mb-4">
          <div className="p-3 bg-black">
            <Icon className="h-6 w-6 text-white" />
          </div>
          <h3 className="text-xl font-bold">{title}</h3>
        </div>
        
        <p className="text-gray-700">{description}</p>
      </div>
    </div>
  );
}