import { Brain, Calendar, DollarSign, Map } from 'lucide-react';
import { useEffect, useRef } from 'react';
import anime from 'animejs';

const features = [
  {
    name: 'Smart Planning',
    description: 'AI-powered travel plans tailored to your preferences and budget. Easily discover the unknown to you.',
    icon: Brain,
    gradient: {
      from: '#FF6B6B',
      to: '#4ECDC4'
    },
    shape: {
      default: 'M20,20 Q50,0 80,20 Q95,35 80,50 Q50,70 20,50 Q5,35 20,20',
      hover: 'M30,30 Q50,10 70,30 Q85,45 70,60 Q50,80 30,60 Q15,45 30,30',
      animate: [
        'M20,20 Q50,0 80,20 Q95,35 80,50 Q50,70 20,50 Q5,35 20,20',
        'M25,25 Q50,5 75,25 Q90,40 75,55 Q50,75 25,55 Q10,40 25,25',
        'M30,30 Q50,10 70,30 Q85,45 70,60 Q50,80 30,60 Q15,45 30,30'
      ]
    }
  },
  {
    name: 'Budget Optimization',
    description: 'Find the best deals and optimize your travel budget effectively.',
    icon: DollarSign,
    gradient: {
      from: '#A8E6CF',
      to: '#3BB4C1'
    },
    shape: {
      default: 'M30,20 Q50,10 70,20 Q85,35 70,50 Q50,60 30,50 Q15,35 30,20',
      hover: 'M25,25 Q50,15 75,25 Q90,40 75,55 Q50,65 25,55 Q10,40 25,25',
      animate: [
        'M30,20 Q50,10 70,20 Q85,35 70,50 Q50,60 30,50 Q15,35 30,20',
        'M25,25 Q50,15 75,25 Q90,40 75,55 Q50,65 25,55 Q10,40 25,25',
        'M20,30 Q50,20 80,30 Q95,45 80,60 Q50,70 20,60 Q5,45 20,30'
      ]
    }
  },
  {
    name: 'Smart Scheduling',
    description: 'Optimize your itinerary with AI-powered timing suggestions.',
    icon: Calendar,
    gradient: {
      from: '#FFD93D',
      to: '#FF6B6B'
    },
    shape: {
      default: 'M25,25 Q50,15 75,25 Q90,40 75,55 Q50,65 25,55 Q10,40 25,25',
      hover: 'M20,30 Q50,20 80,30 Q95,45 80,60 Q50,70 20,60 Q5,45 20,30',
      animate: [
        'M25,25 Q50,15 75,25 Q90,40 75,55 Q50,65 25,55 Q10,40 25,25',
        'M30,20 Q50,10 70,20 Q85,35 70,50 Q50,60 30,50 Q15,35 30,20',
        'M20,30 Q50,20 80,30 Q95,45 80,60 Q50,70 20,60 Q5,45 20,30'
      ]
    }
  },
  {
    name: 'Local Insights',
    description: 'Get personalized recommendations for authentic local experiences.',
    icon: Map,
    gradient: {
      from: '#6C5CE7',
      to: '#A8E6CF'
    },
    shape: {
      default: 'M20,30 Q50,20 80,30 Q95,45 80,60 Q50,70 20,60 Q5,45 20,30',
      hover: 'M25,25 Q50,15 75,25 Q90,40 75,55 Q50,65 25,55 Q10,40 25,25',
      animate: [
        'M20,30 Q50,20 80,30 Q95,45 80,60 Q50,70 20,60 Q5,45 20,30',
        'M25,25 Q50,15 75,25 Q90,40 75,55 Q50,65 25,55 Q10,40 25,25',
        'M30,20 Q50,10 70,20 Q85,35 70,50 Q50,60 30,50 Q15,35 30,20'
      ]
    }
  }
];

export default function Features() {
  const cardsRef = useRef<(HTMLDivElement | null)[]>([]);
  const shapesRef = useRef<(SVGPathElement | null)[]>([]);
  const animationsRef = useRef<anime.AnimeInstance[]>([]);
  const headingRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    // Initialize the heading animation
    if (headingRef.current) {
      const textWrapper = headingRef.current;
      textWrapper.innerHTML = textWrapper.textContent!.replace(/\S/g, "<span class='letter inline-block opacity-0 translate-y-[100px]'>$&</span>");

      anime.timeline({ loop: true })
        .add({
          targets: '.heading-animation .letter',
          translateY: [100, 0],
          translateZ: 0,
          opacity: [0, 1],
          easing: "easeOutExpo",
          duration: 1400,
          delay: (el, i) => 300 + 30 * i
        })
        .add({
          targets: '.heading-animation .letter',
          translateY: [0, -100],
          opacity: [1, 0],
          easing: "easeInExpo",
          duration: 1200,
          delay: (el, i) => 100 + 30 * i
        });
    }

    // Initialize card animations
    cardsRef.current.forEach((card, index) => {
      if (!card || !shapesRef.current[index]) return;

      anime({
        targets: card,
        translateY: [50, 0],
        opacity: [0, 1],
        delay: index * 200,
        duration: 1000,
        easing: 'easeOutElastic(1, .8)',
      });

      const floatingAnimation = anime({
        targets: card,
        translateY: [0, -10, 0],
        rotateZ: [0, 2, 0, -2, 0],
        duration: 4000 + index * 400,
        direction: 'alternate',
        loop: true,
        easing: 'easeInOutSine',
        autoplay: true
      });

      const shapeAnimation = anime({
        targets: shapesRef.current[index],
        d: [
          { value: features[index].shape.animate[0] },
          { value: features[index].shape.animate[1] },
          { value: features[index].shape.animate[2] },
          { value: features[index].shape.animate[0] }
        ],
        duration: 8000 + index * 1000,
        easing: 'easeInOutSine',
        loop: true,
        direction: 'alternate',
        autoplay: true
      });

      animationsRef.current.push(floatingAnimation, shapeAnimation);

      card.addEventListener('mouseenter', () => {
        anime({
          targets: card,
          scale: 1.05,
          duration: 500,
          easing: 'easeOutElastic(1, .8)'
        });

        anime({
          targets: shapesRef.current[index],
          d: features[index].shape.hover,
          duration: 800,
          easing: 'easeOutElastic(1, .8)'
        });
      });

      card.addEventListener('mouseleave', () => {
        anime({
          targets: card,
          scale: 1,
          duration: 500,
          easing: 'easeOutElastic(1, .8)'
        });

        anime({
          targets: shapesRef.current[index],
          d: features[index].shape.default,
          duration: 800,
          easing: 'easeOutElastic(1, .8)'
        });
      });
    });

    return () => {
      animationsRef.current.forEach(animation => animation.pause());
    };
  }, []);

  return (
    <div className="py-24 bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 ref={headingRef} className="heading-animation text-4xl font-bold bg-gradient-to-r from-primary-700 to-accent-600 bg-clip-text sm:text-5xl">
            Why Choose WanderWise?
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Experience smarter travel planning with our innovative features.
          </p>
        </div>

        <div className="mt-20">
          <div className="grid grid-cols-1 gap-12 sm:grid-cols-2 lg:grid-cols-4">
            {features.map((feature, index) => (
              <div
                key={feature.name}
                ref={(el) => (cardsRef.current[index] = el)}
                className="relative group perspective-1000"
              >
                <div className="h-full transform-style-3d">
                  <div className="relative bg-white border-4 border-black rounded-2xl p-8 h-full transform transition-all duration-300 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] hover:shadow-none hover:translate-x-2 hover:translate-y-2">
                    <svg
                      className="absolute inset-0 w-full h-full opacity-20"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="none"
                    >
                      <defs>
                        <linearGradient
                          id={`gradient-${index}`}
                          x1="0%"
                          y1="0%"
                          x2="100%"
                          y2="100%"
                        >
                          <stop
                            offset="0%"
                            stopColor={feature.gradient.from}
                          />
                          <stop
                            offset="100%"
                            stopColor={feature.gradient.to}
                          />
                        </linearGradient>
                      </defs>
                      <path
                        ref={(el) => (shapesRef.current[index] = el)}
                        className="transition-all duration-300"
                        d={feature.shape.default}
                        fill={`url(#gradient-${index})`}
                      />
                    </svg>
                    
                    <div className="relative z-10">
                      <div 
                        className="flex items-center justify-center h-12 w-12 rounded-xl border-4 border-black mx-auto transform transition-transform duration-300 group-hover:scale-110 bg-white"
                      >
                        <feature.icon className="h-6 w-6" />
                      </div>
                      <h3 className="mt-6 text-xl font-bold text-center">
                        {feature.name}
                      </h3>
                      <p className="mt-4 text-gray-600 text-center">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}