import { X, Mail, Phone, MapPin, Calendar, DollarSign, Users } from 'lucide-react';

interface Client {
  id: number;
  name: string;
  email: string;
  phone: string;
  location: string;
  status: string;
  trips: number;
  revenue: number;
}

interface ClientDetailsProps {
  client: Client;
  onClose: () => void;
}

export default function ClientDetails({ client, onClose }: ClientDetailsProps) {
  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-2 sm:p-4 z-50">
      <div className="bg-white border-2 sm:border-4 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] sm:shadow-[8px_8px_0px_0px_rgba(0,0,0,1)] w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="flex items-center justify-between p-4 sm:p-6 border-b-2 sm:border-b-4 border-black">
          <h3 className="text-xl sm:text-2xl font-bold truncate pr-2">{client.name}</h3>
          <button
            onClick={onClose}
            className="p-1.5 sm:p-2 hover:bg-gray-100 transition-colors"
          >
            <X className="h-5 w-5 sm:h-6 sm:w-6" />
          </button>
        </div>

        <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
          {/* Contact Information */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
            <div className="space-y-3 sm:space-y-4">
              <div className="flex items-center gap-2 text-sm sm:text-base">
                <Mail className="h-4 w-4 sm:h-5 sm:w-5 text-gray-500 flex-shrink-0" />
                <span className="break-all">{client.email}</span>
              </div>
              <div className="flex items-center gap-2 text-sm sm:text-base">
                <Phone className="h-4 w-4 sm:h-5 sm:w-5 text-gray-500 flex-shrink-0" />
                <span>{client.phone}</span>
              </div>
              <div className="flex items-center gap-2 text-sm sm:text-base">
                <MapPin className="h-4 w-4 sm:h-5 sm:w-5 text-gray-500 flex-shrink-0" />
                <span>{client.location}</span>
              </div>
            </div>

            <div className="space-y-3 sm:space-y-4">
              <div className="flex items-center gap-2 text-sm sm:text-base">
                <Calendar className="h-4 w-4 sm:h-5 sm:w-5 text-gray-500 flex-shrink-0" />
                <span>Client since Jan 2023</span>
              </div>
              <div className="flex items-center gap-2 text-sm sm:text-base">
                <DollarSign className="h-4 w-4 sm:h-5 sm:w-5 text-gray-500 flex-shrink-0" />
                <span>Total Revenue: ${client.revenue.toLocaleString()}</span>
              </div>
              <div className="flex items-center gap-2 text-sm sm:text-base">
                <Users className="h-4 w-4 sm:h-5 sm:w-5 text-gray-500 flex-shrink-0" />
                <span>Total Trips: {client.trips}</span>
              </div>
            </div>
          </div>

          {/* Recent Activity */}
          <div>
            <h4 className="text-base sm:text-lg font-bold mb-3 sm:mb-4">Recent Activity</h4>
            <div className="space-y-3 sm:space-y-4">
              {[1, 2, 3].map((i) => (
                <div
                  key={i}
                  className="flex items-center justify-between p-3 sm:p-4 bg-gray-50 border border-black sm:border-2"
                >
                  <div>
                    <div className="font-bold text-sm sm:text-base">Booked Trip to Paris</div>
                    <div className="text-xs sm:text-sm text-gray-500">3 days ago</div>
                  </div>
                  <div className="text-accent-500 font-bold text-sm sm:text-base">$4,500</div>
                </div>
              ))}
            </div>
          </div>

          {/* Action Buttons */}
          <div className="flex flex-col sm:flex-row gap-3 sm:gap-4">
            <button className="w-full sm:flex-1 px-4 sm:px-6 py-2.5 sm:py-3 bg-black text-white font-bold text-sm sm:text-base hover:bg-gray-800 transition-colors">
              Edit Client
            </button>
            <button className="w-full sm:flex-1 px-4 sm:px-6 py-2.5 sm:py-3 bg-accent-500 text-white font-bold text-sm sm:text-base hover:bg-accent-600 transition-colors">
              Book New Trip
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}