import { format } from 'date-fns';

interface ScheduleHeaderProps {
  date: string;
}

export default function ScheduleHeader({ date }: ScheduleHeaderProps) {
  return (
    <div className="p-4 border-b border-white/10">
      <h3 className="text-lg font-medium text-white">
        {format(new Date(date), 'EEEE, MMMM d')}
      </h3>
    </div>
  );
}