import { useState } from 'react';
import Search from './Search';
import AISurvey from './AISurvey';
import BackgroundImage from './BackgroundImage';

export default function Hero() {
  const [showSearch, setShowSearch] = useState(false);

  return (
    <BackgroundImage interval={8000}>
      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
        <div className="text-center space-y-8">
          <div className="animate-fade-in">
            <h1 className="text-5xl font-bold tracking-tight text-white sm:text-6xl md:text-7xl">
              Travel Smarter with
              <span className="bg-gradient-to-r from-primary-200 to-accent-300 text-transparent bg-clip-text">
                {' '}WanderWise
              </span>
            </h1>
            <p className="mt-6 max-w-2xl mx-auto text-xl text-gray-200">
              Let AI guide you to your perfect destination through our personalized quiz or search directly with our AI travel planner.
            </p>
          </div>

          <div className="mt-10 animate-fade-in" style={{ animationDelay: '0.2s' }}>
            {showSearch ? (
              <Search onBackToQuiz={() => setShowSearch(false)} />
            ) : (
              <AISurvey onStartSearch={() => setShowSearch(true)} />
            )}
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
}