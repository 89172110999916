import { useState, useEffect } from 'react';
import { Users, Calendar, BarChart3, Settings, Search, DollarSign, MapPin } from 'lucide-react';
import ClientList from './ClientList';
import Kanban from './Kanban';
import Analytics from './Analytics';
import SettingsPanel from './Settings';
import TripBuilder from './TripBuilder';
import DealTracker from './DealTracker';

export default function EnterpriseCRM() {
  const [activeTab, setActiveTab] = useState('clients');
  const [searchQuery, setSearchQuery] = useState('');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (window.location.hash === '#crm') {
      document.getElementById('enterprise-crm')?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  const tabs = [
    { id: 'clients', label: 'Clients', icon: Users },
    { id: 'deals', label: 'Deals', icon: DollarSign },
    { id: 'trips', label: 'Trip Builder', icon: MapPin },
    { id: 'bookings', label: 'Bookings', icon: Calendar },
    { id: 'analytics', label: 'Analytics', icon: BarChart3 },
    { id: 'settings', label: 'Settings', icon: Settings }
  ];

  return (
    <div id="enterprise-crm" className="py-12 sm:py-24 bg-gray-100 min-h-screen">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-xl shadow-sm">
          <div className="p-4 sm:p-6 border-b border-gray-200">
            <div className="flex flex-col space-y-4 w-full">
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
                <h2 className="text-xl sm:text-2xl font-semibold text-gray-900">WanderWise Pro CRM</h2>
                
                {/* Mobile Menu Button */}
                <button 
                  className="sm:hidden flex items-center p-2 rounded-lg hover:bg-gray-100"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <span className="sr-only">Open menu</span>
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>

              {/* Alert - Full width on mobile */}
              <div className="w-full">
                <div className="bg-accent-50 border border-accent-200 text-accent-700 px-4 sm:px-6 py-3 rounded-lg flex items-center gap-2 shadow-sm">
                  <div className="w-2 h-2 rounded-full bg-accent-500 animate-pulse"></div>
                  <p className="text-xs sm:text-sm font-medium">
                    Welcome to our interactive demo environment. Feel free to explore all features and functionality.
                  </p>
                </div>
              </div>

              {/* Search Bar - Full width on mobile */}
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder="Search clients, bookings, or trips..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="w-full px-4 py-2 pl-10 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-accent-500 focus:border-transparent"
                />
                <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>

          {/* Navigation Tabs - Horizontal scroll on mobile */}
          <div className="overflow-x-auto">
            <div className="flex border-b border-gray-200 min-w-max">
              {tabs.map((tab) => (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`
                    flex items-center gap-2 px-4 sm:px-6 py-3 sm:py-4 font-medium transition-all duration-200 text-sm sm:text-base
                    ${activeTab === tab.id
                      ? 'text-accent-500 border-b-2 border-accent-500'
                      : 'text-gray-500 hover:text-gray-700 hover:bg-gray-50'
                    }
                  `}
                >
                  <tab.icon className="h-4 w-4 sm:h-5 sm:w-5" />
                  {tab.label}
                </button>
              ))}
            </div>
          </div>

          {/* Content Area - Responsive padding */}
          <div className="p-4 sm:p-6">
            {activeTab === 'clients' && <ClientList searchQuery={searchQuery} />}
            {activeTab === 'deals' && <DealTracker />}
            {activeTab === 'trips' && <TripBuilder />}
            {activeTab === 'bookings' && <Kanban />}
            {activeTab === 'analytics' && <Analytics />}
            {activeTab === 'settings' && <SettingsPanel />}
          </div>
        </div>
      </div>
    </div>
  );
}