import { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Clock, MapPin, DollarSign, Users } from 'lucide-react';

const mockBookings = {
  pending: [
    { id: 'p1', client: 'Acme Corp', destination: 'Paris', date: '2024-03-15', value: '$4,500' },
    { id: 'p2', client: 'Tech Inc', destination: 'Tokyo', date: '2024-03-20', value: '$6,800' }
  ],
  confirmed: [
    { id: 'c1', client: 'Global Ltd', destination: 'New York', date: '2024-03-10', value: '$3,200' },
    { id: 'c2', client: 'Startup Co', destination: 'London', date: '2024-03-25', value: '$5,100' }
  ],
  completed: [
    { id: 'f1', client: 'Big Corp', destination: 'Sydney', date: '2024-02-28', value: '$7,300' },
    { id: 'f2', client: 'Small Biz', destination: 'Rome', date: '2024-02-15', value: '$4,900' }
  ]
};

export default function Kanban() {
  const [columns, setColumns] = useState(mockBookings);

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    // Dropped outside a valid droppable
    if (!destination) return;

    // Clone current state
    const newColumns = { ...columns };

    // Get source and destination columns
    const sourceCol = [...newColumns[source.droppableId as keyof typeof columns]];
    const destCol = [...newColumns[destination.droppableId as keyof typeof columns]];

    // Remove from source
    const [removed] = sourceCol.splice(source.index, 1);

    // Add to destination
    destCol.splice(destination.index, 0, removed);

    // Update state with new column data
    setColumns({
      ...newColumns,
      [source.droppableId]: sourceCol,
      [destination.droppableId]: destCol
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="flex flex-col md:grid md:grid-cols-3 gap-4 md:gap-6 overflow-x-auto">
        {Object.entries(columns).map(([columnId, items]) => (
          <div
            key={columnId}
            className="bg-gray-50 border-4 border-black p-3 md:p-4 min-w-[280px] md:min-w-0"
          >
            <h3 className="text-lg md:text-xl font-bold mb-3 md:mb-4 capitalize">{columnId}</h3>
            
            <Droppable droppableId={columnId}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`space-y-3 md:space-y-4 min-h-[200px] transition-colors ${
                    snapshot.isDraggingOver ? 'bg-gray-100' : ''
                  }`}
                >
                  {items.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`
                            bg-white border-4 border-black p-3 md:p-4
                            ${snapshot.isDragging ? 'shadow-lg' : 'shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]'}
                            transition-all duration-200 cursor-move
                            text-sm md:text-base
                          `}
                          style={{
                            ...provided.draggableProps.style,
                            transform: snapshot.isDragging
                              ? provided.draggableProps.style?.transform
                              : 'none'
                          }}
                        >
                          <div className="font-bold truncate">{item.client}</div>
                          <div className="text-gray-600 truncate">{item.destination}</div>
                          <div className="flex flex-wrap justify-between mt-2 gap-2 text-xs md:text-sm">
                            <span className="text-gray-500">{item.date}</span>
                            <span className="font-bold text-accent-500">{item.value}</span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        ))}
      </div>
    </DragDropContext>
  );
}