import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import { PaywallProvider } from './contexts/PaywallContext';
import { AgencyProvider } from './contexts/AgencyContext';
import { TripSlipProvider } from './contexts/TripSlipContext';
import { HelmetProvider } from 'react-helmet-async';
import Navigation from './components/Navigation';
import Footer from './components/Footer';
import FloatingTripSlip from './components/TripSlip/FloatingTripSlip';
import PaywallModal from './components/ui/PaywallModal';
import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Activities from './pages/Activities';
import Destinations from './pages/Destinations';
import Dining from './pages/Dining';
import Itineraries from './pages/Itineraries';
import Share from './pages/Share';
import Pricing from './pages/Pricing';
import AgencyDashboard from './pages/agency/Dashboard';
import AgencyTeam from './pages/agency/Team';
import AgencyClients from './pages/agency/Clients';
import AgencyTrips from './pages/agency/Trips';
import AgencySettings from './pages/agency/Settings';
import ProtectedRoute from './components/auth/ProtectedRoute';

function App() {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <PaywallProvider>
          <AgencyProvider>
            <TripSlipProvider>
              <Router>
                <Navigation />
                <FloatingTripSlip />
                <PaywallModal />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/blog/:slug" element={<BlogPost />} />
                  <Route path="/activities" element={<Activities />} />
                  <Route path="/destinations" element={<Destinations />} />
                  <Route path="/dining" element={<Dining />} />
                  <Route path="/itineraries" element={<Itineraries />} />
                  <Route path="/share/:shareId" element={<Share />} />
                  <Route path="/pricing" element={<Pricing />} />
                  <Route
                    path="/agency"
                    element={
                      <ProtectedRoute>
                        <AgencyDashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/agency/team"
                    element={
                      <ProtectedRoute>
                        <AgencyTeam />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/agency/clients"
                    element={
                      <ProtectedRoute>
                        <AgencyClients />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/agency/trips"
                    element={
                      <ProtectedRoute>
                        <AgencyTrips />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/agency/settings"
                    element={
                      <ProtectedRoute>
                        <AgencySettings />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
                <Footer />
              </Router>
            </TripSlipProvider>
          </AgencyProvider>
        </PaywallProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;