import { Draggable } from 'react-beautiful-dnd';
import { Activity, Utensils, Hotel, MapPin, Clock, Trash2 } from 'lucide-react';
import { format, parseISO } from 'date-fns';
import type { ScheduleItemProps } from './types';

export default function ScheduleItem({ item, index, onDelete }: ScheduleItemProps) {
  const getIcon = () => {
    switch (item.type) {
      case 'activity':
        return Activity;
      case 'dining':
        return Utensils;
      case 'accommodation':
        return Hotel;
      case 'travel':
        return MapPin;
      default:
        return Clock;
    }
  };

  const getColorClass = () => {
    switch (item.type) {
      case 'activity':
        return 'text-emerald-400 bg-emerald-500/10';
      case 'dining':
        return 'text-orange-400 bg-orange-500/10';
      case 'accommodation':
        return 'text-blue-400 bg-blue-500/10';
      case 'travel':
        return 'text-purple-400 bg-purple-500/10';
      default:
        return 'text-gray-400 bg-gray-500/10';
    }
  };

  const Icon = getIcon();
  const colorClass = getColorClass();

  return (
    <Draggable draggableId={item._id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`
            group relative flex items-start gap-3 p-4 rounded-lg 
            transition-all duration-200 border
            ${snapshot.isDragging
              ? 'shadow-lg scale-105 bg-white/10 border-accent-500/30'
              : 'bg-white/5 border-white/10 hover:border-accent-500/30'
            }
          `}
          style={{
            ...provided.draggableProps.style,
            opacity: snapshot.isDragging ? 0.8 : 1
          }}
        >
          <div className={`p-2 rounded-lg ${colorClass} touch-none`}>
            <Icon className="h-4 w-4" />
          </div>

          <div className="flex-1 min-w-0">
            <h4 className="font-medium text-white mb-1">{item.title}</h4>
            
            <div className="flex items-center gap-4 text-sm text-gray-400">
              <div className="flex items-center gap-1.5">
                <Clock className="h-4 w-4" />
                <span>
                  {format(parseISO(`2000-01-01T${item.startTime}`), 'h:mm a')} - 
                  {format(parseISO(`2000-01-01T${item.endTime}`), 'h:mm a')}
                </span>
              </div>
              {item.location && (
                <div className="flex items-center gap-1.5">
                  <MapPin className="h-4 w-4" />
                  <span>{item.location}</span>
                </div>
              )}
            </div>

            {item.description && (
              <p className="mt-2 text-sm text-gray-300">{item.description}</p>
            )}

            {item.notes && (
              <p className="mt-2 text-sm text-gray-400 italic">{item.notes}</p>
            )}
          </div>

          <button
            onClick={() => onDelete(item._id)}
            className="absolute top-2 right-2 p-1.5 text-gray-400 hover:text-red-400 hover:bg-red-500/10 rounded transition-colors opacity-0 group-hover:opacity-100"
          >
            <Trash2 className="h-4 w-4" />
          </button>
        </div>
      )}
    </Draggable>
  );
}