import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Minus, MapPin, Clock, DollarSign, Users, Calendar, Plane, Compass, Shield } from 'lucide-react';

interface FAQItem {
  id: string;
  question: string;
  answer: string;
  icon: typeof MapPin;
}

const faqs: FAQItem[] = [
  {
    id: 'destinations',
    question: 'How do I find the perfect destination for my budget?',
    answer: 'Our AI analyzes your budget, travel preferences, and current market conditions to suggest destinations that offer the best value. We consider factors like seasonal pricing, exchange rates, and local costs to ensure your budget goes further.',
    icon: MapPin
  },
  {
    id: 'timing',
    question: 'When is the best time to book my trip?',
    answer: "WanderWise tracks historical pricing data and predicts future trends to help you book at the optimal time. We'll alert you when prices drop and suggest the best booking windows for your specific destinations.",
    icon: Clock
  },
  {
    id: 'budget',
    question: 'How much should I budget for my trip?',
    answer: 'Our AI provides personalized budget recommendations based on your destination, travel style, and preferences. We break down costs for flights, accommodations, activities, and daily expenses, helping you plan and track your spending effectively.',
    icon: DollarSign
  },
  {
    id: 'group',
    question: 'How can I coordinate a trip with multiple people?',
    answer: "WanderWise's group planning features let everyone collaborate on the itinerary, vote on activities, and split expenses. Our AI balances everyone's preferences and budgets while keeping the group synchronized through shared planning tools.",
    icon: Users
  },
  {
    id: 'itinerary',
    question: 'How detailed should my travel itinerary be?',
    answer: 'Our AI helps you create the perfect balance between structure and flexibility. We suggest daily activities while leaving room for spontaneity, and automatically adjust your schedule based on factors like opening hours, travel time, and weather.',
    icon: Calendar
  },
  {
    id: 'transportation',
    question: "What's the best way to get around my destination?",
    answer: "We analyze local transportation options, costs, and efficiency to recommend the best ways to get around. Whether it's public transit, rental cars, or ride-sharing, we'll help you make informed decisions based on your needs and location.",
    icon: Plane
  },
  {
    id: 'activities',
    question: 'How do I find unique local experiences?',
    answer: 'Our AI discovers hidden gems and local favorites by analyzing reviews, local events, and seasonal activities. We match these with your interests to suggest authentic experiences beyond typical tourist attractions.',
    icon: Compass
  },
  {
    id: 'safety',
    question: 'How can I stay safe while traveling?',
    answer: 'We provide real-time safety updates, local emergency information, and travel advisories. Our AI monitors your location and itinerary to alert you about potential risks and suggest safer alternatives when needed.',
    icon: Shield
  }
];

export default function FAQ() {
  const [expandedId, setExpandedId] = useState<string | null>(null);

  return (
    <div className="py-24 bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-16">
          <motion.h2 
            className="text-4xl font-bold text-white mb-4"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            Frequently Asked Questions
          </motion.h2>
          <motion.p 
            className="text-xl text-gray-300 max-w-3xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            Everything you need to know about WanderWise and AI-powered travel planning
          </motion.p>
        </div>

        {/* FAQ Grid */}
        <div className="grid gap-6 md:grid-cols-2">
          {faqs.map((faq, index) => (
            <motion.div
              key={faq.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="group"
            >
              <div 
                onClick={() => setExpandedId(expandedId === faq.id ? null : faq.id)}
                className={`
                  relative overflow-hidden bg-white border-4 border-black cursor-pointer
                  transition-all duration-300
                  ${expandedId === faq.id 
                    ? 'shadow-none translate-x-2 translate-y-2' 
                    : 'shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:translate-x-1 hover:translate-y-1 hover:shadow-[2px_2px_0px_0px_rgba(0,0,0,1)]'
                  }
                `}
              >
                {/* Question */}
                <div className="p-6 flex items-start gap-4">
                  <div className="p-2 bg-black">
                    <faq.icon className="h-6 w-6 text-white" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <h3 className="text-lg font-bold text-gray-900 pr-8">
                      {faq.question}
                    </h3>
                    <AnimatePresence>
                      {expandedId === faq.id && (
                        <motion.p
                          initial={{ height: 0, opacity: 0 }}
                          animate={{ height: 'auto', opacity: 1 }}
                          exit={{ height: 0, opacity: 0 }}
                          transition={{ duration: 0.3 }}
                          className="mt-2 text-gray-600"
                        >
                          {faq.answer}
                        </motion.p>
                      )}
                    </AnimatePresence>
                  </div>
                  <div className="absolute top-6 right-6">
                    {expandedId === faq.id ? (
                      <Minus className="h-5 w-5 text-gray-900" />
                    ) : (
                      <Plus className="h-5 w-5 text-gray-900" />
                    )}
                  </div>
                </div>

                {/* Decorative elements */}
                <div 
                  className={`
                    absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-accent-500 to-primary-600
                    transition-opacity duration-300
                    ${expandedId === faq.id ? 'opacity-100' : 'opacity-0'}
                  `}
                />
                <div 
                  className={`
                    absolute bottom-0 right-0 w-12 h-12 bg-gradient-to-br from-accent-500 to-primary-600
                    transform rotate-45 translate-x-6 translate-y-6
                    transition-opacity duration-300
                    ${expandedId === faq.id ? 'opacity-100' : 'opacity-0'}
                  `}
                />
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}