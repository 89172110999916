import { useState, useEffect } from 'react';
import { Plane, X } from 'lucide-react';
import { searchFlights } from '../../services/flights';
import type { FlightSearchResponse } from '../../types/flights';
import { generateDepartureAirportSuggestions } from '../../services/openai';
import type { Airport } from '../../services/openai';
import Calendar from '../ui/Calendar';
import FlightSearchResults from './FlightSearchResults';

interface FlightSearchProps {
  destination: any;
  onClose: () => void;
  suggestedDates: {
    start: string;
    end: string;
    reason: string;
  };
}

export default function FlightSearch({
  destination,
  onClose,
  suggestedDates
}: FlightSearchProps) {
  const [selectedAirport, setSelectedAirport] = useState<Airport | null>(null);
  const [airports, setAirports] = useState<Airport[]>([]);
  const [loadingAirports, setLoadingAirports] = useState(true);
  const [locationError, setLocationError] = useState<string | null>(null);
  const [dateOption, setDateOption] = useState<'custom' | 'suggested'>('suggested');
  const [customDates, setCustomDates] = useState({
    departure: '',
    return: ''
  });
  const [flights, setFlights] = useState<FlightSearchResponse | null>(null);
  const [searchingFlights, setSearchingFlights] = useState(false);
  const [flightError, setFlightError] = useState<string | null>(null);

  useEffect(() => {
    loadAirports();
  }, []);

  const loadAirports = async () => {
    setLoadingAirports(true);
    setLocationError(null);
    try {
      const suggestions = await generateDepartureAirportSuggestions();
      const validAirports = suggestions.filter(
        airport => airport.code && airport.name && airport.city && airport.description
      );
      setAirports(validAirports);
    } catch (error) {
      console.error('Error fetching airport suggestions:', error);
      setLocationError('Could not determine your location. Please allow location access to see nearby airports.');
    } finally {
      setLoadingAirports(false);
    }
  };

  const handleDateChange = (type: 'departure' | 'return', value: string) => {
    setCustomDates(prev => ({
      ...prev,
      [type]: value
    }));
  };

  const handleSearchFlights = async () => {
    if (!selectedAirport) return;

    setSearchingFlights(true);
    setFlightError(null);

    const dates = dateOption === 'suggested'
      ? { departure: suggestedDates.start, return: suggestedDates.end }
      : customDates;

    try {
      const results = await searchFlights({
        origin: selectedAirport.code,
        destination: destination.code || 'ZQN',
        departureDate: dates.departure,
        returnDate: dates.return,
      });
      setFlights(results);
    } catch (error) {
      console.error('Error searching flights:', error);
      setFlightError('Unable to find flights. Please try different dates or airports.');
    } finally {
      setSearchingFlights(false);
    }
  };

  return (
    <div className="p-4 rounded-xl bg-gray-800/50 border border-accent-500/20 space-y-4">
      <div className="flex justify-between items-center">
        <h4 className="text-lg font-semibold text-white">Select Departure Airport</h4>
        <button
          onClick={onClose}
          className="p-2 text-gray-400 hover:text-white transition-colors rounded-lg hover:bg-white/10"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      <div className="grid gap-3">
        {airports.map((airport) => (
          <button
            key={airport.code}
            onClick={() => setSelectedAirport(airport)}
            className={`
              p-4 rounded-lg border transition-all duration-200 text-left
              ${selectedAirport?.code === airport.code
                ? 'border-accent-500 bg-accent-500/10'
                : 'border-white/10 hover:border-accent-500/50 hover:bg-white/5'
              }
            `}
          >
            <div className="flex items-start justify-between">
              <div>
                <h5 className="font-semibold text-white">{airport.name}</h5>
                <p className="text-sm text-gray-400">{airport.city}</p>
              </div>
              <span className="text-accent-400 font-mono">{airport.code}</span>
            </div>
            <p className="mt-2 text-sm text-gray-300">{airport.description}</p>
          </button>
        ))}
      </div>

      <div className="space-y-4">
        <div className="flex gap-4">
          <button
            onClick={() => setDateOption('suggested')}
            className={`
              flex-1 p-3 rounded-lg border transition-all duration-200
              ${dateOption === 'suggested'
                ? 'border-accent-500 bg-accent-500/10'
                : 'border-white/10 hover:border-accent-500/50'
              }
            `}
          >
            <h5 className="font-semibold text-white mb-1">Suggested Dates</h5>
            <p className="text-sm text-gray-400">{suggestedDates.reason}</p>
          </button>
          <button
            onClick={() => setDateOption('custom')}
            className={`
              flex-1 p-3 rounded-lg border transition-all duration-200
              ${dateOption === 'custom'
                ? 'border-accent-500 bg-accent-500/10'
                : 'border-white/10 hover:border-accent-500/50'
              }
            `}
          >
            <h5 className="font-semibold text-white mb-1">Custom Dates</h5>
            <p className="text-sm text-gray-400">Choose your own travel dates</p>
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <label className="block text-sm text-gray-400">Departure</label>
            {dateOption === 'suggested' ? (
              <input
                type="text"
                value={new Date(suggestedDates.start).toLocaleDateString()}
                readOnly
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white"
              />
            ) : (
              <Calendar
                value={customDates.departure}
                onChange={(date) => handleDateChange('departure', date)}
                min={new Date().toISOString().split('T')[0]}
              />
            )}
          </div>
          <div className="space-y-2">
            <label className="block text-sm text-gray-400">Return</label>
            {dateOption === 'suggested' ? (
              <input
                type="text"
                value={new Date(suggestedDates.end).toLocaleDateString()}
                readOnly
                className="w-full bg-white/5 border border-white/10 rounded-lg px-4 py-2.5 text-white"
              />
            ) : (
              <Calendar
                value={customDates.return}
                onChange={(date) => handleDateChange('return', date)}
                min={customDates.departure || new Date().toISOString().split('T')[0]}
              />
            )}
          </div>
        </div>

        <button
          onClick={handleSearchFlights}
          disabled={!selectedAirport || (dateOption === 'custom' && (!customDates.departure || !customDates.return))}
          className={`
            w-full py-3 px-4 rounded-lg font-medium transition-all duration-200
            ${selectedAirport && (dateOption === 'suggested' || (customDates.departure && customDates.return))
              ? 'bg-accent-500 hover:bg-accent-600 text-white'
              : 'bg-gray-700 text-gray-400 cursor-not-allowed'
            }
          `}
        >
          <span className="flex items-center justify-center gap-2">
            <Plane className="h-4 w-4" />
            Search Flights
          </span>
        </button>
      </div>

      {(searchingFlights || flights || flightError) && (
        <FlightSearchResults
          flights={flights}
          isLoading={searchingFlights}
          error={flightError}
        />
      )}
    </div>
  );
}