import { motion } from 'framer-motion';
import { useAgency } from '../../contexts/AgencyContext';
import { Card, Title } from '@tremor/react';
import { Plus, Check } from 'lucide-react';
import { cn } from '@/lib/utils';

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

export default function Team() {
  const { agency } = useAgency();

  if (!agency) return null;

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="space-y-6"
    >
      <Card>
        <div className="space-y-6">
          <div className="flex items-center justify-between mb-6">
            <Title>Team Members</Title>
            <button className="flex items-center gap-2 px-4 py-2 bg-accent-500 text-white rounded-lg hover:bg-accent-600 transition-colors">
              <Plus className="h-4 w-4" />
              Add Member
            </button>
          </div>
          
          <div className="space-y-4">
            {[
              {
                name: 'Sarah Parker',
                email: 'sarah@wanderwise.com',
                role: 'Admin',
                status: 'active'
              },
              {
                name: 'Michael Chen',
                email: 'michael@wanderwise.com',
                role: 'Agent',
                status: 'active'
              },
              {
                name: 'Emma Davis',
                email: 'emma@wanderwise.com',
                role: 'Agent',
                status: 'pending'
              }
            ].map((member, index) => (
              <div
                key={index}
                className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
              >
                <div className="flex items-center gap-4">
                  <div className="w-10 h-10 rounded-full bg-accent-500 flex items-center justify-center text-white font-medium">
                    {member.name.charAt(0)}
                  </div>
                  <div>
                    <div className="font-medium text-gray-900">{member.name}</div>
                    <div className="text-sm text-gray-500">{member.email}</div>
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <select
                    defaultValue={member.role}
                    className="px-3 py-1.5 rounded border border-gray-200 text-sm"
                  >
                    <option value="Admin">Admin</option>
                    <option value="Agent">Agent</option>
                    <option value="Viewer">Viewer</option>
                  </select>
                  <div className={cn(
                    'px-2 py-1 text-xs rounded-full',
                    member.status === 'active'
                      ? 'bg-emerald-100 text-emerald-700'
                      : 'bg-yellow-100 text-yellow-700'
                  )}>
                    {member.status.charAt(0).toUpperCase() + member.status.slice(1)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>

      <Card>
        <Title>Team Permissions</Title>
        <div className="space-y-4 mt-4">
          {[
            { role: 'Admin', permissions: ['Full system access', 'Manage team', 'View analytics'] },
            { role: 'Agent', permissions: ['Create bookings', 'View clients', 'Limited analytics'] },
            { role: 'Viewer', permissions: ['View bookings', 'View reports'] }
          ].map((role, index) => (
            <div key={index} className="p-4 border border-gray-200 rounded-lg">
              <div className="font-medium text-gray-900 mb-2">{role.role}</div>
              <div className="space-y-2">
                {role.permissions.map((permission, pIndex) => (
                  <div key={pIndex} className="flex items-center gap-2 text-sm text-gray-600">
                    <Check className="h-4 w-4 text-accent-500" />
                    {permission}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </Card>
    </motion.div>
  );
}