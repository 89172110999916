import { Loader2, MapPin, Plus, BookmarkPlus, Compass, Utensils } from 'lucide-react';
import DestinationCard from './TravelRecommendations/DestinationCard';
import ActivityCard from './TripSlip/ActivitySearchModal/ActivityCard';
import DiningResults from './DiningResults';
import { useAuth } from '../contexts/AuthContext';
import { useState } from 'react';
import axios from 'axios';
import Alert from './ui/Alert';

interface SearchResultsProps {
  type: 'destination' | 'activity' | 'dining';
  results: any;
  isLoading: boolean;
  error: string | null;
  onAddDestination: (destination: any) => void;
}

export default function SearchResults({
  type,
  results,
  isLoading,
  error,
  onAddDestination
}: SearchResultsProps) {
  const { isAuthenticated, user } = useAuth();
  const [isSaving, setIsSaving] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);

  const handleSaveDestination = async (destination: any) => {
    if (!isAuthenticated || !user?.googleId) return;

    setIsSaving(destination.name);
    try {
      await axios.post('/api/users/destinations', {
        destination: {
          name: destination.name,
          description: destination.description,
          activities: destination.activities,
          estimatedBudget: destination.estimatedBudget,
          bestTimeToVisit: destination.bestTimeToVisit,
          accommodation: destination.accommodation,
          recommendedDuration: destination.recommendedDuration,
          localTips: destination.localTips
        }
      }, {
        headers: { Authorization: `Bearer ${user.googleId}` }
      });

      setShowAlert(true);
    } catch (error) {
      console.error('Error saving destination:', error);
    } finally {
      setIsSaving(null);
    }
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center py-8">
        <Loader2 className="h-8 w-8 text-accent-400 animate-spin mb-4" />
        <p className="text-gray-300">
          {type === 'destination'
            ? 'Generating recommendations...'
            : type === 'dining'
            ? 'Searching for dining options...'
            : 'Searching for activities...'}
        </p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-4">
        <p className="text-red-400">{error}</p>
      </div>
    );
  }

  if (!results?.data) return null;

  if (type === 'destination') {
    return (
      <div className="max-w-5xl mx-auto">
        {showAlert && (
          <Alert
            type="success"
            message="Destination saved successfully!"
            onClose={() => setShowAlert(false)}
          />
        )}

        {/* Travel Style Header */}
        <div className="bg-gray-900/60 backdrop-blur-sm rounded-xl p-4 mb-6 border border-white/10">
          <h2 className="text-2xl font-bold text-white mb-2">Your Perfect Destinations</h2>
          <p className="text-xl text-gray-300">{results.data.travelStyle}</p>
        </div>

        {/* Destinations */}
        <div className="space-y-6">
          {results.data.destinations.map((destination: any, index: number) => (
            <div key={destination.name} className="bg-gray-900/50 backdrop-blur-sm rounded-xl border border-white/10">
              {/* Destination Header */}
              <div className="p-6 border-b border-white/10">
                <div className="flex items-center gap-2 text-accent-400 mb-1">
                  <MapPin className="h-4 w-4" />
                  <span className="text-sm font-medium">Destination {index + 1}</span>
                </div>
                <div className="flex items-center justify-between">
                  <h2 className="text-3xl font-bold text-white">{destination.name}</h2>
                  <div className="flex items-center gap-3">
                    <button
                      onClick={() => onAddDestination(destination)}
                      className="flex items-center gap-2 px-4 py-2 bg-accent-500/20 hover:bg-accent-500/30 text-accent-300 rounded-lg transition-colors"
                    >
                      <Plus className="h-5 w-5" />
                      <span className="font-medium">Plan Trip</span>
                    </button>
                    <button
                      onClick={() => handleSaveDestination(destination)}
                      disabled={!isAuthenticated || isSaving === destination.name}
                      className={`
                        flex items-center gap-2 px-4 py-2 rounded-lg transition-colors
                        ${!isAuthenticated
                          ? 'bg-gray-700/50 text-gray-500 cursor-not-allowed'
                          : 'bg-accent-500/20 hover:bg-accent-500/30 text-accent-300'
                        }
                      `}
                    >
                      <BookmarkPlus className={`h-5 w-5 ${isSaving === destination.name ? 'animate-pulse' : ''}`} />
                      <span className="font-medium">Save</span>
                    </button>
                  </div>
                </div>
              </div>

              {/* Destination Card */}
              <div className="p-6">
                <DestinationCard 
                  destination={destination}
                  onSave={() => handleSaveDestination(destination)}
                  isSaving={isSaving === destination.name}
                  isAuthenticated={isAuthenticated}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (type === 'activity' && Array.isArray(results.data)) {
    return (
      <div className="max-w-5xl mx-auto">
        {/* Activities Header */}
        <div className="bg-gray-900/60 backdrop-blur-sm rounded-xl p-4 mb-4 border border-white/10">
          <div className="flex items-center gap-2 text-accent-400 mb-1">
            <Compass className="h-4 w-4" />
            <span className="text-sm font-medium">Activities</span>
          </div>
          <div className="flex items-center justify-between">
            <h2 className="text-3xl font-bold text-white">Nearby Activities</h2>
          </div>
        </div>

        {/* Activities List */}
        <div className="bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 border border-white/10">
          <div className="space-y-6">
            {results.data.map((activity: any, index: number) => (
              <ActivityCard
                key={index}
                activity={activity}
                destinationName="nearby"
              />
            ))}
          </div>
        </div>
      </div>
    );
  }

  if (type === 'dining' && Array.isArray(results.data)) {
    return (
      <div className="max-w-5xl mx-auto">
        {/* Dining Header */}
        <div className="bg-gray-900/60 backdrop-blur-sm rounded-xl p-4 mb-4 border border-white/10">
          <div className="flex items-center gap-2 text-accent-400 mb-1">
            <Utensils className="h-4 w-4" />
            <span className="text-sm font-medium">Dining</span>
          </div>
          <div className="flex items-center justify-between">
            <h2 className="text-3xl font-bold text-white">Restaurant Recommendations</h2>
          </div>
        </div>

        {/* Dining Results */}
        <div className="bg-gray-900/50 backdrop-blur-sm rounded-xl p-6 border border-white/10">
          <DiningResults
            results={results.data}
            isLoading={isLoading}
            error={error}
          />
        </div>
      </div>
    );
  }

  return null;
}