import { useState } from 'react';
import { Search as SearchIcon, MapPin, Compass, ArrowLeft, Info, X, Utensils } from 'lucide-react';
import Button from './ui/Button';
import { generateTravelRecommendations } from '../services/openai';
import { searchActivityDetails } from '../services/activities';
import { searchDining } from '../services/dining';
import SearchResults from './SearchResults';
import { useTripSlip } from '../contexts/TripSlipContext';
import Alert from './ui/Alert';
import StarRating from './ui/StarRating';

interface SearchProps {
  onBackToQuiz?: () => void;
}

export default function Search({ onBackToQuiz }: SearchProps) {
  const [searchType, setSearchType] = useState<'destination' | 'activity' | 'dining'>('destination');
  const [searchQuery, setSearchQuery] = useState('');
  const [locationQuery, setLocationQuery] = useState('');
  const [destinations, setDestinations] = useState<string[]>([]);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchResults, setSearchResults] = useState<{
    type: 'destination' | 'activity' | 'dining';
    data: any;
  } | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const { addDestination } = useTripSlip();
  const [starRating, setStarRating] = useState(0);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (searchType === 'destination') {
      setSearchQuery(value);
      if (value.endsWith(',')) {
        const newDestination = value.slice(0, -1).trim();
        if (newDestination && !destinations.includes(newDestination)) {
          setDestinations([...destinations, newDestination]);
          setSearchQuery('');
        }
      }
    } else {
      setSearchQuery(value);
    }
  };

  const removeDestination = (index: number) => {
    setDestinations(prev => prev.filter((_, i) => i !== index));
  };

  const handleAddDestination = (destination: string) => {
    addDestination(destination);
    setShowAlert(true);
  };

  const handleSearch = async () => {
    if (searchType === 'destination') {
      // Add current input as destination if not empty
      if (searchQuery.trim()) {
        setDestinations(prev => [...prev, searchQuery.trim()]);
        setSearchQuery('');
      }

      // If no destinations and no current query, return
      if (!destinations.length && !searchQuery.trim()) return;
    }

    setIsSearching(true);
    setError(null);
    setSearchResults(null);

    try {
      if (searchType === 'destination') {
        // Use all destinations including current query if exists
        const allDestinations = [...destinations];
        if (searchQuery.trim()) {
          allDestinations.push(searchQuery.trim());
        }

        const recommendations = await generateTravelRecommendations({
          destinations: allDestinations,
          stayDomestic: false
        });

        setSearchResults({
          type: 'destination',
          data: recommendations
        });
      } else if (searchType === 'activity') {
        const activities = await searchActivityDetails(searchQuery, 'nearby');
        setSearchResults({
          type: 'activity',
          data: activities
        });
      } else if (searchType === 'dining') {
        if (!locationQuery.trim()) {
          setError('Please enter a location for dining search');
          return;
        }
        const restaurants = await searchDining(searchQuery, locationQuery, starRating || undefined);
        setSearchResults({
          type: 'dining',
          data: restaurants
        });
      }
    } catch (error) {
      console.error('Search error:', error);
      setError(
        searchType === 'destination'
          ? 'Unable to generate recommendations. Please try again.'
          : searchType === 'dining'
          ? 'Unable to find dining options. Please try again.'
          : 'Unable to find activities. Please try again.'
      );
    } finally {
      setIsSearching(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4">
      <div className="max-w-xl mx-auto glass-effect rounded-2xl shadow-lg p-6 border border-white/10 mb-8">
        {showAlert && (
          <Alert
            type="success"
            message="Destination added to Trip Slip successfully!"
            onClose={() => setShowAlert(false)}
          />
        )}

        {onBackToQuiz && (
          <button
            onClick={onBackToQuiz}
            className="flex items-center text-gray-300 hover:text-white mb-4 transition-colors duration-200 group"
          >
            <ArrowLeft className="h-4 w-4 mr-2 transform group-hover:-translate-x-1 transition-transform duration-200" />
            Back to AI Quiz
          </button>
        )}
        
        <div className="flex gap-4 mb-4">
          <button
            onClick={() => {
              setSearchType('destination');
              setSearchResults(null);
              setDestinations([]);
              setSearchQuery('');
              setLocationQuery('');
              setStarRating(0);
            }}
            className={`flex items-center px-4 py-2 rounded-lg transition-all ${
              searchType === 'destination'
                ? 'bg-white/20 text-white'
                : 'text-gray-300 hover:bg-white/10'
            }`}
          >
            <MapPin className="h-5 w-5 mr-2" />
            Destination
          </button>
          <button
            onClick={() => {
              setSearchType('activity');
              setSearchResults(null);
              setDestinations([]);
              setSearchQuery('');
              setLocationQuery('');
              setStarRating(0);
            }}
            className={`flex items-center px-4 py-2 rounded-lg transition-all ${
              searchType === 'activity'
                ? 'bg-white/20 text-white'
                : 'text-gray-300 hover:bg-white/10'
            }`}
          >
            <Compass className="h-5 w-5 mr-2 transition-all duration-300 animate-spin-slow" />
            Activity
          </button>
          <button
            onClick={() => {
              setSearchType('dining');
              setSearchResults(null);
              setDestinations([]);
              setSearchQuery('');
              setLocationQuery('');
              setStarRating(0);
            }}
            className={`flex items-center px-4 py-2 rounded-lg transition-all ${
              searchType === 'dining'
                ? 'bg-white/20 text-white'
                : 'text-gray-300 hover:bg-white/10'
            }`}
          >
            <Utensils className="h-5 w-5 mr-2" />
            Dining
          </button>
        </div>

        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1">
            <div className="relative">
              <div className="flex flex-wrap items-center gap-2 pl-12 pr-4 py-3 bg-white/10 border border-white/20 rounded-xl text-white focus-within:ring-2 focus-within:ring-accent-400 transition-all">
                {searchType === 'destination' && destinations.map((dest, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2 px-3 py-1 bg-accent-500/20 text-accent-300 rounded-lg text-sm"
                  >
                    <span>{dest}</span>
                    <button
                      onClick={() => removeDestination(index)}
                      className="p-0.5 hover:text-white transition-colors"
                    >
                      <X className="h-3 w-3" />
                    </button>
                  </div>
                ))}
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  placeholder={
                    searchType === 'destination'
                      ? destinations.length ? "Add another destination..." : "Where would you like to go?"
                      : searchType === 'dining'
                      ? "What would you like to eat?"
                      : "What would you like to do?"
                  }
                  className="flex-1 min-w-[200px] bg-transparent placeholder-gray-300 focus:outline-none"
                />
              </div>
              {searchType === 'destination' ? (
                <MapPin className="absolute left-3 top-3 h-6 w-6 text-gray-400" />
              ) : searchType === 'dining' ? (
                <Utensils className="absolute left-3 top-3 h-6 w-6 text-gray-400" />
              ) : (
                <Compass className="absolute left-3 top-3 h-6 w-6 text-gray-400 transition-all duration-300 animate-spin-slow" />
              )}
            </div>

            {searchType === 'dining' && (
              <div className="mt-4 space-y-4">
                <div className="relative">
                  <div className="flex items-center pl-12 pr-4 py-3 bg-white/10 border border-white/20 rounded-xl text-white focus-within:ring-2 focus-within:ring-accent-400 transition-all">
                    <input
                      type="text"
                      value={locationQuery}
                      onChange={(e) => setLocationQuery(e.target.value)}
                      onKeyPress={handleKeyPress}
                      placeholder="Enter location (e.g., New York, NY)"
                      className="flex-1 bg-transparent placeholder-gray-300 focus:outline-none"
                    />
                  </div>
                  <MapPin className="absolute left-3 top-3 h-6 w-6 text-gray-400" />
                </div>

                <div className="flex items-center gap-3 px-4 py-3 bg-white/10 border border-white/20 rounded-xl">
                  <span className="text-gray-300">Optional Rating Filter:</span>
                  <StarRating
                    value={starRating}
                    onChange={setStarRating}
                    size="md"
                  />
                  {starRating > 0 && (
                    <button
                      onClick={() => setStarRating(0)}
                      className="ml-2 text-xs text-accent-400 hover:text-accent-300"
                    >
                      Clear
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
          <Button 
            size="lg"
            onClick={handleSearch}
            disabled={isSearching || (!searchQuery.trim() && destinations.length === 0) || (searchType === 'dining' && !locationQuery.trim())}
            className="bg-gradient-to-r from-primary-700 to-accent-600 text-white transform hover:scale-105 transition-all duration-200"
          >
            <SearchIcon className="h-5 w-5 mr-2" />
            Search
          </Button>
        </div>
      </div>

      {/* Search Results */}
      {(isSearching || searchResults || error) && (
        <div className="mt-6">
          <SearchResults
            type={searchType}
            results={searchResults}
            isLoading={isSearching}
            error={error}
            onAddDestination={handleAddDestination}
          />
        </div>
      )}
    </div>
  );
}