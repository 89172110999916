import { AlertTriangle } from 'lucide-react';
import { Link } from 'react-router-dom';

interface ArticleErrorProps {
  error: string | null;
}

export default function ArticleError({ error }: ArticleErrorProps) {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24">
      <div className="max-w-4xl mx-auto px-4 text-center">
        <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-red-500/10 mb-6">
          <AlertTriangle className="h-8 w-8 text-red-500" />
        </div>
        <h1 className="text-3xl font-bold text-white mb-4">
          Article Not Found
        </h1>
        <p className="text-xl text-gray-300 mb-8">
          {error || "The article you're looking for might have been moved or deleted."}
        </p>
        <Link
          to="/blog"
          className="inline-flex items-center gap-2 px-6 py-3 bg-accent-500 text-white rounded-lg hover:bg-accent-600 transition-colors"
        >
          Back to Blog
        </Link>
      </div>
    </div>
  );
}