import { GoogleLogin } from '@react-oauth/google';
import { useAuth } from '../../contexts/AuthContext';

interface GoogleSignInProps {
  variant?: 'default' | 'compact';
}

export default function GoogleSignIn({ variant = 'default' }: GoogleSignInProps) {
  const { handleGoogleSuccess, handleGoogleError } = useAuth();

  return (
    <div className={variant === 'compact' ? 'scale-80 -ml-4' : ''}>
      <GoogleLogin
        onSuccess={handleGoogleSuccess}
        onError={handleGoogleError}
        useOneTap
        theme="filled_black"
        size={variant === 'compact' ? 'medium' : 'large'}
        shape="pill"
        text="signin_with"
        locale="en"
      />
    </div>
  );
}