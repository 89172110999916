import type { Deal } from '../../../../types/agency';
import DealCard from './DealCard';

interface DealOverlayProps {
  deal: Deal | null;
}

export default function DealOverlay({ deal }: DealOverlayProps) {
  if (!deal) return null;

  return (
    <div className="opacity-80 w-full max-w-[calc(100vw-2rem)]">
      <DealCard deal={deal} index={-1} isDragging />
    </div>
  );
}