import axios from 'axios';
import { format } from 'date-fns';
import type { FlightSearchParams, FlightSearchResponse } from '../types/flights';

export async function searchFlights(params: {
  origin: string;
  destination: string;
  departureDate: string;
  returnDate: string;
  adults?: number;
}): Promise<FlightSearchResponse> {
  try {
    const formattedDepartureDate = format(new Date(params.departureDate), 'yyyy-MM-dd');
    const formattedReturnDate = format(new Date(params.returnDate), 'yyyy-MM-dd');

    const searchParams: FlightSearchParams = {
      departure_id: params.origin,
      arrival_id: params.destination,
      outbound_date: formattedDepartureDate,
      return_date: formattedReturnDate,
      adults: params.adults || 1,
    };

    const response = await axios.post<FlightSearchResponse>(
      '/api/flights/search',
      searchParams
    );

    return response.data;
  } catch (error) {
    console.error('Error searching flights:', error);
    throw new Error('Failed to search flights');
  }
}