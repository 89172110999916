import { Clock, MapPin, Users, DollarSign } from 'lucide-react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { Deal } from '../../../../types/agency';

interface DealCardProps {
  deal: Deal;
  isDragging?: boolean;
}

export default function DealCard({ deal, isDragging = false }: DealCardProps) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging: isSortableDragging
  } = useSortable({
    id: deal.id,
    data: {
      type: 'deal',
      deal
    }
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging || isSortableDragging ? 0.5 : 1
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow cursor-grab active:cursor-grabbing"
    >
      <div className="p-4">
        {/* Header with price and basic info */}
        <div className="flex items-center justify-between mb-3">
          <div className="flex items-center gap-3">
            <div className="text-sm font-medium">{deal.client}</div>
            <div className="text-xs text-gray-500">
              {deal.destinations.join(' → ')}
            </div>
          </div>
          <div className="text-right">
            <div className="text-lg font-bold text-accent-500">
              ${deal.budget.total.toLocaleString()}
            </div>
            <div className="text-xs text-gray-500">
              ${deal.budget.remaining.toLocaleString()} remaining
            </div>
          </div>
        </div>

        {/* Details */}
        <div className="grid grid-cols-2 gap-4 text-sm">
          <div className="flex items-center gap-2 text-gray-600">
            <Clock className="h-4 w-4" />
            <span>{deal.startDate.toLocaleDateString()}</span>
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <MapPin className="h-4 w-4" />
            <span>{deal.destinations[0]}</span>
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <Users className="h-4 w-4" />
            <span>Client #{deal.clientId}</span>
          </div>
          <div className="flex items-center gap-2 text-gray-600">
            <DollarSign className="h-4 w-4" />
            <span>{deal.status}</span>
          </div>
        </div>
      </div>
    </div>
  );
}