import { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import type { TravelRecommendation } from '../services/openai';
import type { SurveyResponse } from '../types/survey';
import type { DiningResult } from '../types/dining';
import type { ActivityResult } from '../types/activities';

type Destination = TravelRecommendation['destinations'][number] & {
  surveyResponses?: SurveyResponse;
  savedDining?: DiningResult[];
  savedActivitySuggestions?: ActivityResult[];
};

interface TripSlipContextType {
  destinations: Destination[];
  isOpen: boolean;
  addDestination: (destination: Destination, surveyResponses?: SurveyResponse) => void;
  removeDestination: (destinationName: string) => void;
  updateDestination: (destinationName: string, updatedDestination: Destination) => void;
  clearDestinations: () => void;
  setIsOpen: (open: boolean) => void;
  saveToTrips: (destination: Destination) => Promise<void>;
  addDiningToDestination: (destinationName: string, dining: DiningResult) => Promise<void>;
  removeDiningFromDestination: (destinationName: string, diningId: string) => Promise<void>;
}

const TripSlipContext = createContext<TripSlipContextType | undefined>(undefined);

export function TripSlipProvider({ children }: { children: ReactNode }) {
  const [destinations, setDestinations] = useState<Destination[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const { isAuthenticated, user } = useAuth();

  // Load trip slip from server on auth change
  useEffect(() => {
    if (isAuthenticated && user?.googleId) {
      loadTripSlip();
    }
  }, [isAuthenticated, user?.googleId]);

  const loadTripSlip = async () => {
    try {
      const response = await axios.get('/api/users/trip-slip', {
        headers: { Authorization: `Bearer ${user?.googleId}` }
      });
      if (response.data) {
        setDestinations(response.data.map((item: any) => ({
          ...item.destination,
          surveyResponses: item.surveyResponses,
          savedDining: item.destination.savedDining || []
        })));
      }
    } catch (error) {
      console.error('Error loading trip slip:', error);
    }
  };

  const saveTripSlip = async (newDestinations: Destination[]) => {
    if (isAuthenticated && user?.googleId) {
      try {
        // Transform destinations to match server schema
        const formattedDestinations = newDestinations.map(dest => ({
          destination: {
            name: dest.name,
            description: dest.description,
            activities: dest.activities,
            estimatedBudget: dest.estimatedBudget,
            bestTimeToVisit: dest.bestTimeToVisit,
            accommodation: dest.accommodation,
            recommendedDuration: dest.recommendedDuration,
            localTips: dest.localTips,
            savedDining: dest.savedDining || []
          },
          surveyResponses: dest.surveyResponses
        }));

        await axios.put('/api/users/trip-slip', 
          { destinations: formattedDestinations },
          { headers: { Authorization: `Bearer ${user.googleId}` } }
        );
      } catch (error) {
        console.error('Error saving trip slip:', error);
      }
    }
  };

  const addDestination = async (destination: Destination, surveyResponses?: SurveyResponse) => {
    setDestinations(prev => {
      const exists = prev.some(d => d.name === destination.name);
      if (exists) return prev;
      
      const newDestinations = [...prev, { ...destination, surveyResponses, savedDining: [] }];
      saveTripSlip(newDestinations);
      setIsOpen(true);
      return newDestinations;
    });
  };

  const removeDestination = async (destinationName: string) => {
    setDestinations(prev => {
      const newDestinations = prev.filter(d => d.name !== destinationName);
      saveTripSlip(newDestinations);
      return newDestinations;
    });
  };

  const updateDestination = async (destinationName: string, updatedDestination: Destination) => {
    setDestinations(prev => {
      const newDestinations = prev.map(d => 
        d.name === destinationName ? updatedDestination : d
      );
      saveTripSlip(newDestinations);
      return newDestinations;
    });
  };

  const addDiningToDestination = async (destinationName: string, dining: DiningResult) => {
    try {
      // Save to backend
      await axios.post('/api/users/dining/save', 
        { dining, destinationName },
        { headers: { Authorization: `Bearer ${user?.googleId}` } }
      );

      // Update local state
      setDestinations(prev => {
        return prev.map(dest => {
          if (dest.name === destinationName) {
            return {
              ...dest,
              savedDining: [...(dest.savedDining || []), dining]
            };
          }
          return dest;
        });
      });
    } catch (error) {
      console.error('Error adding dining to destination:', error);
      throw error;
    }
  };

  const removeDiningFromDestination = async (destinationName: string, diningId: string) => {
    try {
      // Remove from backend
      await axios.delete(`/api/users/dining/${diningId}`, {
        headers: { Authorization: `Bearer ${user?.googleId}` }
      });

      // Update local state
      setDestinations(prev => {
        return prev.map(dest => {
          if (dest.name === destinationName) {
            return {
              ...dest,
              savedDining: (dest.savedDining || []).filter(d => d._id !== diningId)
            };
          }
          return dest;
        });
      });
    } catch (error) {
      console.error('Error removing dining from destination:', error);
      throw error;
    }
  };

  const clearDestinations = async () => {
    setDestinations([]);
    if (isAuthenticated && user?.googleId) {
      await saveTripSlip([]);
    }
  };

  const saveToTrips = async (destination: Destination) => {
    if (!isAuthenticated || !user?.googleId) {
      throw new Error('Must be authenticated to save trips');
    }

    try {
      const tripData = {
        destination: {
          name: destination.name,
          description: destination.description,
          activities: destination.activities,
          estimatedBudget: destination.estimatedBudget,
          bestTimeToVisit: destination.bestTimeToVisit,
          accommodation: destination.accommodation,
          recommendedDuration: destination.recommendedDuration,
          localTips: destination.localTips,
          savedDining: destination.savedDining || [],
          savedActivitySuggestions: destination.savedActivitySuggestions || []
        },
        surveyResponses: destination.surveyResponses,
        removeFromTripSlip: true
      };

      await axios.post('/api/users/trips', tripData, {
        headers: { Authorization: `Bearer ${user.googleId}` }
      });
      
      // Remove from trip slip locally
      removeDestination(destination.name);
    } catch (error) {
      console.error('Error saving trip:', error);
      throw error;
    }
  };

  const value = {
    destinations,
    isOpen,
    addDestination,
    removeDestination,
    updateDestination,
    clearDestinations,
    setIsOpen,
    saveToTrips,
    addDiningToDestination,
    removeDiningFromDestination
  };

  return (
    <TripSlipContext.Provider value={value}>
      {children}
    </TripSlipContext.Provider>
  );
}

export function useTripSlip() {
  const context = useContext(TripSlipContext);
  if (context === undefined) {
    throw new Error('useTripSlip must be used within a TripSlipProvider');
  }
  return context;
}