import { useState } from 'react';
import { useTripSlip } from '../../contexts/TripSlipContext';
import { useAuth } from '../../contexts/AuthContext';
import TripSlipSection from './TripSlipSection';
import { Calendar, Clock, DollarSign, Plane, Hotel, Activity, Trash2, Info, LogIn, Utensils, Save } from 'lucide-react';
import FlightSearch from './FlightSearch';
import HotelSearch from './HotelSearch';
import DiningSearch from './DiningSearch';
import GoogleSignIn from '../auth/GoogleSignIn';
import Alert from '../ui/Alert';
import PaywallModal from '../ui/PaywallModal';
import { usePaywall } from '../../contexts/PaywallContext';
interface DateSuggestion {
  start: string;
  end: string;
  reason: string;
}

interface SearchState {
  flights: string | null;
  hotels: string | null;
  dining: string | null;
}

export default function TripSlip() {
  //const { destinations, removeDestination, saveToTrips } = useTripSlip();
  const { destinations, removeDestination } = useTripSlip();
  const { isAuthenticated } = useAuth();
  const [searchState, setSearchState] = useState<SearchState>({
    flights: null,
    hotels: null,
    dining: null
  });
  const [savingDestination, setSavingDestination] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [showPaywallModal, setShowPaywallModal] = useState(false);
  const { setShowPaywall } = usePaywall();
  const saveToTrips = async (destination: Destination) => {
    if (!isAuthenticated) {
      throw new Error('Must be authenticated to save trips');
    }
    setShowPaywall(true);
    // Show paywall modal instead of saving
  }
  const getSuggestedDates = (destination: any): DateSuggestion => {
    const now = new Date();
    let startDate = new Date();
    let endDate = new Date();
    let reason = '';

    // Parse trip timing if available from survey responses
    if (destination.surveyResponses?.tripTiming) {
      switch (destination.surveyResponses.tripTiming) {
        case 'within-month':
          startDate.setDate(now.getDate() + 14);
          reason = 'Based on your preferred trip timing (within a month)';
          break;
        case '1-3-months':
          startDate.setMonth(now.getMonth() + 2);
          reason = 'Based on your preferred trip timing (1-3 months away)';
          break;
        case '3-6-months':
          startDate.setMonth(now.getMonth() + 4);
          reason = 'Based on your preferred trip timing (3-6 months away)';
          break;
        case '6-plus-months':
          startDate.setMonth(now.getMonth() + 7);
          reason = 'Based on your preferred trip timing (6+ months away)';
          break;
        default:
          // Use best time to visit if no specific timing
          const bestTime = destination.bestTimeToVisit.toLowerCase();
          const months = {
            january: 0, february: 1, march: 2, april: 3, may: 4, june: 5,
            july: 6, august: 7, september: 8, october: 9, november: 10, december: 11
          };

          // Find the first mentioned month in bestTimeToVisit
          for (const [month, monthIndex] of Object.entries(months)) {
            if (bestTime.includes(month)) {
              startDate = new Date(now.getFullYear(), monthIndex, 15);
              if (startDate < now) {
                startDate.setFullYear(startDate.getFullYear() + 1);
              }
              reason = `Based on destination's best time to visit (${destination.bestTimeToVisit})`;
              break;
            }
          }
      }
    } else {
      // Default to best time to visit logic
      const bestTime = destination.bestTimeToVisit.toLowerCase();
      const months = {
        january: 0, february: 1, march: 2, april: 3, may: 4, june: 5,
        july: 6, august: 7, september: 8, october: 9, november: 10, december: 11
      };

      for (const [month, monthIndex] of Object.entries(months)) {
        if (bestTime.includes(month)) {
          startDate = new Date(now.getFullYear(), monthIndex, 15);
          if (startDate < now) {
            startDate.setFullYear(startDate.getFullYear() + 1);
          }
          reason = `Based on destination's best time to visit (${destination.bestTimeToVisit})`;
          break;
        }
      }
    }

    // Set duration based on survey response
    let duration = 7; // Default to 7 days
    if (destination.surveyResponses?.tripDuration) {
      switch (destination.surveyResponses.tripDuration) {
        case 'weekend':
          duration = 3;
          break;
        case 'short':
          duration = 5;
          break;
        case 'week':
          duration = 7;
          break;
        case 'two-weeks':
          duration = 14;
          break;
        case 'long':
          duration = 21;
          break;
      }
    }

    endDate = new Date(startDate);
    endDate.setDate(startDate.getDate() + duration);

    return {
      start: startDate.toISOString().split('T')[0],
      end: endDate.toISOString().split('T')[0],
      reason
    };
  };

  const toggleSearch = (type: keyof SearchState, destinationName: string) => {
    setSearchState(prev => ({
      ...prev,
      [type]: prev[type] === destinationName ? null : destinationName
    }));
  };

  const handleSaveToTrips = async (destination: any) => {
    if (!isAuthenticated) return;

    setSavingDestination(destination.name);
    try {
      await saveToTrips(destination);
      setAlertMessage('Destination saved to trips successfully!');
      setShowAlert(true);
    } catch (error) {
      console.error('Error saving to trips:', error);
      setAlertMessage('Failed to save destination to trips. Please try again.');
      setShowAlert(true);
    } finally {
      setSavingDestination(null);
    }
  };

  if (destinations.length === 0) {
    return (
      <div className="text-center text-gray-300 py-8">
        <p>Start planning your trip by taking our AI travel quiz!</p>
      </div>
    );
  }

  return (
    <div className="trip-slip-content space-y-8">
      {showAlert && (
        <Alert
          type={alertMessage.includes('success') ? 'success' : 'error'}
          message={alertMessage}
          onClose={() => setShowAlert(false)}
        />
      )}

      {!isAuthenticated && destinations.length > 0 && (
        <div className="bg-accent-500/10 border border-accent-500/20 rounded-xl p-4">
          <div className="flex items-start gap-3">
            <LogIn className="h-5 w-5 text-accent-400 flex-shrink-0 mt-1" />
            <div className="space-y-2">
              <div>
                <h4 className="text-white font-medium mb-1">Save Your Progress</h4>
                <p className="text-sm text-gray-300">
                  Sign in with Google to save your trip planning progress.
                </p>
              </div>
              <GoogleSignIn variant="compact" />
            </div>
          </div>
        </div>
      )}

      {destinations.map((destination) => {
        const suggestedDates = getSuggestedDates(destination);
        
        return (
          <div key={destination.name} className="relative">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-4">
                <h3 className="text-2xl font-bold text-white">{destination.name}</h3>
                <button
                  onClick={() => removeDestination(destination.name)}
                  className="p-2 text-gray-400 hover:text-red-500 hover:bg-red-500/10 rounded-lg transition-all duration-200"
                  title="Remove destination"
                >
                  <Trash2 className="h-4 w-4" />
                </button>
              </div>
              <div className="flex flex-col sm:flex-row items-center gap-4">
                <div className="flex items-center gap-2">
                <button
                  onClick={() => handleSaveToTrips(destination)}
                  className="flex items-center gap-2 px-3 py-2 bg-emerald-500/20 hover:bg-emerald-500/30 text-emerald-300 rounded-lg transition-colors group relative"
                  title="Save trip to access our visual planner and finalize your trip time schedule"
                >
                  <Save className="h-4 w-4" />
                  <span className="text-sm">Save Trip</span>
                  <div className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-sm rounded-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible group-active:opacity-100 group-active:visible transition-all duration-200 whitespace-nowrap">
                    Save trip to access our visual planner and your trip time schedule
                  </div>
                </button>
                  <button
                    onClick={() => toggleSearch('flights', destination.name)}
                    className={`
                      flex items-center gap-2 px-4 py-2 rounded-lg transition-all duration-200
                      ${searchState.flights === destination.name
                        ? 'bg-accent-500 text-white'
                        : 'bg-accent-500/20 hover:bg-accent-500/30 text-accent-300'
                      }
                    `}
                  >
                    <Plane className="h-4 w-4" />
                    Find Flights
                  </button>
                </div>
                {searchState.flights === destination.name && (
                  <div className="flex items-center gap-1 text-xs text-accent-300">
                    <Info className="h-3 w-3" />
                    <span>Select a departure airport to search for flights</span>
                  </div>
                )}
              </div>
            </div>

            {searchState.flights === destination.name && (
              <FlightSearch
                destination={destination}
                onClose={() => toggleSearch('flights', destination.name)}
                suggestedDates={suggestedDates}
              />
            )}

            {searchState.hotels === destination.name && (
              <HotelSearch
                destination={destination.name}
                onClose={() => toggleSearch('hotels', destination.name)}
                suggestedDates={suggestedDates}
                accommodation={destination.accommodation}
              />
            )}

            <div className="space-y-6">
              <TripSlipSection
                icon={Calendar}
                title="Best Time to Visit"
                content={destination.bestTimeToVisit}
                destinationName={destination.name}
              />
              <TripSlipSection
                icon={Clock}
                title="Recommended Duration"
                content={destination.recommendedDuration}
                destinationName={destination.name}
              />
              <TripSlipSection
                icon={DollarSign}
                title="Estimated Budget"
                content={destination.estimatedBudget}
                destinationName={destination.name}
              />
              <TripSlipSection
                icon={Hotel}
                title="Accommodation"
                content={destination.accommodation}
                destinationName={destination.name}
                suggestedDates={suggestedDates}
              />
              <TripSlipSection
                icon={Utensils}
                title="Dining"
                content={
                  <div className="flex items-center justify-between">
                    <span className="text-gray-300">Find restaurants and dining options</span>
                  </div>
                }
                destinationName={destination.name}
                suggestedDates={suggestedDates}
              />
              <TripSlipSection
                icon={Activity}
                title="Activities"
                content={
                  <ul>
                    {destination?.activities?.map((activity, index) => (
                      <li key={index} className="flex items-center gap-2">
                        <span className="w-6 h-6 rounded-full bg-accent-500 flex items-center justify-center text-white text-sm">
                          {index + 1}
                        </span>
                        <span>{activity}</span>
                      </li>
                    ))}
                  </ul>
                }
                destinationName={destination.name}
              />
            </div>
          </div>
        );
      })}
      <PaywallModal 
      isOpen={showPaywallModal}
      onClose={() => setShowPaywallModal(false)}
    />
    </div>
  );
}