import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Compass, Search as SearchIcon } from 'lucide-react';
import SEOHead from '@/components/SEO/SEOHead';
import StructuredData from '@/components/SEO/StructuredData';
import GoogleSignIn from '@/components/auth/GoogleSignIn';
import Alert from '@/components/ui/Alert';
import axios from 'axios';

interface SavedActivity {
  _id: string;
  title: string;
  description: string;
  price?: string;
  duration?: string;
  images: string[];
  links: Array<{
    title: string;
    url: string;
  }>;
  savedAt: string;
}

export default function Activities() {
  const [savedActivities, setSavedActivities] = useState<SavedActivity[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const { isAuthenticated, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    loadSavedActivities();
  }, [isAuthenticated, user?.googleId]);

  const loadSavedActivities = async () => {
    if (!isAuthenticated || !user?.googleId) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.get('/api/users/activities/search', {
        headers: { Authorization: `Bearer ${user.googleId}` }
      });
      // Sort activities by savedAt date in descending order (newest first)
      const sortedActivities = response.data.sort((a: SavedActivity, b: SavedActivity) => 
        new Date(b.savedAt).getTime() - new Date(a.savedAt).getTime()
      );
      setSavedActivities(sortedActivities);
    } catch (error) {
      console.error('Error loading saved activities:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteActivity = async (activityId: string) => {
    if (!isAuthenticated || !user?.googleId) return;

    try {
      const response = await axios.delete(`/api/users/activities/search/${activityId}`, {
        headers: { Authorization: `Bearer ${user.googleId}` }
      });
      setSavedActivities(response.data);
      setShowAlert(true);
    } catch (error) {
      console.error('Error deleting activity:', error);
    }
  };

  const pageData = {
    '@type': 'ItemList',
    itemListElement: savedActivities.map((activity, index) => ({
      '@type': 'Thing',
      position: index + 1,
      name: activity.title,
      description: activity.description,
      url: `https://wanderwise.com/activities/${activity._id}`
    }))
  };

  if (!isAuthenticated) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24">
        <div className="max-w-lg mx-auto px-4">
          <div className="text-center space-y-6">
            <div className="bg-accent-500/10 rounded-full p-4 w-16 h-16 mx-auto">
              <Compass className="w-8 h-8 text-accent-400" />
            </div>
            <h1 className="text-3xl font-bold text-white">Save Your Favorite Activities</h1>
            <p className="text-gray-300">
              Sign in to save activities and access them from any device. Build your collection of exciting experiences for your next adventure.
            </p>
            <div className="flex justify-center">
              <GoogleSignIn />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <SEOHead
        title="Saved Activities"
        description="View and manage your saved activities. Access your personalized collection of travel experiences."
        canonicalUrl="https://wanderwise.com/activities"
      />
      <StructuredData type="ItemList" data={pageData} />

      {showAlert && (
        <Alert
          type="success"
          message="Activity removed successfully!"
          onClose={() => setShowAlert(false)}
        />
      )}

      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-12 md:py-24">
        <div className="max-w-7xl mx-auto px-3 sm:px-6 lg:px-8">
          <div className="text-center mb-8 md:mb-12">
            <h1 className="text-2xl md:text-3xl font-bold text-white mb-3 md:mb-4">Your Saved Activities</h1>
            <p className="text-sm md:text-base text-gray-300">
              {savedActivities.length > 0
                ? 'Manage your saved activities and discover new experiences.'
                : 'Start saving activities to build your adventure wishlist.'}
            </p>
          </div>

          {/* Search Button */}
          <div className="flex justify-center mb-8">
            <button
              onClick={() => navigate('/')}
              className="flex items-center gap-2 px-6 py-3 bg-accent-500 hover:bg-accent-600 text-white rounded-lg transition-colors"
            >
              <SearchIcon className="h-5 w-5" />
              Search Activities
            </button>
          </div>

          {isLoading ? (
            <div className="animate-pulse space-y-8">
              {[1, 2, 3].map((n) => (
                <div key={n} className="h-64 bg-white/5 rounded-2xl" />
              ))}
            </div>
          ) : savedActivities.length === 0 ? (
            <div className="text-center py-8 md:py-12">
              <div className="bg-white/5 rounded-xl md:rounded-2xl p-6 md:p-8 max-w-lg mx-auto">
                <Compass className="w-10 h-10 md:w-12 md:h-12 text-gray-400 mx-auto mb-3 md:mb-4" />
                <h3 className="text-lg md:text-xl font-medium text-white mb-2">No saved activities yet</h3>
                <p className="text-sm md:text-base text-gray-400 mb-4 md:mb-6">
                  Use our AI-powered search to discover and save activities that match your interests.
                </p>
                <button
                  onClick={() => navigate('/')}
                  className="inline-flex items-center px-4 py-2 rounded-lg bg-accent-500 text-white hover:bg-accent-600 transition-colors text-sm md:text-base"
                >
                  <SearchIcon className="h-4 w-4 mr-2" />
                  Search Activities
                </button>
              </div>
            </div>
          ) : (
            <div className="grid gap-6 md:gap-8">
              {savedActivities.map((activity) => (
                <div
                  key={activity._id}
                  className="bg-gray-900/60 backdrop-blur-sm rounded-xl overflow-hidden border border-white/10 hover:border-accent-500/30 transition-all duration-300"
                >
                  {/* Images */}
                  <div className="relative h-48 md:h-64 overflow-hidden">
                    <div className="flex h-full">
                      {activity.images.slice(0, 3).map((image, imgIndex) => (
                        <div
                          key={imgIndex}
                          className="flex-1 relative first:rounded-tl-xl last:rounded-tr-xl overflow-hidden"
                        >
                          <img
                            src={image}
                            alt={`${activity.title} - Image ${imgIndex + 1}`}
                            className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 hover:scale-105"
                          />
                        </div>
                      ))}
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/50 to-transparent" />
                  </div>

                  {/* Content */}
                  <div className="p-6 md:p-8 space-y-6">
                    <div className="flex flex-wrap items-start justify-between gap-4">
                      <div>
                        <h3 className="text-xl md:text-2xl font-bold text-white mb-2">
                          {activity.title}
                        </h3>
                        <div className="flex flex-wrap items-center gap-4">
                          {activity.price && (
                            <span className="px-3 py-1 bg-emerald-500/10 text-emerald-400 rounded-lg">
                              {activity.price}
                            </span>
                          )}
                          {activity.duration && (
                            <span className="px-3 py-1 bg-blue-500/10 text-blue-400 rounded-lg">
                              {activity.duration}
                            </span>
                          )}
                        </div>
                      </div>
                      <button
                        onClick={() => handleDeleteActivity(activity._id)}
                        className="px-4 py-2 bg-red-500/10 hover:bg-red-500/20 text-red-400 rounded-lg transition-colors"
                      >
                        Remove
                      </button>
                    </div>

                    <p className="text-gray-300">{activity.description}</p>

                    {/* Links */}
                    <div className="space-y-3">
                      <h4 className="text-sm font-medium text-white">Booking Options:</h4>
                      <div className="grid gap-2">
                        {activity.links.map((link, index) => (
                          <a
                            key={index}
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="flex items-center justify-between p-3 bg-white/5 hover:bg-white/10 rounded-lg transition-colors group"
                          >
                            <span className="text-gray-300 group-hover:text-white">
                              {link.title}
                            </span>
                            <SearchIcon className="h-4 w-4 text-gray-500 group-hover:text-accent-400" />
                          </a>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}