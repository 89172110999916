import { Link } from 'react-router-dom';
import { Instagram, BookOpen, Compass } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="bg-gray-900/60 backdrop-blur-sm border-t border-white/10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="flex flex-col items-center justify-center space-y-8">
          {/* Logo */}
          <Link to="/" className="flex items-center space-x-2 group">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-accent-500 to-primary-600 blur-lg opacity-50 group-hover:opacity-75 transition-opacity duration-300" />
              <Compass className="h-8 w-8 relative text-accent-400 group-hover:text-accent-300 transition-all duration-300 animate-spin-slow" />
            </div>
            <span className="text-xl font-bold bg-gradient-to-r from-accent-400 to-primary-400 text-transparent bg-clip-text">
              WanderWise
            </span>
          </Link>

          {/* Navigation */}
          <nav className="flex flex-wrap justify-center gap-6">
            <Link
              to="/blog"
              className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors group"
            >
              <BookOpen className="h-4 w-4 text-accent-400 group-hover:text-accent-300" />
              <span>Travel Blog</span>
            </Link>
            <a
              href="https://instagram.com/wiserwanderer_"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors group"
            >
              <Instagram className="h-4 w-4 text-accent-400 group-hover:text-accent-300" />
              <span>@wiserwanderer_</span>
            </a>
          </nav>

          {/* Copyright */}
          <div className="text-sm text-gray-500">
            © {new Date().getFullYear()} WanderWise. All rights reserved.
          </div>
        </div>
      </div>
    </footer>
  );
}