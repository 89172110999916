import { motion } from 'framer-motion';
import { useAgency } from '../../contexts/AgencyContext';
import ClientList from '../../components/EnterpriseCRM/ClientList';
import { useState } from 'react';
const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

export default function Clients() {
  const { agency } = useAgency();
  const [searchQuery, setSearchQuery] = useState('');

  if (!agency) return null;

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="show"
      className="space-y-6"
    >
      <ClientList searchQuery={searchQuery} />
    </motion.div>
  );
}