import { createContext, useContext, useState, ReactNode } from 'react';

interface PaywallContextType {
  showPaywall: boolean;
  setShowPaywall: (show: boolean) => void;
}

const PaywallContext = createContext<PaywallContextType | undefined>(undefined);

export function PaywallProvider({ children }: { children: ReactNode }) {
  const [showPaywall, setShowPaywall] = useState(false);

  return (
    <PaywallContext.Provider value={{ showPaywall, setShowPaywall }}>
      {children}
    </PaywallContext.Provider>
  );
}

export function usePaywall() {
  const context = useContext(PaywallContext);
  if (context === undefined) {
    throw new Error('usePaywall must be used within a PaywallProvider');
  }
  return context;
}