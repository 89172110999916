import { useState } from 'react';
import { useBlog } from '../hooks/useBlog';
import SEOHead from '../components/SEO/SEOHead';
import StructuredData from '../components/SEO/StructuredData';
import BlogHeader from '../components/blog/BlogHeader';
import BlogFilters from '../components/blog/BlogFilters';
import ArticleGrid from '../components/blog/ArticleGrid';

export default function Blog() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  
  const { articles, isLoading, error, hasMore, loadMore, total } = useBlog({
    category: selectedCategory
  });

  const filteredArticles = articles.filter(article => 
    article.headline.toLowerCase().includes(searchQuery.toLowerCase()) ||
    article.metaDescription.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const pageData = {
    '@type': 'Blog',
    name: 'WanderWise Travel Blog',
    description: 'Expert travel tips, destination guides, and insights for the modern traveler.',
    url: 'https://wanderwise.com/blog',
    blogPost: filteredArticles.map(article => ({
      '@type': 'BlogPosting',
      headline: article.headline,
      description: article.metaDescription,
      image: article.image,
      datePublished: article.publishedAt,
      author: {
        '@type': 'Person',
        name: 'WanderWise Team'
      }
    }))
  };

  return (
    <>
      <SEOHead
        title="Travel Blog"
        description="Discover expert travel tips, destination guides, and insights to help you plan your perfect trip."
        canonicalUrl="https://wanderwise.com/blog"
      />
      <StructuredData type="Blog" data={pageData} />

      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <BlogHeader 
            searchQuery={searchQuery}
            onSearchChange={setSearchQuery}
          />

          <BlogFilters
            selectedCategory={selectedCategory}
            onSelectCategory={setSelectedCategory}
          />

          <ArticleGrid
            articles={filteredArticles}
            isLoading={isLoading}
          />

          {hasMore && (
            <div className="text-center mt-12">
              <button
                onClick={loadMore}
                className="px-6 py-3 bg-accent-500 text-white rounded-lg hover:bg-accent-600 transition-colors"
              >
                Load More Articles
              </button>
            </div>
          )}

          {error && (
            <div className="text-center mt-12">
              <p className="text-red-400">{error}</p>
            </div>
          )}

          {!isLoading && !error && filteredArticles.length === 0 && (
            <div className="text-center mt-12">
              <p className="text-gray-400">No articles found matching your criteria.</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}