import OpenAI from 'openai';
import type { SurveyResponse } from '../types/survey';

const openai = new OpenAI({
  apiKey: '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',
  dangerouslyAllowBrowser: true,
});

export interface TravelRecommendation {
  destinations: Array<{
    name: string;
    description: string;
    activities: string[];
    estimatedBudget: string;
    bestTimeToVisit: string;
    accommodation: string;
    recommendedDuration: string;
    localTips: string[];
  }>;
  personalizedTips: string[];
  travelStyle: string;
}

export interface Airport {
  code: string;
  name: string;
  city: string;
  description: string;
}

export interface Location {
  city: string;
  region: string;
  country: string;
}

export async function generateTripDescription(
  destination: string,
  surveyResponses: SurveyResponse
): Promise<string> {
  const prompt = `Based on these travel preferences:

Travel Style: ${surveyResponses.travelerType.join(', ')}
Trip Duration: ${surveyResponses.tripDuration}
Budget: ${surveyResponses.budget}
Travel Group: ${surveyResponses.companions}
Preferred Experiences: ${surveyResponses.experiences.join(', ')}
Travel Pace: ${surveyResponses.pace}
Climate Preference: ${surveyResponses.climate}

Create a single, energetic sentence that starts with "As a [travel style] traveler seeking [key preferences]..." and explains why ${destination} perfectly matches their specific survey choices. Keep it concise and exciting!

Format the response as a simple string with no additional formatting.`;

  try {
    const completion = await openai.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4o',
      temperature: 1.0,
      max_tokens: 200,
    });

    const response = completion.choices[0].message.content;
    if (!response) throw new Error('No response from AI');

    return response.trim();
  } catch (error) {
    console.error('Error generating trip description:', error);
    throw new Error('Failed to generate trip description');
  }
}
export async function generateMultiDestinationRecommendation(
  destinations: string[]
): Promise<TravelRecommendation> {
  const prompt = `Provide detailed recommendations for the following destinations: ${destinations.join(', ')}.

For each destination, provide information in this exact format:

DESTINATION:
Name: [Full destination name including country]
Description: [2-3 sentences about the destination]
Activities: [List at least 10 specific activities, separated by semicolons]
Budget: [Estimated cost range]
Best Time: [Best months to visit]
Accommodation: [Specific accommodation recommendation]
Duration: [Recommended duration based on activities]
Local Tips:
- [First tip]
- [Second tip]
- [Third tip]
- [Fourth tip]

[Repeat the above format for each destination]

TRAVEL STYLE:
[One sentence describing the unique character and appeal of these destinations as a combined trip]

Please follow this format exactly for each destination, including the headers and structure.`;

  try {
    const completion = await openai.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4o',
      temperature: 1.0,
      max_tokens: 2500,
    });

    const response = completion.choices[0].message.content;
    if (!response) throw new Error('No response from AI');

    return parseMultiDestinationResponse(response);
  } catch (error) {
    console.error('Error generating destination recommendations:', error);
    throw new Error('Failed to generate destination recommendations');
  }
}

function parseMultiDestinationResponse(response: string): TravelRecommendation {
  const destinations: TravelRecommendation['destinations'] = [];
  let travelStyle = '';

  // Split response into sections by double newline
  const sections = response.split('\n\n');
  
  let currentDestination: any = {};
  let collectingTips = false;
  const localTips: string[] = [];

  sections.forEach((section) => {
    if (section.startsWith('DESTINATION:')) {
      // If we were collecting a previous destination, save it
      if (Object.keys(currentDestination).length > 0) {
        currentDestination.localTips = [...localTips];
        destinations.push(currentDestination);
        localTips.length = 0; // Clear tips array
      }
      
      // Start new destination
      currentDestination = {};
      collectingTips = false;
    } else if (section.startsWith('TRAVEL STYLE:')) {
      // Save the last destination before setting travel style
      if (Object.keys(currentDestination).length > 0) {
        currentDestination.localTips = [...localTips];
        destinations.push(currentDestination);
      }
      travelStyle = section.replace('TRAVEL STYLE:', '').trim();
      return;
    }

    // Process destination details
    const lines = section.split('\n');
    lines.forEach((line) => {
      if (line.startsWith('Name:')) {
        currentDestination.name = line.replace('Name:', '').trim();
      } else if (line.startsWith('Description:')) {
        currentDestination.description = line.replace('Description:', '').trim();
      } else if (line.startsWith('Activities:')) {
        currentDestination.activities = line
          .replace('Activities:', '')
          .trim()
          .split(';')
          .map(a => a.trim())
          .filter(a => a.length > 0);
      } else if (line.startsWith('Budget:')) {
        currentDestination.estimatedBudget = line.replace('Budget:', '').trim();
      } else if (line.startsWith('Best Time:')) {
        currentDestination.bestTimeToVisit = line.replace('Best Time:', '').trim();
      } else if (line.startsWith('Accommodation:')) {
        currentDestination.accommodation = line.replace('Accommodation:', '').trim();
      } else if (line.startsWith('Duration:')) {
        currentDestination.recommendedDuration = line.replace('Duration:', '').trim();
      } else if (line.startsWith('Local Tips:')) {
        collectingTips = true;
      } else if (collectingTips && line.startsWith('-')) {
        localTips.push(line.substring(1).trim());
      }
    });
  });

  // Ensure we have valid data
  if (destinations.length === 0) {
    destinations.push({
      name: 'Error loading destination',
      description: 'Please try again',
      activities: ['No activities available'],
      estimatedBudget: 'Not available',
      bestTimeToVisit: 'Not available',
      accommodation: 'Not available',
      recommendedDuration: 'Not available',
      localTips: ['No tips available'],
    });
  }

  return {
    destinations,
    personalizedTips: [],
    travelStyle: travelStyle || 'Multi-Destination Travel Experience',
  };
}
export async function generateSingleDestinationRecommendation(
  destination: string
): Promise<TravelRecommendation> {
  const prompt = `Provide a detailed recommendation for ${destination} in this exact format:

DESTINATION:
Name: ${destination}
Description: [2-3 sentences about the destination]
Activities: [List at least 10 specific activities, separated by semicolons]
Budget: [Estimated cost range]
Best Time: [Best months to visit]
Accommodation: [Specific accommodation recommendation]
Duration: [Recommended duration based on activities]
Local Tips:
- [First tip]
- [Second tip]
- [Third tip]
- [Fourth tip]

TRAVEL STYLE:
[One sentence describing the unique character and appeal of this destination]

Please follow this format exactly, including the headers and structure.`;

  try {
    const completion = await openai.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4o',
      temperature: 1.0,
      max_tokens: 1000,
    });

    const response = completion.choices[0].message.content;
    if (!response) throw new Error('No response from AI');

    return parseSingleDestinationResponse(response);
  } catch (error) {
    console.error('Error generating destination recommendation:', error);
    throw new Error('Failed to generate destination recommendation');
  }
}

function parseSingleDestinationResponse(response: string): TravelRecommendation {
  const sections = response.split('\n\n');
  const destinationSection = sections.find(s => s.startsWith('DESTINATION:'));
  const travelStyleSection = sections.find(s => s.startsWith('TRAVEL STYLE:'));

  if (!destinationSection) {
    throw new Error('Invalid response format: Missing destination section');
  }

  const lines = destinationSection.split('\n');
  
  // Extract local tips
  const localTips: string[] = [];
  let inTipsSection = false;
  for (const line of lines) {
    if (line.startsWith('Local Tips:')) {
      inTipsSection = true;
    } else if (inTipsSection && line.startsWith('-')) {
      localTips.push(line.substring(1).trim());
    }
  }

  const destination = {
    name: extractValue(lines, 'Name:'),
    description: extractValue(lines, 'Description:'),
    activities: extractValue(lines, 'Activities:')
      .split(';')
      .map(a => a.trim())
      .filter(a => a.length > 0),
    estimatedBudget: extractValue(lines, 'Budget:'),
    bestTimeToVisit: extractValue(lines, 'Best Time:'),
    accommodation: extractValue(lines, 'Accommodation:'),
    recommendedDuration: extractValue(lines, 'Duration:'),
    localTips
  };

  const travelStyle = travelStyleSection
    ? travelStyleSection.replace('TRAVEL STYLE:', '').trim()
    : 'Custom Travel Experience';

  return {
    destinations: [destination],
    personalizedTips: [],
    travelStyle
  };
}

function extractValue(lines: string[], key: string): string {
  const line = lines.find(l => l.startsWith(key));
  return line ? line.substring(key.length).trim() : '';
}

function extractLocalTips(lines: string[]): string[] {
  const tips: string[] = [];
  let inTipsSection = false;

  for (const line of lines) {
    if (line.startsWith('Local Tips:')) {
      inTipsSection = true;
    } else if (inTipsSection && line.startsWith('-')) {
      tips.push(line.substring(1).trim());
    } else if (inTipsSection && line.trim() === '') {
      break;
    }
  }

  return tips;
}

export async function generateDepartureAirportSuggestions(): Promise<Airport[]> {
  let locationContext = '';
  try {
    const location = await getCurrentLocation();
    locationContext = `near ${location.city}, ${location.region}, ${location.country}`;
  } catch (error) {
    console.warn('Could not get location:', error);
    locationContext = 'in major international hubs';
  }

  const prompt = `Suggest 3 major airports ${locationContext} that would be convenient departure points for international travel.
For each airport, provide:
1. The IATA airport code
2. The full airport name
3. The city it serves
4. A brief description highlighting why it's convenient for travelers from this area (connections, destinations, accessibility)

Format the response exactly as follows (just the data, no additional text):
CODE|Full Airport Name|City|Description
CODE|Full Airport Name|City|Description
CODE|Full Airport Name|City|Description`;

  try {
    const completion = await openai.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4o',
      temperature: 1.0,
      max_tokens: 500,
    });

    const response = completion.choices[0].message.content;
    if (!response) throw new Error('No response from AI');

    return response.split('\n').map(line => {
      const [code, name, city, description] = line.split('|');
      return { code, name, city, description };
    });
  } catch (error) {
    console.error('Error generating airport suggestions:', error);
    throw new Error('Failed to generate airport suggestions');
  }
}

async function getCurrentLocation(): Promise<Location> {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported by your browser'));
      return;
    }

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        try {
          const response = await fetch(
            `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`
          );
          const data = await response.json();
          resolve({
            city: data.city,
            region: data.principalSubdivision,
            country: data.countryName,
          });
        } catch (error) {
          reject(error);
        }
      },
      (error) => {
        reject(error);
      }
    );
  });
}

export async function generateAlternativeActivity(
  destinationName: string,
  currentActivity: string,
  existingActivities: string[]
): Promise<string> {
  const prompt = `For ${destinationName}, suggest an alternative activity to replace "${currentActivity}".
The new activity should:
1. Be different from the current activity and these existing activities: ${existingActivities.join(', ')}
2. Be specific to ${destinationName}
3. Be similarly engaging and match the destination's character
4. Include specific locations or venues where applicable

Provide just the new activity text with no additional formatting or explanation.`;

  try {
    const completion = await openai.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4o',
      temperature: 0.8,
      max_tokens: 100,
    });

    const response = completion.choices[0].message.content;
    if (!response) throw new Error('No response from AI');

    return response.trim();
  } catch (error) {
    console.error('Error generating alternative activity:', error);
    throw new Error('Failed to generate alternative activity');
  }
}

export async function generateNewActivity(
  destinationName: string,
  existingActivities: string[]
): Promise<string> {
  const prompt = `Suggest a new unique activity for ${destinationName}.
The activity should:
1. Be different from these existing activities: ${existingActivities.join(', ')}
2. Be specific to ${destinationName}
3. Include specific locations or venues where applicable
4. Be engaging and match the destination's character

Provide just the new activity text with no additional formatting or explanation.`;

  try {
    const completion = await openai.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4o',
      temperature: 0.8,
      max_tokens: 100,
    });

    const response = completion.choices[0].message.content;
    if (!response) throw new Error('No response from AI');

    return response.trim();
  } catch (error) {
    console.error('Error generating new activity:', error);
    throw new Error('Failed to generate new activity');
  }
}

export async function generateTravelRecommendations(
  surveyResponses: SurveyResponse,
  excludeDestinations: string[] = []
): Promise<TravelRecommendation> {
  const excludeText = excludeDestinations.length
    ? `\nPlease exclude these destinations: ${excludeDestinations.join(', ')}`
    : '';

  const domesticText = surveyResponses.stayDomestic
    ? '\nOnly recommend destinations within the United States.'
    : '';

  const travelStyles = surveyResponses.travelerType.map(style => {
    switch (style) {
      case 'adventure': return 'seeking thrilling experiences and outdoor activities';
      case 'culture': return 'interested in local traditions and cultural immersion';
      case 'luxury': return 'preferring high-end experiences and premium accommodations';
      case 'budget': return 'looking for value and authentic local experiences';
      case 'foodie': return 'passionate about culinary experiences and local cuisine';
      case 'nature': return 'drawn to natural landscapes and outdoor environments';
      case 'urban': return 'interested in city life and metropolitan experiences';
      case 'relaxation': return 'seeking peaceful and rejuvenating experiences';
      case 'photography': return 'focused on capturing beautiful moments and landscapes';
      case 'eco': return 'prioritizing sustainable and eco-friendly travel';
      case 'history': return 'fascinated by historical sites and heritage';
      case 'wellness': return 'interested in health, wellness, and spiritual experiences';
      default: return style;
    }
  }).join(', ');

  const prompt = `As a travel expert, provide detailed recommendations for a traveler who is ${travelStyles}.

Additional preferences:
Trip Timing: ${surveyResponses.tripTiming}
Trip Duration: ${surveyResponses.tripDuration}
Budget: ${surveyResponses.budget}
Travel Companions: ${surveyResponses.companions}
Accommodation Preference: ${surveyResponses.accommodation}
Desired Experiences: ${surveyResponses.experiences.join(', ')}
Travel Pace: ${surveyResponses.pace}
Climate Preference: ${surveyResponses.climate}

Please provide 5 unique ${surveyResponses.stayDomestic ? 'United States' : ''} destination recommendations that specifically cater to these combined travel styles and preferences. The recommendations should align with the specified budget of ${surveyResponses.budget} - for higher budgets ($3,000+ per person), focus on premium destinations and luxury experiences, we should attempt to exhaust the whole budget, while for lower budgets (under $3,000), prioritize value-oriented but still high-quality options.${excludeText}${domesticText}

Format each recommendation exactly as follows:

DESTINATION 1:
Name: [Destination Name]
Description: [2-3 sentences about the destination]
Activities: [List at least 10 specific activities, separated by semicolons]
Budget: [Estimated cost range]
Best Time: [Best months to visit]
Accommodation: [Specific accommodation recommendation]
Duration: [Recommended duration based on trip length and activities]
Local Tips: [List 3-4 destination-specific insider tips, each on a new line starting with -]

[Repeat for DESTINATIONS 2-5]

GENERAL TIPS:
[List 3-4 broad travel tips that apply to the entire trip and selected travel style]

TRAVEL STYLE:
[One sentence summarizing the unique combination of travel preferences]`;

  try {
    const completion = await openai.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4o',
      temperature: 1.2,
      max_tokens: 2500,
    });

    const response = completion.choices[0].message.content;
    if (!response) throw new Error('No response from AI');

    return parseAIResponse(response);
  } catch (error) {
    console.error('Error generating travel recommendations:', error);
    throw new Error('Failed to generate travel recommendations');
  }
}

function parseAIResponse(response: string): TravelRecommendation {
  const destinations: TravelRecommendation['destinations'] = [];
  const personalizedTips: string[] = [];
  let travelStyle = '';

  // Split response into sections
  const sections = response.split('\n\n');

  sections.forEach((section) => {
    if (section.startsWith('DESTINATION')) {
      const lines = section.split('\n');
      const destination = {
        name: extractValue(lines, 'Name:'),
        description: extractValue(lines, 'Description:'),
        activities: extractValue(lines, 'Activities:')
          .split(';')
          .map((a) => a.trim())
          .filter((a) => a.length > 0),
        estimatedBudget: extractValue(lines, 'Budget:'),
        bestTimeToVisit: extractValue(lines, 'Best Time:'),
        accommodation: extractValue(lines, 'Accommodation:'),
        recommendedDuration: extractValue(lines, 'Duration:'),
        localTips: extractLocalTips(lines),
      };
      destinations.push(destination);
    } else if (section.startsWith('GENERAL TIPS:')) {
      const tips = section.replace('GENERAL TIPS:', '').split('\n');
      personalizedTips.push(
        ...tips.map((tip) => tip.trim()).filter(tip => tip.length > 0)
      );
    } else if (section.startsWith('TRAVEL STYLE:')) {
      travelStyle = section.replace('TRAVEL STYLE:', '').trim();
    }
  });

  // Ensure we have valid data
  if (!destinations.length) {
    destinations.push({
      name: 'Error loading destination',
      description: 'Please try again',
      activities: ['No activities available'],
      estimatedBudget: 'Not available',
      bestTimeToVisit: 'Not available',
      accommodation: 'Not available',
      recommendedDuration: 'Not available',
      localTips: ['No tips available'],
    });
  }

  if (!personalizedTips.length) {
    personalizedTips.push('No travel tips available');
  }

  return {
    destinations,
    personalizedTips,
    travelStyle: travelStyle || 'Custom Travel Experience',
  };
}