import { LightbulbIcon } from 'lucide-react';

interface DestinationTipsProps {
  tips: string[];
}

export default function DestinationTips({ tips }: DestinationTipsProps) {
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-2">
        <LightbulbIcon className="h-5 w-5 text-yellow-400" />
        <h4 className="font-medium text-white">Local Tips</h4>
      </div>
      <div className="grid gap-3">
        {tips.map((tip, index) => (
          <div
            key={index}
            className="group relative overflow-hidden rounded-lg"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-yellow-500 to-amber-500 opacity-0 group-hover:opacity-10 transition-opacity duration-300" />
            <div className="relative flex items-start gap-3 p-3 bg-white/5 rounded-lg hover:bg-white/10 transition-colors">
              <span className="h-2 w-2 rounded-full bg-yellow-400 mt-2 group-hover:scale-125 transition-transform" />
              <span className="text-gray-300 group-hover:text-white transition-colors">
                {tip}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}