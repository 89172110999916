import { motion } from 'framer-motion';
import { Filter } from 'lucide-react';
import type { ICategory } from '../../types/blog';

interface CategoryFilterProps {
  categories: ICategory[];
  selectedCategory: string | null;
  onSelectCategory: (categorySlug: string | null) => void;
}

export default function CategoryFilter({
  categories,
  selectedCategory,
  onSelectCategory
}: CategoryFilterProps) {
  return (
    <div className="flex items-center gap-3 overflow-x-auto pb-2 scrollbar-thin scrollbar-thumb-accent-500/20 scrollbar-track-transparent">
      <div className="flex items-center gap-2 text-gray-400 flex-shrink-0">
        <Filter className="h-5 w-5" />
        <span className="text-sm">Filter:</span>
      </div>
      <motion.button
        onClick={() => onSelectCategory(null)}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        className={`
          px-4 py-2 rounded-lg text-sm font-medium whitespace-nowrap transition-colors
          ${!selectedCategory
            ? 'bg-accent-500 text-white'
            : 'bg-white/5 text-gray-300 hover:bg-white/10'
          }
        `}
      >
        All
      </motion.button>
      {categories.map((category) => (
        <motion.button
          key={category.id}
          onClick={() => onSelectCategory(category.slug)}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className={`
            px-4 py-2 rounded-lg text-sm font-medium whitespace-nowrap transition-colors
            ${selectedCategory === category.slug
              ? 'bg-accent-500 text-white'
              : 'bg-white/5 text-gray-300 hover:bg-white/10'
            }
          `}
        >
          {category.title}
        </motion.button>
      ))}
    </div>
  );
}