import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import DealCard from './DealCard';
import type { Deal } from '../../../../types/agency';

interface DealColumnProps {
  id: string;
  title: string;
  deals: Deal[];
  color: string;
}

export default function DealColumn({ id, title, deals, color }: DealColumnProps) {
  const { setNodeRef, isOver } = useDroppable({ 
    id,
    data: {
      type: 'column',
      accepts: ['deal']
    }
  });

  return (
    <div 
      ref={setNodeRef}
      className={`
        bg-gray-50 rounded-lg p-4 transition-colors duration-200
        ${isOver ? 'bg-gray-100 ring-2 ring-accent-500/20' : ''}
      `}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2">
          <div className={`w-3 h-3 rounded-full ${color}`} />
          <h3 className="font-medium text-gray-900">{title}</h3>
        </div>
        <div className="text-sm text-gray-500">
          {deals.length} deals
        </div>
      </div>

      <SortableContext 
        items={deals.map(deal => deal.id)}
        strategy={verticalListSortingStrategy}
      >
        <div className="space-y-3">
          {deals.map((deal) => (
            <DealCard key={deal.id} deal={deal} />
          ))}
        </div>
      </SortableContext>
    </div>
  );
}