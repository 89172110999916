import { useState } from 'react';
import { Utensils, X } from 'lucide-react';
import { searchDining } from '../../services/dining';
import type { DiningResult } from '../../types/dining';
import DiningResults from '../DiningResults';
import StarRating from '../ui/StarRating';

interface DiningSearchProps {
  destination: string;
  onClose: () => void;
  suggestedDates: {
    start: string;
    end: string;
    reason: string;
  };
}

export default function DiningSearch({
  destination,
  onClose
}: DiningSearchProps) {
  const [searchQuery, setSearchQuery] = useState('');
  const [starRating, setStarRating] = useState(0);
  const [results, setResults] = useState<DiningResult[] | null>(null);
  const [isSearching, setIsSearching] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setError('Please enter a search term');
      return;
    }

    setIsSearching(true);
    setError(null);

    try {
      const searchResults = await searchDining(searchQuery, destination, starRating || undefined);
      setResults(searchResults);
    } catch (error) {
      console.error('Error searching dining options:', error);
      setError('Unable to find dining options. Please try again.');
    } finally {
      setIsSearching(false);
    }
  };

  return (
    <div className="p-4 rounded-xl bg-gray-800/50 border border-accent-500/20 space-y-4 mb-6">
      <div className="flex justify-between items-center">
        <h4 className="text-lg font-semibold text-white">Find Dining</h4>
        <button
          onClick={onClose}
          className="p-2 text-gray-400 hover:text-white transition-colors rounded-lg hover:bg-white/10"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      <div className="space-y-4">
        {/* Search Input */}
        <div className="relative">
          <div className="flex items-center pl-12 pr-4 py-3 bg-white/10 border border-white/20 rounded-xl text-white focus-within:ring-2 focus-within:ring-accent-400 transition-all">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
              placeholder="What would you like to eat?"
              className="flex-1 bg-transparent placeholder-gray-300 focus:outline-none"
            />
          </div>
          <Utensils className="absolute left-3 top-3 h-6 w-6 text-gray-400" />
        </div>

        {/* Star Rating Filter */}
        <div className="flex items-center gap-3 px-4 py-3 bg-white/10 border border-white/20 rounded-xl">
          <span className="text-gray-300">Optional Rating Filter:</span>
          <StarRating
            value={starRating}
            onChange={setStarRating}
            size="md"
          />
          {starRating > 0 && (
            <button
              onClick={() => setStarRating(0)}
              className="ml-2 text-xs text-accent-400 hover:text-accent-300"
            >
              Clear
            </button>
          )}
        </div>

        {/* Search Button */}
        <button
          onClick={handleSearch}
          disabled={!searchQuery.trim() || isSearching}
          className={`
            w-full py-3 px-4 rounded-lg font-medium transition-all duration-200
            ${searchQuery.trim() && !isSearching
              ? 'bg-accent-500 hover:bg-accent-600 text-white'
              : 'bg-gray-700 text-gray-400 cursor-not-allowed'
            }
          `}
        >
          Search Restaurants
        </button>
      </div>

      {/* Results */}
      {(isSearching || results || error) && (
        <DiningResults
          results={results || []}
          isLoading={isSearching}
          error={error}
          destinationName={destination}
        />
      )}
    </div>
  );
}