import { motion } from 'framer-motion';
import { X, Check, Calendar, Bell, Users, Clock } from 'lucide-react';
import { usePaywall } from '../../contexts/PaywallContext';

export default function PaywallModal() {
  const { showPaywall, setShowPaywall } = usePaywall();

  if (!showPaywall) return null;

  const features = [
    {
      icon: Calendar,
      title: 'Advanced Scheduling',
      description: 'Minute-by-minute trip planning with visual timeline'
    },
    {
      icon: Bell,
      title: 'Group Alerts',
      description: 'Keep everyone in sync with real-time notifications'
    },
    {
      icon: Users,
      title: 'Group Planning',
      description: 'Collaborate with travel companions in real-time'
    },
    {
      icon: Clock,
      title: 'Smart Timing',
      description: 'AI-powered scheduling suggestions and optimization'
    }
  ];

  return (
    <div className="fixed inset-0 z-[9999]">
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm" onClick={() => setShowPaywall(false)} />
      
      <div className="fixed inset-0 pointer-events-none flex items-center justify-center p-4">
        <motion.div 
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          className="relative w-full max-w-lg bg-gray-900 rounded-2xl shadow-xl pointer-events-auto border border-white/10"
        >
          {/* Close Button */}
          <button
            onClick={() => setShowPaywall(false)}
            className="absolute top-4 right-4 p-2 text-gray-400 hover:text-white rounded-lg transition-colors"
          >
            <X className="h-5 w-5" />
          </button>

          {/* Content */}
          <div className="p-6">
            <h3 className="text-2xl font-bold text-white text-center mb-2">
              Upgrade to Pro
            </h3>
            <p className="text-gray-300 text-center mb-6">
              Access our fully featured trip planner and take your travel planning to the next level
            </p>

            {/* Features */}
            <div className="space-y-4 mb-6">
              {features.map((feature) => (
                <div key={feature.title} className="flex items-start gap-3">
                  <div className="p-2 rounded-lg bg-accent-500/10">
                    <feature.icon className="h-5 w-5 text-accent-400" />
                  </div>
                  <div>
                    <h4 className="font-medium text-white">{feature.title}</h4>
                    <p className="text-sm text-gray-400">{feature.description}</p>
                  </div>
                </div>
              ))}
            </div>

            {/* Pricing */}
            <div className="bg-white/5 rounded-xl p-6 mb-6">
              <div className="flex justify-center gap-8">
                <div className="text-center">
                  <div className="text-2xl font-bold text-white">$4.99</div>
                  <div className="text-sm text-gray-400">per month</div>
                </div>
                <div className="text-center">
                  <div className="text-2xl font-bold text-white">$47</div>
                  <div className="text-sm text-gray-400">per year</div>
                  <div className="text-xs text-accent-400">Save 21%</div>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="space-y-3">
              <button className="w-full py-3 bg-accent-500 hover:bg-accent-600 text-white rounded-xl font-medium transition-colors">
                Start Free Trial
              </button>
              <button 
                onClick={() => setShowPaywall(false)}
                className="w-full py-3 bg-white/5 hover:bg-white/10 text-gray-300 rounded-xl font-medium transition-colors"
              >
                Maybe Later
              </button>
            </div>

            {/* Footer */}
            <p className="text-xs text-gray-400 text-center mt-4">
              7-day free trial • Cancel anytime • Money-back guarantee
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
}