import { useDraggable } from '@dnd-kit/core';
import { Activity, Utensils, MapPin, Clock, DollarSign, ExternalLink, Award, ChevronDown } from 'lucide-react';
import StarRating from '../ui/StarRating';
import { useState } from 'react';

interface Link {
  title: string;
  url: string;
  _id: string;
}

interface DraggableItemProps {
  id: string;
  type: 'activity' | 'dining';
  title: string;
  description?: string;
  duration?: string;
  location?: string;
  rating?: number;
  price?: string;
  links?: Link[];
  specialties?: string[];
}

export default function DraggableItem({
  id,
  type,
  title,
  description,
  duration,
  location,
  rating,
  price,
  links,
  specialties
}: DraggableItemProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: `${type}-${id}`,
    data: {
      type,
      title,
      description,
      duration,
      location,
      rating,
      price,
      links,
      specialties
    }
  });

  const Icon = type === 'activity' ? Activity : Utensils;
  const colorClass = type === 'activity' 
    ? 'text-emerald-400 bg-emerald-500/10'
    : 'text-orange-400 bg-orange-500/10';

  const hasExpandableContent = description || (type === 'dining' && specialties?.length > 0) || links?.length > 0;

  return (
    <div
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      className={`
        group relative flex items-start gap-2 sm:gap-3 p-3 sm:p-4 bg-white/5 rounded-lg 
        hover:bg-white/10 transition-colors cursor-move
        border border-white/10 hover:border-accent-500/30
        ${isDragging ? 'opacity-50' : 'opacity-100'}
      `}
      style={{
        transform: isDragging ? 'scale(1.05)' : undefined,
        transition: 'transform 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22)'
      }}
    >
      <div className={`p-1.5 sm:p-2 rounded-lg ${colorClass}`}>
        <Icon className="h-3 w-3 sm:h-4 sm:w-4" />
      </div>
      <div className="flex-1 min-w-0">
        <div className="flex items-center justify-between gap-2 mb-1">
          <div className="flex items-center gap-1.5">
            <h4 className="font-medium text-white text-sm sm:text-base line-clamp-2">{title}</h4>
            {type === 'activity' && price && (
              <div className="inline-flex items-center gap-1 px-1.5 sm:px-2 py-0.5 bg-emerald-500/10 text-emerald-400 rounded text-xs sm:text-sm">
                ~
                <DollarSign className="h-3 w-3 sm:h-4 sm:w-4" />
                <span>
                  {(() => {
                    const [min, max] = price.split('-').map(p => parseInt(p.replace(/\D/g, '')));
                    return Math.round((min + max) / 2);
                  })()}
                </span>
              </div>
            )}
          </div>
          {type === 'dining' && typeof rating === 'number' && (
            <StarRating value={rating} readonly size="sm" onChange={() => {}} />
          )}
        </div>
        
        <div className="flex items-center gap-3 sm:gap-4 text-xs sm:text-sm text-gray-400">
          {duration && (
            <div className="flex items-center gap-1 sm:gap-1.5">
              <Clock className="h-3 w-3 sm:h-4 sm:w-4" />
              <span className="line-clamp-1">{duration}</span>
            </div>
          )}
          {location && (
            <div className="flex items-center gap-1 sm:gap-1.5">
              <MapPin className="h-3 w-3 sm:h-4 sm:w-4" />
              <span>{location}</span>
            </div>
          )}
        </div>

        {hasExpandableContent && (
          <button 
            onClick={(e) => {
              e.preventDefault();
              setIsExpanded(!isExpanded);
            }}
            className="mt-2 flex items-center gap-1 text-xs sm:text-sm text-gray-400 hover:text-gray-300 transition-colors"
          >
            <ChevronDown className={`h-3 w-3 sm:h-4 sm:w-4 transition-transform ${isExpanded ? 'rotate-180' : ''}`} />
            {isExpanded ? 'Show less' : 'Show more'}
          </button>
        )}

        {isExpanded && (
          <div className="mt-2 space-y-2 sm:space-y-3">
            {description && (
              <p className="text-xs sm:text-sm text-gray-300">{description}</p>
            )}
            {type === 'dining' && specialties && specialties.length > 0 && (
              <div className="space-y-1 sm:space-y-1.5">
                <div className="flex items-center gap-1 sm:gap-1.5 text-xs sm:text-sm text-gray-400">
                  <Award className="h-3 w-3 sm:h-4 sm:w-4" />
                  <span className="font-medium">Known For</span>
                </div>
                <div className="flex flex-wrap gap-1 sm:gap-1.5">
                  {specialties.map((specialty, index) => (
                    <span 
                      key={index}
                      className="inline-flex px-1.5 sm:px-2 py-0.5 bg-orange-500/10 text-orange-400 rounded text-[10px] sm:text-xs font-medium"
                    >
                      {specialty}
                    </span>
                  ))}
                </div>
              </div>
            )}
            {links && links.length > 0 && (
              <div className="flex flex-wrap gap-1.5 sm:gap-2">
                {links.map((link, index) => {
                  const colors = [
                    'bg-emerald-500/10 hover:bg-emerald-500/20 text-emerald-400 hover:text-emerald-300',
                    'bg-blue-500/10 hover:bg-blue-500/20 text-blue-400 hover:text-blue-300',
                    'bg-purple-500/10 hover:bg-purple-500/20 text-purple-400 hover:text-purple-300',
                    'bg-amber-500/10 hover:bg-amber-500/20 text-amber-400 hover:text-amber-300'
                  ];
                  const colorClass = colors[index % colors.length];
                  
                  return (
                    <a
                      key={link._id}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={`inline-flex items-center gap-1 sm:gap-1.5 px-1.5 sm:px-2 py-0.5 sm:py-1 text-[10px] sm:text-xs rounded-md ${colorClass} transition-colors`}
                    >
                      <ExternalLink className="h-2.5 w-2.5 sm:h-3 sm:w-3" />
                      {link.title}
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}