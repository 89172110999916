import { useEffect, useRef } from 'react';
import { Users, Building2, Calendar, BarChart3, Settings, Boxes } from 'lucide-react';
import anime from 'animejs';
import DemoCard from './DemoCard';
import DataVisualizer from './DataVisualizer';

const features = [
  {
    icon: Users,
    title: 'Team Management',
    description: 'Manage unlimited team members with role-based access control and detailed activity logs.'
  },
  {
    icon: Building2,
    title: 'Multi-Branch Support',
    description: 'Coordinate between multiple locations with centralized data and branch-specific reporting.'
  },
  {
    icon: Calendar,
    title: 'Advanced Scheduling',
    description: 'Smart scheduling system with conflict resolution and resource allocation.'
  },
  {
    icon: BarChart3,
    title: 'Analytics Dashboard',
    description: 'Real-time insights with customizable KPIs and automated reporting.'
  },
  {
    icon: Settings,
    title: 'Custom Workflows',
    description: 'Design and automate complex business processes with our visual workflow builder.'
  },
  {
    icon: Boxes,
    title: 'Resource Management',
    description: 'Track and optimize resource allocation across all your travel operations.'
  }
];

export default function EnterpriseDemo() {
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (titleRef.current) {
      const letters = titleRef.current.textContent!.split('');
      titleRef.current.innerHTML = letters
        .map(letter => `<span class="letter inline-block">${letter}</span>`)
        .join('');

      anime.timeline({ loop: true })
        .add({
          targets: '.letter',
          translateY: [-100, 0],
          opacity: [0, 1],
          easing: "easeOutExpo",
          duration: 1400,
          delay: (el, i) => 30 * i
        })
        .add({
          targets: '.letter',
          translateY: [0, 100],
          opacity: [1, 0],
          easing: "easeInExpo",
          duration: 1200,
          delay: (el, i) => 30 * i
        });
    }

    const scrollAnimation = anime({
      targets: containerRef.current,
      translateX: [-50, 0],
      opacity: [0, 1],
      duration: 1000,
      easing: 'easeOutQuad',
      autoplay: false
    });

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          scrollAnimation.play();
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div id="enterprise-demo" className="py-24 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 
            ref={titleRef}
            className="text-4xl font-bold mb-4 overflow-hidden"
          >
            Enterprise-Grade Travel Management
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Powerful tools for travel agencies and enterprise organizations
          </p>
        </div>

        <div ref={containerRef} className="space-y-16">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <DemoCard
                key={feature.title}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
                delay={index * 100}
              />
            ))}
          </div>

          <div className="bg-white border-4 border-black p-8 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
            <h3 className="text-2xl font-bold mb-6">Real-Time Analytics</h3>
            <DataVisualizer />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-white border-4 border-black p-8 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
              <h3 className="text-2xl font-bold mb-4">Custom Integration</h3>
              <div className="space-y-4">
                <div className="h-8 bg-gray-200 rounded-full w-3/4 animate-pulse" />
                <div className="h-8 bg-gray-200 rounded-full w-1/2 animate-pulse" />
                <div className="h-8 bg-gray-200 rounded-full w-2/3 animate-pulse" />
              </div>
            </div>

            <div className="bg-white border-4 border-black p-8 shadow-[8px_8px_0px_0px_rgba(0,0,0,1)]">
              <h3 className="text-2xl font-bold mb-4">API Documentation</h3>
              <div className="font-mono text-sm bg-gray-100 p-4 rounded">
                <div className="text-blue-600">GET /api/v1/bookings</div>
                <div className="text-gray-500">Authorization: Bearer {'{token}'}</div>
                <div className="text-gray-500">Content-Type: application/json</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}