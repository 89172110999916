import { useEffect, useRef } from 'react';
import anime from 'animejs';

const generateRandomData = (points: number) => {
  return Array.from({ length: points }, () => Math.random() * 100);
};

export default function DataVisualizer() {
  const chartRef = useRef<HTMLDivElement>(null);
  const data = generateRandomData(12);
  const maxValue = Math.max(...data);

  useEffect(() => {
    if (chartRef.current) {
      const bars = chartRef.current.querySelectorAll('.bar');
      
      anime({
        targets: bars,
        height: (el: Element) => el.getAttribute('data-height'),
        delay: anime.stagger(100),
        duration: 1500,
        easing: 'easeOutElastic(1, .8)',
        loop: true,
        direction: 'alternate'
      });
    }
  }, []);

  return (
    <div ref={chartRef} className="flex items-end justify-between h-40 gap-2 p-4 bg-white border-4 border-black">
      {data.map((value, index) => (
        <div
          key={index}
          className="bar w-full bg-accent-500 transition-all duration-300 hover:bg-accent-600"
          style={{ height: 0 }}
          data-height={`${(value / maxValue) * 100}%`}
        />
      ))}
    </div>
  );
}