import { SurveyQuestion } from '../types/survey';

export const surveyQuestions: SurveyQuestion[] = [
  {
    id: 'travelerType',
    title: 'What type of traveler are you?',
    description: 'Choose all that match your travel personality',
    type: 'multiple',
    options: [
      { id: 'adventure', label: 'Adventure Seeker' },
      { id: 'culture', label: 'Culture Explorer' },
      { id: 'luxury', label: 'Luxury Traveler' },
      { id: 'budget', label: 'Budget Backpacker' },
      { id: 'foodie', label: 'Food Enthusiast' },
      { id: 'nature', label: 'Nature Lover' },
      { id: 'urban', label: 'Urban Explorer' },
      { id: 'relaxation', label: 'Relaxation Seeker' },
      { id: 'photography', label: 'Photo Enthusiast' },
      { id: 'eco', label: 'Eco-Conscious Traveler' },
      { id: 'history', label: 'History Buff' },
      { id: 'wellness', label: 'Wellness Seeker' }
    ]
  },
  {
    id: 'tripTiming',
    title: 'How soon is your trip?',
    description: 'Help us find the best timing and deals',
    type: 'single',
    options: [
      { id: 'within-month', label: 'Within a month' },
      { id: '1-3-months', label: '1-3 months away' },
      { id: '3-6-months', label: '3-6 months away' },
      { id: '6-plus-months', label: '6+ months away' },
      { id: 'browsing', label: 'Just browsing' }
    ]
  },
  {
    id: 'tripDuration',
    title: 'How long is your trip?',
    description: 'This helps us plan the right amount of activities and destinations',
    type: 'single',
    options: [
      { id: 'weekend', label: 'Weekend Getaway (2-3 days)' },
      { id: 'short', label: 'Short Trip (4-6 days)' },
      { id: 'week', label: 'One Week' },
      { id: 'two-weeks', label: 'Two Weeks' },
      { id: 'long', label: 'Extended Trip (2+ weeks)' }
    ]
  },
  {
    id: 'budget',
    title: 'What\'s your budget range?',
    description: 'Total budget per person, including accommodations and activities',
    type: 'single',
    options: [
      { id: 'budget-1500', label: 'Under $1,500' },
      { id: 'budget-3000', label: '$1,500 - $3,000' },
      { id: 'budget-5000', label: '$3,000 - $5,000' },
      { id: 'budget-10000', label: '$5,000 - $10,000' },
      { id: 'budget-25000', label: '$10,000 - $25,000' },
      { id: 'budget-50000', label: '$25,000 - $50,000' },
      { id: 'budget-100000', label: '$50,000 - $100,000' },
      { id: 'budget-100000-plus', label: '$100,000+' }
    ]
  },
  {
    id: 'companions',
    title: 'Who are you traveling with?',
    description: 'This helps us recommend suitable destinations and activities',
    type: 'single',
    options: [
      { id: 'solo', label: 'Solo Adventure' },
      { id: 'couple', label: 'Romantic Getaway' },
      { id: 'family', label: 'Family with Kids' },
      { id: 'friends', label: 'Friend Group' },
      { id: 'business', label: 'Business Trip' }
    ]
  },
  {
    id: 'accommodation',
    title: 'What\'s your preferred accommodation style?',
    description: 'Choose your ideal place to stay',
    type: 'single',
    options: [
      { id: 'luxury', label: 'Luxury Resort' },
      { id: 'boutique', label: 'Boutique Hotel' },
      { id: 'vacation-rental', label: 'Vacation Rental' },
      { id: 'hostel', label: 'Hostel/Backpacker' },
      { id: 'unique', label: 'Unique Stay (Treehouse, Glamping, etc.)' }
    ]
  },
  {
    id: 'experiences',
    title: 'Must-Have Experiences',
    description: 'Select all that interest you',
    type: 'multiple',
    options: [
      { id: 'food', label: 'Local Food & Dining' },
      { id: 'history', label: 'Historical Sites' },
      { id: 'outdoor', label: 'Outdoor Activities' },
      { id: 'shopping', label: 'Shopping & Markets' },
      { id: 'nightlife', label: 'Nightlife & Events' },
      { id: 'wellness', label: 'Relaxation & Wellness' },
      { id: 'photography', label: 'Photo Spots' },
      { id: 'events', label: 'Local Events & Festivals' }
    ]
  },
  {
    id: 'pace',
    title: 'What\'s your ideal travel pace?',
    description: 'How do you like to structure your trips?',
    type: 'single',
    options: [
      { id: 'relaxed', label: 'Relaxed & Easy' },
      { id: 'balanced', label: 'Balanced Mix' },
      { id: 'fast', label: 'Fast & Full' },
      { id: 'spontaneous', label: 'Spontaneous' }
    ]
  },
  {
    id: 'climate',
    title: 'Preferred Climate',
    description: 'What weather do you enjoy most while traveling?',
    type: 'single',
    options: [
      { id: 'tropical', label: 'Tropical & Warm' },
      { id: 'mild', label: 'Mild & Temperate' },
      { id: 'cool', label: 'Cool & Crisp' },
      { id: 'cold', label: 'Cold & Snowy' }
    ]
  }
];