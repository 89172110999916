import OpenAI from 'openai';

const openai = new OpenAI({
    apiKey: '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',
  dangerouslyAllowBrowser: true,
});

interface ActivityResult {
  title: string;
  description: string;
  images: string[];
  links: Array<{
    title: string;
    url: string;
  }>;
  price?: string;
  duration?: string;
}
export async function searchActivityDetails(
  activity: string,
  destination: string
): Promise<ActivityResult[]> {
  const prompt = `Find detailed information about "${activity}" in ${destination}.
For each option, provide:
1. A descriptive title that captures the unique aspect of this variation
2. A compelling description (2-3 sentences) highlighting what makes this option special
3. Three relevant images that showcase this activity (DO NOT USE UNSPLASH IMAGES):
   - Use images from Pexels API with format: https://images.pexels.com/photos/{photo-id}/pexels-photo-{photo-id}.jpeg
   - Search for relevant keywords combining activity type, location and descriptive terms
   - Select high-quality, well-composed images that authentically represent the experience
   - IMPORTANT: Make sure the images are actually showing the specific activity being described, not just generic location photos
   - For example, if describing "surfing", the images should show people surfing, not just beach scenes
4. At least 3 relevant links for booking or information:
   - For general activities, include links from:
     - TripAdvisor (format: https://www.tripadvisor.com/Search?q={activity}+{destination})
     - Viator (format: https://www.viator.com/search/{destination}?q={activity})
     - GetYourGuide (format: https://www.getyourguide.com/s?q={activity}+{destination})
   - For sports events, concerts, or shows, also include links from:
     - Ticketmaster (format: https://www.ticketmaster.com/search?q={activity}+{destination})
     - StubHub (format: https://www.stubhub.com/search?q={activity}+{destination})
   - Replace spaces with + in the search terms
   - If exact format links cannot be found, provide the closest matching available link from that platform
5. Approximate price range with currency (e.g. "$50-100 USD", "€75-150")
6. Typical duration including any seasonal variations

Provide 3 distinct variations of this activity, each offering a unique perspective or experience.

Format the response as valid JSON with this structure:
{
  "results": [
    {
      "title": "string",
      "description": "string", 
      "images": ["url1", "url2", "url3"],
      "links": [
        {"title": "string", "url": "string"}
      ],
      "price": "string (optional)",
      "duration": "string (optional)"
    }
  ]
}`;

  try {
    const completion = await openai.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4o',
      temperature: 1.0,
      response_format: { type: "json_object" },
    });

    const response = completion.choices[0].message.content;
    if (!response) throw new Error('No response from AI');

    const data = JSON.parse(response);
    return data.results;
  } catch (error) {
    console.error('Error searching activity details:', error);
    throw new Error('Failed to search activity details');
  }
}
