import { Check } from 'lucide-react';

interface MultipleSelectIndicatorProps {
  isSelected: boolean;
}

export default function MultipleSelectIndicator({ isSelected }: MultipleSelectIndicatorProps) {
  return (
    <div className={`
      flex items-center justify-center
      w-5 h-5 rounded-md border-2
      transition-all duration-200
      ${isSelected 
        ? 'border-accent-500 bg-accent-500' 
        : 'border-white/20 bg-white/5'}
    `}>
      {isSelected && <Check className="h-3 w-3 text-white" />}
    </div>
  );
}