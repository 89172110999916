import { useEffect, useRef } from 'react';
import { DollarSign, TrendingUp, Users, Globe } from 'lucide-react';
import anime from 'animejs';

const stats = [
  { icon: DollarSign, label: 'Revenue', value: '$245,000', change: '+12%' },
  { icon: TrendingUp, label: 'Bookings', value: '1,234', change: '+8%' },
  { icon: Users, label: 'Active Clients', value: '456', change: '+15%' },
  { icon: Globe, label: 'Destinations', value: '89', change: '+5%' }
];

export default function Analytics() {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartRef.current) {
      const bars = chartRef.current.querySelectorAll('.bar');
      
      anime({
        targets: bars,
        height: (el: Element) => el.getAttribute('data-height'),
        delay: anime.stagger(100),
        duration: 1500,
        easing: 'easeOutElastic(1, .8)'
      });
    }
  }, []);

  return (
    <div className="space-y-4 sm:space-y-8 px-4 sm:px-0">
      {/* Stats Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
        {stats.map((stat) => (
          <div
            key={stat.label}
            className="bg-white border-2 sm:border-4 border-black p-4 sm:p-6 shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] sm:shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]"
          >
            <div className="flex items-center gap-2 sm:gap-3 mb-3 sm:mb-4">
              <div className="p-1.5 sm:p-2 bg-black">
                <stat.icon className="h-4 w-4 sm:h-6 sm:w-6 text-white" />
              </div>
              <div className="text-xs sm:text-sm font-bold">{stat.label}</div>
            </div>
            <div className="text-xl sm:text-2xl font-bold">{stat.value}</div>
            <div className="text-xs sm:text-sm text-emerald-500 font-bold mt-1 sm:mt-2">
              {stat.change}
            </div>
          </div>
        ))}
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6">
        <div className="bg-white border-2 sm:border-4 border-black p-4 sm:p-6 shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] sm:shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
          <h3 className="text-lg sm:text-xl font-bold mb-4 sm:mb-6">Revenue by Month</h3>
          <div ref={chartRef} className="h-48 sm:h-64 flex items-end gap-1 sm:gap-2">
            {Array.from({ length: 12 }).map((_, i) => (
              <div
                key={i}
                className="bar flex-1 bg-accent-500 transition-all duration-300 hover:bg-accent-600"
                data-height={`${Math.random() * 100}%`}
                style={{ height: 0 }}
              />
            ))}
          </div>
        </div>

        <div className="bg-white border-2 sm:border-4 border-black p-4 sm:p-6 shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] sm:shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
          <h3 className="text-lg sm:text-xl font-bold mb-4 sm:mb-6">Top Destinations</h3>
          <div className="space-y-3 sm:space-y-4">
            {['Paris', 'Tokyo', 'New York', 'London'].map((city) => (
              <div key={city} className="flex items-center gap-2 sm:gap-4">
                <div className="flex-1">
                  <div className="text-sm sm:text-base font-bold">{city}</div>
                  <div className="text-xs sm:text-sm text-gray-500">
                    {Math.floor(Math.random() * 100)} bookings
                  </div>
                </div>
                <div className="w-24 sm:w-32 h-1.5 sm:h-2 bg-gray-200 rounded-full overflow-hidden">
                  <div
                    className="h-full bg-accent-500"
                    style={{ width: `${Math.random() * 100}%` }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}