import CategoryFilter from './CategoryFilter';

interface BlogFiltersProps {
  selectedCategory: string | null;
  onSelectCategory: (category: string | null) => void;
}

export default function BlogFilters({ selectedCategory, onSelectCategory }: BlogFiltersProps) {
  return (
    <div className="flex justify-center mb-12">
      <CategoryFilter
        categories={[]} // You'll need to fetch categories
        selectedCategory={selectedCategory}
        onSelectCategory={onSelectCategory}
      />
    </div>
  );
}