import { useEffect, useState } from 'react';
import { MapPin, ArrowRight } from 'lucide-react';
import { getNextTravelImage, type TravelImage } from '../services/images';

interface BackgroundImageProps {
  interval?: number;
  children: React.ReactNode;
}

export default function BackgroundImage({
  interval = 10000,
  children,
}: BackgroundImageProps) {
  const [currentImage, setCurrentImage] = useState<TravelImage | null>(null);
  const [nextImage, setNextImage] = useState<TravelImage | null>(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    setCurrentImage(getNextTravelImage());
  }, []);

  useEffect(() => {
    const loadNextImage = () => {
      setNextImage(getNextTravelImage());
    };

    const timer = setInterval(() => {
      if (nextImage) {
        setIsTransitioning(true);
        setTimeout(() => {
          setCurrentImage(nextImage);
          setNextImage(null);
          setIsTransitioning(false);
          loadNextImage();
        }, 1000);
      } else {
        loadNextImage();
      }
    }, interval);

    return () => clearInterval(timer);
  }, [nextImage, interval]);

  if (!currentImage) return null;

  return (
    <div className="relative">
      <div
        className={`absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ${
          isTransitioning ? 'opacity-0' : 'opacity-100'
        }`}
        style={{
          backgroundImage: `url(${currentImage.url})`,
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      </div>
      {nextImage && (
        <div
          className={`absolute inset-0 bg-cover bg-center transition-opacity duration-1000 ${
            isTransitioning ? 'opacity-100' : 'opacity-0'
          }`}
          style={{
            backgroundImage: `url(${nextImage.url})`,
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        </div>
      )}
      <div className="absolute bottom-4 right-4">
        <a
          href={`/destinations/${currentImage.location.toLowerCase().replace(/,?\s+/g, '-')}`}
          className="group flex items-center space-x-2 bg-white/10 hover:bg-white/20 backdrop-blur-md px-4 py-2 rounded-full text-white transition-all duration-300 hover:scale-105"
        >
          <MapPin className="h-4 w-4" />
          <span className="font-medium">{currentImage.location}</span>
          <ArrowRight className="h-4 w-4 transform group-hover:translate-x-1 transition-transform duration-300" />
        </a>
      </div>
      {children}
    </div>
  );
}