import { Calendar, Clock, Tag } from 'lucide-react';
import { format, parseISO } from 'date-fns';
import type { IArticle } from '../../types/blog';

interface ArticleHeaderProps {
  article: IArticle;
}

export default function ArticleHeader({ article }: ArticleHeaderProps) {
  const formatDate = (dateString: string) => {
    try {
      return format(parseISO(dateString), 'MMMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date unavailable';
    }
  };

  return (
    <header>
      {/* Category */}
      <div className="inline-block px-3 py-1 bg-accent-500/10 text-accent-400 rounded-full text-sm font-medium mb-4">
        {article.category.title}
      </div>

      {/* Title */}
      <h1 className="text-4xl font-bold text-white mb-4">
        {article.headline}
      </h1>

      {/* Meta Info */}
      <div className="flex flex-wrap items-center gap-4 text-sm text-gray-400">
        <div className="flex items-center gap-1.5">
          <Calendar className="h-4 w-4" />
          <time dateTime={article.publishedAt}>
            {formatDate(article.publishedAt)}
          </time>
        </div>
        <div className="flex items-center gap-1.5">
          <Clock className="h-4 w-4" />
          <span>{article.readingTime} min read</span>
        </div>
        {article.tags.length > 0 && (
          <div className="flex items-center gap-1.5">
            <Tag className="h-4 w-4" />
            <div className="flex items-center gap-2">
              {article.tags.map(tag => (
                <span key={tag.id}>{tag.title}</span>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Featured Image */}
      {article.image && (
        <div className="mt-8 aspect-[21/9] rounded-2xl overflow-hidden">
          <img
            src={article.image}
            alt={article.headline}
            className="w-full h-full object-cover"
          />
        </div>
      )}
    </header>
  );
}