interface TravelImage {
  id: string;
  url: string;
  location: string;
  region: string;
  photographer: string;
  description: string;
}

const travelImages1: TravelImage[] = [
  // Europe
  {
    id: 'europe-1',
    url: 'https://images.pexels.com/photos/1271619/pexels-photo-1271619.jpeg',
    location: 'Santorini, Greece',
    region: 'Europe',
    photographer: 'Pexels',
    description: 'Iconic white buildings and blue domes overlooking the Aegean Sea'
  },
  {
    id: 'europe-2',
    url: 'https://images.pexels.com/photos/1591373/pexels-photo-1591373.jpeg',
    location: 'Venice, Italy',
    region: 'Europe',
    photographer: 'Pexels',
    description: 'Historic canals and gondolas in the heart of Venice'
  },
  {
    id: 'europe-3',
    url: 'https://images.pexels.com/photos/2422461/pexels-photo-2422461.jpeg',
    location: 'Paris, France',
    region: 'Europe',
    photographer: 'Pexels',
    description: 'Eiffel Tower at sunset with dramatic sky'
  },
  {
    id: 'europe-4',
    url: 'https://images.pexels.com/photos/3889742/pexels-photo-3889742.jpeg',
    location: 'Swiss Alps',
    region: 'Europe',
    photographer: 'Pexels',
    description: 'Snow-capped mountains and pristine alpine lakes'
  },

  // Asia
  {
    id: 'asia-1',
    url: 'https://images.pexels.com/photos/3225529/pexels-photo-3225529.jpeg',
    location: 'Tokyo, Japan',
    region: 'Asia',
    photographer: 'Pexels',
    description: 'Modern cityscape with traditional temples'
  },
  {
    id: 'asia-2',
    url: 'https://images.pexels.com/photos/3225531/pexels-photo-3225531.jpeg',
    location: 'Bali, Indonesia',
    region: 'Asia',
    photographer: 'Pexels',
    description: 'Tropical paradise with ancient temples'
  },
  {
    id: 'asia-3',
    url: 'https://images.pexels.com/photos/2549018/pexels-photo-2549018.jpeg',
    location: 'Bangkok, Thailand',
    region: 'Asia',
    photographer: 'Pexels',
    description: 'Vibrant street life and ornate temples'
  },
  {
    id: 'asia-4',
    url: 'https://images.pexels.com/photos/2387873/pexels-photo-2387873.jpeg',
    location: 'Great Wall, China',
    region: 'Asia',
    photographer: 'Pexels',
    description: 'Ancient wonder stretching across mountains'
  },

  // Americas
  {
    id: 'americas-1',
    url: 'https://images.pexels.com/photos/1659438/pexels-photo-1659438.jpeg',
    location: 'Machu Picchu, Peru',
    region: 'Americas',
    photographer: 'Pexels',
    description: 'Ancient Incan citadel in the clouds'
  },
  {
    id: 'americas-2',
    url: 'https://images.pexels.com/photos/2224424/pexels-photo-2224424.jpeg',
    location: 'New York City, USA',
    region: 'Americas',
    photographer: 'Pexels',
    description: 'Manhattan skyline at sunset'
  },
  {
    id: 'americas-3',
    url: 'https://images.pexels.com/photos/2901209/pexels-photo-2901209.jpeg',
    location: 'Rio de Janeiro, Brazil',
    region: 'Americas',
    photographer: 'Pexels',
    description: 'Christ the Redeemer overlooking the bay'
  },
  {
    id: 'americas-4',
    url: 'https://images.pexels.com/photos/1486785/pexels-photo-1486785.jpeg',
    location: 'Banff National Park, Canada',
    region: 'Americas',
    photographer: 'Pexels',
    description: 'Pristine wilderness and turquoise lakes'
  },

  // Middle East
  {
    id: 'middle-east-1',
    url: 'https://images.pexels.com/photos/3225528/pexels-photo-3225528.jpeg',
    location: 'Dubai, UAE',
    region: 'Middle East',
    photographer: 'Pexels',
    description: 'Futuristic skyline and desert luxury'
  },
  {
    id: 'middle-east-2',
    url: 'https://images.pexels.com/photos/2403251/pexels-photo-2403251.jpeg',
    location: 'Petra, Jordan',
    region: 'Middle East',
    photographer: 'Pexels',
    description: 'Ancient rose-colored city carved into rock'
  },

  // Africa
  {
    id: 'africa-1',
    url: 'https://images.pexels.com/photos/4577793/pexels-photo-4577793.jpeg',
    location: 'Serengeti, Tanzania',
    region: 'Africa',
    photographer: 'Pexels',
    description: 'Vast savannas and wildlife migrations'
  },
  {
    id: 'africa-2',
    url: 'https://images.pexels.com/photos/1285625/pexels-photo-1285625.jpeg',
    location: 'Cape Town, South Africa',
    region: 'Africa',
    photographer: 'Pexels',
    description: 'Table Mountain and coastal beauty'
  },

  // Oceania
  {
    id: 'oceania-1',
    url: 'https://images.pexels.com/photos/2193300/pexels-photo-2193300.jpeg',
    location: 'Great Barrier Reef, Australia',
    region: 'Oceania',
    photographer: 'Pexels',
    description: 'World\'s largest coral reef system'
  },
  {
    id: 'oceania-2',
    url: 'https://images.pexels.com/photos/724963/pexels-photo-724963.jpeg',
    location: 'Queenstown, New Zealand',
    region: 'Oceania',
    photographer: 'Pexels',
    description: 'Adventure capital surrounded by mountains'
  }
];
const travelImages2: TravelImage[] = [
  // Europe (Continued)
  {
    id: 'europe-5',
    url: 'https://images.pexels.com/photos/338504/pexels-photo-338504.jpeg',
    location: 'Edinburgh, Scotland',
    region: 'Europe',
    photographer: 'Pexels',
    description: 'Historic Edinburgh Castle on Castle Rock'
  },
  {
    id: 'europe-6',
    url: 'https://images.pexels.com/photos/2570062/pexels-photo-2570062.jpeg',
    location: 'Amsterdam, Netherlands',
    region: 'Europe',
    photographer: 'Pexels',
    description: 'Canals and colorful houseboats in Amsterdam'
  },
  {
    id: 'europe-7',
    url: 'https://images.pexels.com/photos/4057788/pexels-photo-4057788.jpeg',
    location: 'Prague, Czech Republic',
    region: 'Europe',
    photographer: 'Pexels',
    description: 'Iconic Charles Bridge and Gothic spires'
  },
  {
    id: 'europe-8',
    url: 'https://images.pexels.com/photos/1007206/pexels-photo-1007206.jpeg',
    location: 'Barcelona, Spain',
    region: 'Europe',
    photographer: 'Pexels',
    description: 'Sagrada Familia and vibrant street life'
  },

  // Asia (Continued)
  {
    id: 'asia-5',
    url: 'https://images.pexels.com/photos/2387875/pexels-photo-2387875.jpeg',
    location: 'Kyoto, Japan',
    region: 'Asia',
    photographer: 'Pexels',
    description: 'Traditional temples and cherry blossoms'
  },
  {
    id: 'asia-6',
    url: 'https://images.pexels.com/photos/1610813/pexels-photo-1610813.jpeg',
    location: 'Angkor Wat, Cambodia',
    region: 'Asia',
    photographer: 'Pexels',
    description: 'Ancient temple complex surrounded by jungle'
  },
  {
    id: 'asia-7',
    url: 'https://images.pexels.com/photos/774123/pexels-photo-774123.jpeg',
    location: 'Maldives',
    region: 'Asia',
    photographer: 'Pexels',
    description: 'Overwater bungalows and crystal-clear water'
  },
  {
    id: 'asia-8',
    url: 'https://images.pexels.com/photos/21787/pexels-photo.jpg',
    location: 'Himalayas, Nepal',
    region: 'Asia',
    photographer: 'Pexels',
    description: 'Towering peaks and remote villages'
  },

  // Americas (Continued)
  {
    id: 'americas-5',
    url: 'https://images.pexels.com/photos/410648/pexels-photo-410648.jpeg',
    location: 'Grand Canyon, USA',
    region: 'Americas',
    photographer: 'Pexels',
    description: 'Majestic canyon with vibrant colors'
  },
  {
    id: 'americas-6',
    url: 'https://images.pexels.com/photos/1271618/pexels-photo-1271618.jpeg',
    location: 'Havana, Cuba',
    region: 'Americas',
    photographer: 'Pexels',
    description: 'Colorful streets and vintage cars'
  },
  {
    id: 'americas-7',
    url: 'https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg',
    location: 'Patagonia, Argentina',
    region: 'Americas',
    photographer: 'Pexels',
    description: 'Glaciers and rugged mountain landscapes'
  },
  {
    id: 'americas-8',
    url: 'https://images.pexels.com/photos/2307257/pexels-photo-2307257.jpeg',
    location: 'Yellowstone National Park, USA',
    region: 'Americas',
    photographer: 'Pexels',
    description: 'Geothermal wonders and wildlife'
  },

  // Middle East (Continued)
  {
    id: 'middle-east-3',
    url: 'https://images.pexels.com/photos/3225530/pexels-photo-3225530.jpeg',
    location: 'Jerusalem, Israel',
    region: 'Middle East',
    photographer: 'Pexels',
    description: 'Historic and spiritual landmarks'
  },
  {
    id: 'middle-east-4',
    url: 'https://images.pexels.com/photos/3048263/pexels-photo-3048263.jpeg',
    location: 'Cairo, Egypt',
    region: 'Middle East',
    photographer: 'Pexels',
    description: 'Pyramids of Giza and bustling city streets'
  },

  // Africa (Continued)
  {
    id: 'africa-3',
    url: 'https://images.pexels.com/photos/36717/safari-lion-wilderness-savanna.jpg',
    location: 'Kruger National Park, South Africa',
    region: 'Africa',
    photographer: 'Pexels',
    description: 'Big Five safaris and breathtaking sunsets'
  },
  {
    id: 'africa-4',
    url: 'https://images.pexels.com/photos/45201/pexels-photo-45201.jpeg',
    location: 'Pyramids, Egypt',
    region: 'Africa',
    photographer: 'Pexels',
    description: 'Iconic ancient structures in the desert'
  },

  // Oceania (Continued)
  {
    id: 'oceania-3',
    url: 'https://images.pexels.com/photos/317350/pexels-photo-317350.jpeg',
    location: 'Sydney Opera House, Australia',
    region: 'Oceania',
    photographer: 'Pexels',
    description: 'Iconic architectural wonder on the harbor'
  },
  {
    id: 'oceania-4',
    url: 'https://images.pexels.com/photos/2382686/pexels-photo-2382686.jpeg',
    location: 'Milford Sound, New Zealand',
    region: 'Oceania',
    photographer: 'Pexels',
    description: 'Dramatic fjord landscapes and waterfalls'
  },

  // More entries can follow in a similar structure to reach the 100 images goal.
];
const travelImages: TravelImage[] = [
  ...travelImages1,
  ...travelImages2
];
let currentIndex = -1;
let lastRegion: string | null = null;

export const getNextTravelImage = (): TravelImage => {
  // Avoid showing images from the same region consecutively
  let nextImage: TravelImage;
  do {
    currentIndex = (currentIndex + 1) % travelImages.length;
    nextImage = travelImages[currentIndex];
  } while (lastRegion === nextImage.region && travelImages.length > 1);
  
  lastRegion = nextImage.region;
  return nextImage;
};

export const getRandomTravelImage = (): TravelImage => {
  const randomIndex = Math.floor(Math.random() * travelImages.length);
  return travelImages[randomIndex];
};

export const getImagesByRegion = (region: string): TravelImage[] => {
  return travelImages.filter(image => image.region === region);
};

export type { TravelImage };