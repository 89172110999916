import { Calendar, Clock, Tag } from 'lucide-react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import type { IArticle } from '../../types/blog';

interface ArticleCardProps {
  article: IArticle;
}

export default function ArticleCard({ article }: ArticleCardProps) {
  const formatDate = (dateString: string) => {
    try {
      return format(parseISO(dateString), 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Date unavailable';
    }
  };

  return (
    <Link
      to={`/blog/${article.slug}`}
      className="group block bg-gray-900/60 backdrop-blur-sm rounded-xl overflow-hidden border border-white/10 hover:border-accent-500/30 transition-all duration-300"
    >
      {/* Cover Image */}
      <div className="relative aspect-video overflow-hidden">
        <img
          src={article.image}
          alt={article.headline}
          className="absolute inset-0 w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/50 to-transparent opacity-60 group-hover:opacity-40 transition-opacity" />
      </div>

      {/* Content */}
      <div className="p-6">
        {/* Category */}
        <div className="inline-block px-3 py-1 bg-gradient-to-r from-accent-500/20 to-primary-600/20 text-accent-400 rounded-full text-sm font-medium mb-4 border border-accent-500/20">
          {article.category.title}
        </div>

        {/* Title & Description */}
        <h3 className="text-xl font-bold text-white mb-2 group-hover:text-accent-400 transition-colors">
          {article.headline}
        </h3>
        <p className="text-gray-300 text-sm line-clamp-2 mb-4">
          {article.metaDescription}
        </p>

        {/* Tags */}
        <div className="flex flex-wrap gap-2 mb-4">
          {article.tags.map((tag) => (
            <span
              key={tag.id}
              className="px-2 py-0.5 text-xs font-medium rounded-md bg-white/5 text-gray-300 border border-white/10"
            >
              {tag.title}
            </span>
          ))}
        </div>

        {/* Metadata */}
        <div className="flex items-center gap-4 text-sm text-gray-400">
          <div className="flex items-center gap-1.5">
            <Calendar className="h-4 w-4 text-accent-400" />
            <time dateTime={article.publishedAt}>
              {formatDate(article.publishedAt)}
            </time>
          </div>
          <div className="flex items-center gap-1.5">
            <Clock className="h-4 w-4 text-accent-400" />
            <span>{article.readingTime} min read</span>
          </div>
        </div>
      </div>
    </Link>
  );
}