import { Star, MapPin } from 'lucide-react';
import type { Hotel } from '../../../types/hotels';
import HotelAmenities from './HotelAmenities';

interface HotelCardProps {
  hotel: Hotel;
}

export default function HotelCard({ hotel }: HotelCardProps) {
  return (
    <div className="bg-white/5 rounded-xl overflow-hidden hover:bg-white/10 transition-colors">
      <div className="grid md:grid-cols-3 gap-6">
        {/* Image */}
        <div className="relative aspect-video md:aspect-auto">
          {hotel.images?.[0] && (
            <img
              src={hotel.images[0].original_image}
              alt={hotel.name}
              className="absolute inset-0 w-full h-full object-cover"
            />
          )}
        </div>

        {/* Info */}
        <div className="p-6 md:col-span-2 space-y-4">
          <div className="flex justify-between items-start gap-4">
            <div>
              <h4 className="text-lg font-semibold text-white">{hotel.name}</h4>
              {hotel.hotel_class && (
                <div className="flex items-center gap-2 mt-1">
                  <div className="flex">
                    {Array.from({ length: Math.floor(hotel.extracted_hotel_class || 0) }).map((_, i) => (
                      <Star key={i} className="h-4 w-4 text-yellow-400 fill-current" />
                    ))}
                  </div>
                  {hotel.reviews && (
                    <span className="text-sm text-gray-400">
                      ({hotel.reviews.toLocaleString()} reviews)
                    </span>
                  )}
                </div>
              )}
            </div>
            <div className="text-right">
              {hotel.rate_per_night?.lowest && (
                <>
                  <div className="text-2xl font-bold text-white">
                    {hotel.rate_per_night.lowest}
                  </div>
                  <div className="text-sm text-gray-400">per night</div>
                </>
              )}
              {hotel.deal && (
                <div className="mt-1 text-xs text-emerald-400">
                  {hotel.deal}
                </div>
              )}
            </div>
          </div>

          {hotel.description && (
            <p className="text-sm text-gray-300">{hotel.description}</p>
          )}

          {hotel.gps_coordinates && hotel.nearby_places?.[0] && (
            <div className="flex items-center gap-2 text-gray-300">
              <MapPin className="h-4 w-4 flex-shrink-0" />
              <span className="text-sm">
                {hotel.nearby_places[0].name} • {hotel.nearby_places[0].transportations?.[0]?.duration} away
              </span>
            </div>
          )}

          {/* Amenities */}
          {hotel.amenities && (
            <div className="flex flex-wrap gap-3">
              <HotelAmenities amenities={hotel.amenities} />
            </div>
          )}

          <a
            href={hotel.link}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block px-4 py-2 bg-accent-500 hover:bg-accent-600 text-white rounded-lg transition-colors"
          >
            View Details & Book
          </a>
        </div>
      </div>
    </div>
  );
}