import { useState } from 'react';
import { Plus, Calendar, MapPin, Clock, DollarSign, Users } from 'lucide-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface TripDay {
  id: string;
  date: string;
  activities: TripActivity[];
}

interface TripActivity {
  id: string;
  type: 'flight' | 'hotel' | 'activity' | 'transfer';
  title: string;
  time: string;
  duration?: string;
  price?: string;
  location?: string;
}

export default function TripBuilder() {
  const [trip, setTrip] = useState<TripDay[]>([
    {
      id: 'day-1',
      date: '2024-03-15',
      activities: [
        {
          id: 'activity-1',
          type: 'flight',
          title: 'Flight to Paris',
          time: '10:00',
          duration: '8h',
          price: '$800'
        },
        {
          id: 'activity-2',
          type: 'transfer',
          title: 'Airport Transfer',
          time: '19:00',
          duration: '45m',
          price: '$60'
        },
        {
          id: 'activity-3',
          type: 'hotel',
          title: 'Check-in: Hotel de Paris',
          time: '20:00',
          location: 'Central Paris'
        }
      ]
    }
  ]);

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const sourceDay = trip.find(day => day.id === result.source.droppableId);
    const destDay = trip.find(day => day.id === result.destination.droppableId);

    if (!sourceDay || !destDay) return;

    const [movedActivity] = sourceDay.activities.splice(result.source.index, 1);
    destDay.activities.splice(result.destination.index, 0, movedActivity);

    setTrip([...trip]);
  };

  const getActivityIcon = (type: TripActivity['type']) => {
    switch (type) {
      case 'flight':
        return <MapPin className="h-4 w-4" />;
      case 'hotel':
        return <Users className="h-4 w-4" />;
      case 'activity':
        return <Clock className="h-4 w-4" />;
      case 'transfer':
        return <DollarSign className="h-4 w-4" />;
      default:
        return null;
    }
  };

  return (
    <div className="space-y-4 sm:space-y-6">
      {/* Trip Header */}
      <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4 sm:gap-0">
        <div>
          <h2 className="text-xl sm:text-2xl font-bold text-gray-900">Paris Adventure</h2>
          <div className="flex items-center gap-2 text-gray-500 mt-1">
            <Calendar className="h-4 w-4" />
            <span className="text-sm sm:text-base">Mar 15 - Mar 20, 2024</span>
          </div>
        </div>
        <button className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 py-2 bg-accent-500 text-white rounded-lg hover:bg-accent-600 transition-colors">
          <Plus className="h-5 w-5" />
          Add Day
        </button>
      </div>

      {/* Trip Timeline */}
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="space-y-4 sm:space-y-6">
          {trip.map((day) => (
            <div key={day.id} className="bg-white rounded-lg shadow-sm">
              <div className="p-3 sm:p-4 border-b border-gray-200">
                <div className="font-medium text-gray-900 text-sm sm:text-base">{day.date}</div>
              </div>
              
              <Droppable droppableId={day.id}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="p-3 sm:p-4 space-y-3"
                  >
                    {day.activities.map((activity, index) => (
                      <Draggable
                        key={activity.id}
                        draggableId={activity.id}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="flex flex-col sm:flex-row items-start sm:items-center gap-3 sm:gap-4 p-3 sm:p-4 bg-gray-50 rounded-lg border border-gray-200 cursor-move hover:bg-gray-100 transition-colors"
                          >
                            <div className="p-2 bg-accent-500/10 rounded-lg text-accent-500">
                              {getActivityIcon(activity.type)}
                            </div>
                            
                            <div className="flex-1 w-full sm:w-auto">
                              <div className="font-medium text-gray-900 text-sm sm:text-base">
                                {activity.title}
                              </div>
                              <div className="flex flex-wrap items-center gap-2 sm:gap-4 mt-1 text-xs sm:text-sm text-gray-500">
                                <div className="flex items-center gap-1">
                                  <Clock className="h-3 w-3 sm:h-4 sm:w-4" />
                                  {activity.time}
                                </div>
                                {activity.duration && (
                                  <div>{activity.duration}</div>
                                )}
                                {activity.location && (
                                  <div className="flex items-center gap-1">
                                    <MapPin className="h-3 w-3 sm:h-4 sm:w-4" />
                                    {activity.location}
                                  </div>
                                )}
                              </div>
                            </div>

                            {activity.price && (
                              <div className="text-accent-500 font-medium text-sm sm:text-base mt-2 sm:mt-0">
                                {activity.price}
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </div>
      </DragDropContext>
    </div>
  );
}