interface TripDescriptionProps {
    description: string;
  }
  
  export default function TripDescription({ description }: TripDescriptionProps) {
    if (!description) return null;
  
    return (
      <div className="p-4 bg-accent-500/10 rounded-lg border border-accent-500/20">
        <p className="text-gray-200 italic">"{description}"</p>
      </div>
    );
  }