import { useState, useEffect } from 'react';
import { X, Copy, Check, Share2 } from 'lucide-react';
import axios from 'axios';

interface ShareModalProps {
  isOpen: boolean;
  onClose: () => void;
  recommendation: any;
  surveyResponses: any;
}

export default function ShareModal({ isOpen, onClose, recommendation, surveyResponses }: ShareModalProps) {
  const [shareUrl, setShareUrl] = useState('');
  const [copied, setCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (isOpen) {
      createShareUrl();
    }
  }, [isOpen]);

  const createShareUrl = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post('/api/share', {
        recommendation,
        surveyResponses
      });
      
      const { shareId } = response.data;
      const url = `${window.location.origin}/share/${shareId}`;
      setShareUrl(url);
    } catch (error) {
      console.error('Error creating share URL:', error);
      setError('Failed to create share link. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50">
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm rounded-3xl" onClick={onClose} />
      
      <div className="fixed inset-0 pointer-events-none flex items-center justify-center p-4">
        <div className="relative w-full max-w-md bg-gray-900 rounded-2xl shadow-[0_0_50px_rgba(0,0,0,0.5)] pointer-events-auto">
          {/* Header */}
          <div className="flex items-center justify-between p-6 border-b border-gray-800">
            <div className="flex items-center gap-3">
              <Share2 className="h-5 w-5 text-accent-400" />
              <h3 className="text-lg font-semibold text-white">Share Recommendations</h3>
            </div>
            <button
              onClick={onClose}
              className="p-2 text-gray-400 hover:text-white rounded-lg transition-colors"
            >
              <X className="h-5 w-5" />
            </button>
          </div>

          {/* Content */}
          <div className="p-6 space-y-4">
            <p className="text-gray-300 text-sm">
              Share these travel recommendations with friends and family. They'll see the exact same destinations and suggestions!
            </p>

            {isLoading ? (
              <div className="flex items-center justify-center py-4">
                <div className="w-8 h-8 border-2 border-accent-500 border-t-transparent rounded-full animate-spin" />
              </div>
            ) : error ? (
              <div className="text-center py-4">
                <p className="text-red-400 text-sm mb-4">{error}</p>
                <button
                  onClick={createShareUrl}
                  className="px-4 py-2 bg-accent-500 text-white rounded-lg hover:bg-accent-600 transition-colors"
                >
                  Try Again
                </button>
              </div>
            ) : (
              <div className="relative">
                <input
                  type="text"
                  value={shareUrl}
                  readOnly
                  className="w-full bg-gray-800/50 border border-gray-700 rounded-lg px-4 py-3 pr-12 text-white text-sm"
                />
                <button
                  onClick={handleCopy}
                  className={`
                    absolute right-2 top-1/2 -translate-y-1/2
                    p-2 rounded-lg transition-all duration-200
                    ${copied
                      ? 'bg-emerald-500/20 text-emerald-400'
                      : 'bg-accent-500/20 text-accent-400 hover:bg-accent-500/30 hover:text-accent-300'
                    }
                  `}
                >
                  {copied ? (
                    <Check className="h-4 w-4" />
                  ) : (
                    <Copy className="h-4 w-4" />
                  )}
                </button>
              </div>
            )}

            {copied && (
              <p className="text-sm text-emerald-400">
                Link copied to clipboard!
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}