import type { Deal } from '../../../types/agency';

export const stages = [
  { id: 'inquiry', label: 'New Inquiry', color: 'bg-blue-500' },
  { id: 'proposal', label: 'Proposal Sent', color: 'bg-yellow-500' },
  { id: 'negotiation', label: 'In Negotiation', color: 'bg-purple-500' },
  { id: 'booked', label: 'Trip Booked', color: 'bg-emerald-500' }
];

// Helper function to generate a random date within a range
const randomDate = (start: Date, end: Date) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

// Helper function to generate a random budget
const randomBudget = (min: number, max: number) => {
  const total = Math.floor(Math.random() * (max - min) + min);
  const spent = Math.floor(Math.random() * total);
  return {
    total,
    spent,
    remaining: total - spent
  };
};

export const mockDeals: Deal[] = [
  // Inquiry Stage
  {
    id: 'deal-1',
    clientId: '1',
    client: 'John Smithson',
    agentId: '1',
    agencyId: '1',
    status: 'inquiry',
    destinations: ['Paris', 'London'],
    startDate: randomDate(new Date('2024-06-01'), new Date('2024-06-30')),
    endDate: randomDate(new Date('2024-07-01'), new Date('2024-07-31')),
    budget: randomBudget(5000, 10000),
    documents: [],
    notes: 'Interested in luxury hotels and fine dining experiences',
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    id: 'deal-2',
    clientId: '2',
    client: 'Tech Innovators Inc',
    agentId: '2',
    agencyId: '1',
    status: 'inquiry',
    destinations: ['Tokyo', 'Singapore'],
    startDate: randomDate(new Date('2024-05-01'), new Date('2024-05-31')),
    endDate: randomDate(new Date('2024-06-01'), new Date('2024-06-30')),
    budget: randomBudget(25000, 35000),
    documents: [],
    notes: 'Corporate retreat for 10 executives',
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    id: 'deal-3',
    clientId: '3',
    client: 'Adventure Seekers Club',
    agentId: '1',
    agencyId: '1',
    status: 'inquiry',
    destinations: ['Machu Picchu', 'Amazon'],
    startDate: randomDate(new Date('2024-07-01'), new Date('2024-07-31')),
    endDate: randomDate(new Date('2024-08-01'), new Date('2024-08-31')),
    budget: randomBudget(12000, 18000),
    documents: [],
    notes: 'Group of 6 looking for hiking and cultural experiences',
    createdAt: new Date(),
    updatedAt: new Date()
  },

  // Proposal Stage
  {
    id: 'deal-4',
    clientId: '4',
    client: 'Sarah Johnson',
    agentId: '2',
    agencyId: '1',
    status: 'proposal',
    destinations: ['Maldives'],
    startDate: randomDate(new Date('2024-08-01'), new Date('2024-08-31')),
    endDate: randomDate(new Date('2024-09-01'), new Date('2024-09-30')),
    budget: randomBudget(15000, 20000),
    documents: [],
    notes: 'Honeymoon package with overwater bungalow',
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    id: 'deal-5',
    clientId: '5',
    client: 'Global Corp Ltd',
    agentId: '1',
    agencyId: '1',
    status: 'proposal',
    destinations: ['Singapore', 'Bali'],
    startDate: randomDate(new Date('2024-05-01'), new Date('2024-05-31')),
    endDate: randomDate(new Date('2024-06-01'), new Date('2024-06-30')),
    budget: randomBudget(35000, 45000),
    documents: [],
    notes: 'Annual team building event for 20 people',
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    id: 'deal-6',
    clientId: '6',
    client: 'Family Adventures Group',
    agentId: '2',
    agencyId: '1',
    status: 'proposal',
    destinations: ['Costa Rica'],
    startDate: randomDate(new Date('2024-06-01'), new Date('2024-06-30')),
    endDate: randomDate(new Date('2024-07-01'), new Date('2024-07-31')),
    budget: randomBudget(18000, 25000),
    documents: [],
    notes: 'Multi-family trip with eco-tourism focus',
    createdAt: new Date(),
    updatedAt: new Date()
  },

  // Negotiation Stage
  {
    id: 'deal-7',
    clientId: '7',
    client: 'Elite Experiences LLC',
    agentId: '1',
    agencyId: '1',
    status: 'negotiation',
    destinations: ['Swiss Alps'],
    startDate: randomDate(new Date('2024-12-01'), new Date('2024-12-31')),
    endDate: randomDate(new Date('2025-01-01'), new Date('2025-01-31')),
    budget: randomBudget(45000, 55000),
    documents: [],
    notes: 'Luxury ski trip with private chalets',
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    id: 'deal-8',
    clientId: '8',
    client: 'Startup Summit',
    agentId: '2',
    agencyId: '1',
    status: 'negotiation',
    destinations: ['Barcelona', 'Madrid'],
    startDate: randomDate(new Date('2024-09-01'), new Date('2024-09-30')),
    endDate: randomDate(new Date('2024-10-01'), new Date('2024-10-31')),
    budget: randomBudget(28000, 35000),
    documents: [],
    notes: 'Conference and team retreat combination',
    createdAt: new Date(),
    updatedAt: new Date()
  },

  // Booked Stage
  {
    id: 'deal-9',
    clientId: '9',
    client: 'Royal Family Office',
    agentId: '1',
    agencyId: '1',
    status: 'booked',
    destinations: ['Dubai', 'Abu Dhabi'],
    startDate: randomDate(new Date('2024-11-01'), new Date('2024-11-30')),
    endDate: randomDate(new Date('2024-12-01'), new Date('2024-12-31')),
    budget: randomBudget(75000, 100000),
    documents: [],
    notes: 'VIP treatment and exclusive experiences',
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    id: 'deal-10',
    clientId: '10',
    client: 'Mountain Explorers',
    agentId: '2',
    agencyId: '1',
    status: 'booked',
    destinations: ['Nepal', 'Tibet'],
    startDate: randomDate(new Date('2024-10-01'), new Date('2024-10-31')),
    endDate: randomDate(new Date('2024-11-01'), new Date('2024-11-30')),
    budget: randomBudget(22000, 28000),
    documents: [],
    notes: 'Everest Base Camp expedition',
    createdAt: new Date(),
    updatedAt: new Date()
  },
  {
    id: 'deal-11',
    clientId: '11',
    client: 'Tech Giants Co',
    agentId: '1',
    agencyId: '1',
    status: 'booked',
    destinations: ['Bali'],
    startDate: randomDate(new Date('2024-08-01'), new Date('2024-08-31')),
    endDate: randomDate(new Date('2024-09-01'), new Date('2024-09-30')),
    budget: randomBudget(42000, 48000),
    documents: [],
    notes: 'Executive retreat with wellness focus',
    createdAt: new Date(),
    updatedAt: new Date()
  }
];