import { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';
import { Star, Quote, MapPin } from 'lucide-react';
import anime from 'animejs';

interface Testimonial {
  id: string;
  name: string;
  location: string;
  image: string;
  rating: number;
  text: string;
  tripType: string;
}

const testimonials: Testimonial[] = [
  {
    id: '1',
    name: 'Sarah Mitchell',
    location: 'Tokyo, Japan',
    image: 'https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg',
    rating: 5,
    text: 'WanderWise transformed my solo trip to Japan. The AI suggestions were spot-on, helping me discover hidden gems I would have never found on my own.',
    tripType: 'Solo Adventure'
  },
  {
    id: '2',
    name: 'James Wilson',
    location: 'Barcelona, Spain',
    image: 'https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg',
    rating: 5,
    text: 'Planning a family vacation was so easy with WanderWise. The group planning features kept everyone happy and the budget tools saved us money.',
    tripType: 'Family Trip'
  },
  {
    id: '3',
    name: 'Emma Rodriguez',
    location: 'Bali, Indonesia',
    image: 'https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg',
    rating: 5,
    text: 'The local recommendations were incredible. Every restaurant and activity suggested matched our interests perfectly. Best travel planning tool ever!',
    tripType: 'Honeymoon'
  }
];

export default function Testimonials() {
  const containerRef = useRef<HTMLDivElement>(null);
  const isInView = useInView(containerRef, { once: true, margin: "-100px" });
  const controls = useAnimation();

  useEffect(() => {
    if (isInView) {
      controls.start("visible");
      
      // Animate decorative elements
      if (containerRef.current) {
        anime({
          targets: '.decorative-dot',
          scale: [0, 1],
          opacity: [0, 1],
          delay: anime.stagger(100),
          duration: 1000,
          easing: 'easeOutElastic(1, .8)'
        });

        anime({
          targets: '.decorative-line',
          scaleX: [0, 1],
          opacity: [0, 1],
          delay: anime.stagger(200),
          duration: 1200,
          easing: 'easeOutExpo'
        });
      }
    }
  }, [isInView, controls]);

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const cardVariants = {
    hidden: {
      opacity: 0,
      y: 20
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.18, 0.67, 0.6, 1.22]
      }
    }
  };

  return (
    <div className="py-24 bg-gradient-to-b from-gray-900 to-gray-800 overflow-hidden">
      <div 
        ref={containerRef}
        className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
      >
        {/* Header */}
        <div className="text-center mb-16">
          <motion.h2 
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, ease: [0.18, 0.67, 0.6, 1.22] }}
            className="text-4xl font-bold text-white mb-4"
          >
            What Our Travelers Say
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={isInView ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 0.8, delay: 0.2, ease: [0.18, 0.67, 0.6, 1.22] }}
            className="text-xl text-gray-300"
          >
            Real experiences from our global community
          </motion.p>
        </div>

        {/* Testimonials Grid */}
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={controls}
          className="grid grid-cols-1 md:grid-cols-3 gap-8"
        >
          {testimonials.map((testimonial, index) => (
            <motion.div
              key={testimonial.id}
              variants={cardVariants}
              className="relative"
            >
              {/* Decorative elements */}
              <div className="decorative-dot absolute -top-2 -right-2 w-4 h-4 bg-accent-500 rounded-full opacity-0" />
              <div className="decorative-line absolute -bottom-2 left-4 right-4 h-1 bg-gradient-to-r from-accent-500/50 to-primary-600/50 opacity-0" />

              {/* Card */}
              <div className="relative bg-white/5 backdrop-blur-sm border-4 border-black p-6 rounded-xl shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] hover:translate-x-1 hover:translate-y-1 hover:shadow-none transition-all duration-200">
                {/* Quote Icon */}
                <div className="absolute -top-4 -left-4 p-3 bg-accent-500 rounded-xl shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]">
                  <Quote className="h-6 w-6 text-white" />
                </div>

                {/* Content */}
                <div className="pt-4">
                  {/* Rating */}
                  <div className="flex items-center gap-1 mb-4">
                    {[...Array(testimonial.rating)].map((_, i) => (
                      <Star key={i} className="h-5 w-5 text-yellow-400 fill-current" />
                    ))}
                  </div>

                  {/* Text */}
                  <p className="text-gray-300 mb-6">"{testimonial.text}"</p>

                  {/* Author */}
                  <div className="flex items-center gap-4">
                    <img
                      src={testimonial.image}
                      alt={testimonial.name}
                      className="w-12 h-12 rounded-xl border-2 border-black object-cover"
                    />
                    <div>
                      <h4 className="font-bold text-white">{testimonial.name}</h4>
                      <div className="flex items-center gap-2 text-sm text-gray-400">
                        <MapPin className="h-4 w-4" />
                        <span>{testimonial.location}</span>
                      </div>
                    </div>
                  </div>

                  {/* Trip Type */}
                  <div className="absolute top-6 right-6 px-3 py-1 bg-accent-500/20 text-accent-400 rounded-full text-sm border border-accent-500/30">
                    {testimonial.tripType}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}