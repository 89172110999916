import { useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Plus } from 'lucide-react';
import { format, addDays } from 'date-fns';
import DateNavigation from './DateNavigation';
import ScheduleHeader from './ScheduleHeader';
import ScheduleColumn from './ScheduleColumn';
import AddScheduleItemModal from '../Itineraries/AddScheduleItemModal';
import type { Day, ScheduleItem } from '../../types/schedule';

interface ScheduleViewProps {
  startDate: string;
  endDate: string;
  days: Day[];
  onUpdateSchedule: (days: Day[]) => void;
}

export default function ScheduleView({
  startDate,
  endDate,
  days,
  onUpdateSchedule
}: ScheduleViewProps) {
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [showAddModal, setShowAddModal] = useState(false);

  // Ensure valid dates
  const validStartDate = startDate ? new Date(startDate) : new Date();
  const validEndDate = endDate ? new Date(endDate) : addDays(validStartDate, 7);

  // Generate array of dates between start and end
  const dateRange: string[] = [];
  let currentDate = validStartDate;
  
  while (currentDate <= validEndDate) {
    dateRange.push(currentDate.toISOString().split('T')[0]);
    currentDate = addDays(currentDate, 1);
  }

  // Set initial selected date if not set
  if (!selectedDate && dateRange.length > 0) {
    setSelectedDate(dateRange[0]);
  }

  const getScheduleForDate = (date: string): ScheduleItem[] => {
    if (!date) return [];
    const day = days.find(d => d.date === date);
    return day?.schedule || [];
  };

  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;
    
    if (!destination) return;

    const sourceDate = source.droppableId;
    const destDate = destination.droppableId;
    const sourceSchedule = getScheduleForDate(sourceDate);
    const destSchedule = sourceDate === destDate ? sourceSchedule : getScheduleForDate(destDate);

    const [movedItem] = sourceSchedule.splice(source.index, 1);
    destSchedule.splice(destination.index, 0, movedItem);

    const updatedDays = [...days];

    // Update source day
    const sourceDayIndex = updatedDays.findIndex(d => d.date === sourceDate);
    if (sourceDayIndex >= 0) {
      updatedDays[sourceDayIndex] = {
        ...updatedDays[sourceDayIndex],
        schedule: sourceSchedule
      };
    } else if (sourceSchedule.length > 0) {
      updatedDays.push({ date: sourceDate, schedule: sourceSchedule });
    }

    // Update destination day if different
    if (sourceDate !== destDate) {
      const destDayIndex = updatedDays.findIndex(d => d.date === destDate);
      if (destDayIndex >= 0) {
        updatedDays[destDayIndex] = {
          ...updatedDays[destDayIndex],
          schedule: destSchedule
        };
      } else {
        updatedDays.push({ date: destDate, schedule: destSchedule });
      }
    }

    onUpdateSchedule(updatedDays);
  };

  const handleAddItem = (date: string, item: Omit<ScheduleItem, '_id'>) => {
    const schedule = getScheduleForDate(date);
    const newItem = {
      ...item,
      _id: Math.random().toString(36).substr(2, 9)
    };

    const updatedDays = [...days];
    const dayIndex = updatedDays.findIndex(d => d.date === date);

    if (dayIndex >= 0) {
      updatedDays[dayIndex] = {
        ...updatedDays[dayIndex],
        schedule: [...schedule, newItem]
      };
    } else {
      updatedDays.push({
        date,
        schedule: [newItem]
      });
    }

    onUpdateSchedule(updatedDays);
    setShowAddModal(false);
  };

  return (
    <div className="space-y-6">
      <DateNavigation
        startDate={startDate}
        endDate={endDate}
        selectedDate={selectedDate}
        onDateSelect={setSelectedDate}
        getItemCount={(date) => getScheduleForDate(date).length}
      />

      {selectedDate && (
        <div className="bg-gray-900/60 backdrop-blur-sm rounded-xl border border-white/10 overflow-hidden">
          <ScheduleHeader date={selectedDate} />

          <DragDropContext onDragEnd={handleDragEnd}>
            <div className="p-4 space-y-4">
              <ScheduleColumn
                date={selectedDate}
                items={getScheduleForDate(selectedDate)}
                onItemsChange={(items) => {
                  const updatedDays = [...days];
                  const dayIndex = updatedDays.findIndex(d => d.date === selectedDate);
                  
                  if (dayIndex >= 0) {
                    updatedDays[dayIndex] = {
                      ...updatedDays[dayIndex],
                      schedule: items
                    };
                  } else if (items.length > 0) {
                    updatedDays.push({
                      date: selectedDate,
                      schedule: items
                    });
                  }

                  onUpdateSchedule(updatedDays);
                }}
              />

              <button
                onClick={() => setShowAddModal(true)}
                className="w-full p-3 bg-white/5 hover:bg-white/10 border border-white/10 hover:border-accent-500/30 rounded-lg transition-all duration-200 text-gray-400 hover:text-accent-400"
              >
                <Plus className="h-5 w-5 mx-auto" />
              </button>
            </div>
          </DragDropContext>
        </div>
      )}

      {showAddModal && selectedDate && (
        <AddScheduleItemModal
          onClose={() => setShowAddModal(false)}
          onAdd={(item) => handleAddItem(selectedDate, item)}
        />
      )}
    </div>
  );
}