import { useState, useEffect } from 'react';
import { getArticle } from '../services/blog';
import type { IArticle } from '../types/blog';

export function useArticle(slug: string) {
  const [article, setArticle] = useState<IArticle | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadArticle = async () => {
      try {
        setIsLoading(true);
        setError(null);
        const data = await getArticle(slug);
        setArticle(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to load article');
      } finally {
        setIsLoading(false);
      }
    };

    loadArticle();
  }, [slug]);

  return { article, isLoading, error };
}