import { useState } from 'react';
import { Search, X } from 'lucide-react';
import { searchActivityDetails } from '../../services/activities';
import type { ActivityResult } from '../../types/activities';
import ActivityCard from './ActivitySearchModal/ActivityCard';
import { useAuth } from '../../contexts/AuthContext';
import Alert from '../ui/Alert';

interface ActivitySearchProps {
  activity: string;
  destination: string;
  onClose: () => void;
}

export default function ActivitySearch({
  activity,
  destination,
  onClose
}: ActivitySearchProps) {
  const [results, setResults] = useState<ActivityResult[] | null>(null);
  const [isSearching, setIsSearching] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { isAuthenticated } = useAuth();

  const handleSearch = async () => {
    setIsSearching(true);
    setError(null);

    try {
      const searchResults = await searchActivityDetails(activity, destination);
      setResults(searchResults);
    } catch (error) {
      console.error('Error searching activity details:', error);
      setError('Unable to find activity details. Please try again.');
    } finally {
      setIsSearching(false);
    }
  };

  useState(() => {
    handleSearch();
  }, []);

  return (
    <div className="p-4 rounded-xl bg-gray-800/50 border border-accent-500/20 space-y-4">
      <div className="flex justify-between items-center">
        <h4 className="text-lg font-semibold text-white">Activity Details</h4>
        <button
          onClick={onClose}
          className="p-2 text-gray-400 hover:text-white transition-colors rounded-lg hover:bg-white/10"
        >
          <X className="h-5 w-5" />
        </button>
      </div>

      {/* Results */}
      {isSearching ? (
        <div className="flex flex-col items-center justify-center py-8">
          <div className="w-12 h-12 border-3 border-accent-500 border-t-transparent rounded-full animate-spin" />
          <p className="mt-4 text-sm text-gray-400">Searching for activities...</p>
        </div>
      ) : error ? (
        <div className="text-center py-4">
          <p className="text-red-400 text-sm">{error}</p>
        </div>
      ) : results ? (
        <div className="space-y-4">
          {results.map((result, index) => (
            <ActivityCard 
              key={index} 
              activity={result}
              destinationName={destination}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
}