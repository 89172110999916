import { DragOverlay } from '@dnd-kit/core';
import { Activity, Utensils, MapPin, Clock } from 'lucide-react';

interface DragOverlayItemProps {
  type?: 'activity' | 'dining';
  title?: string;
  description?: string;
  duration?: string;
  location?: string;
}

export default function DragOverlayItem({
  type,
  title,
  description,
  duration,
  location
}: DragOverlayItemProps) {
  if (!type || !title) return null;

  const Icon = type === 'activity' ? Activity : Utensils;
  const colorClass = type === 'activity' 
    ? 'text-emerald-400 bg-emerald-500/10'
    : 'text-orange-400 bg-orange-500/10';

  return (
    <DragOverlay dropAnimation={null}>
      <div
        className={`
          group relative flex items-start gap-3 p-4 bg-white/5 rounded-lg 
          border border-white/10 shadow-xl
          transform scale-105
        `}
        style={{
          width: '300px',
          pointerEvents: 'none'
        }}
      >
        <div className={`p-2 rounded-lg ${colorClass}`}>
          <Icon className="h-4 w-4" />
        </div>

        <div className="flex-1 min-w-0">
          <h4 className="font-medium text-white mb-1">{title}</h4>
          
          <div className="flex items-center gap-4 text-sm text-gray-400">
            {duration && (
              <div className="flex items-center gap-1.5">
                <Clock className="h-4 w-4" />
                <span>{duration}</span>
              </div>
            )}
            {location && (
              <div className="flex items-center gap-1.5">
                <MapPin className="h-4 w-4" />
                <span>{location}</span>
              </div>
            )}
          </div>

          {description && (
            <p className="mt-2 text-sm text-gray-300">{description}</p>
          )}
        </div>
      </div>
    </DragOverlay>
  );
}