import { useState } from 'react';
import { Mail, Phone, MapPin, Plus } from 'lucide-react';
import ClientDetails from './ClientDetails';

const mockClients = [
  {
    id: 1,
    name: 'Acme Corporation',
    email: 'contact@acme.com',
    phone: '+1 (555) 123-4567',
    location: 'New York, NY',
    status: 'active',
    trips: 12,
    revenue: 45000
  },
  {
    id: 2,
    name: 'Global Ventures Ltd',
    email: 'info@globalventures.com',
    phone: '+1 (555) 987-6543',
    location: 'London, UK',
    status: 'pending',
    trips: 8,
    revenue: 32000
  },
  {
    id: 3,
    name: 'Tech Innovators Inc',
    email: 'hello@techinnovators.com',
    phone: '+1 (555) 456-7890',
    location: 'San Francisco, CA',
    status: 'active',
    trips: 15,
    revenue: 67000
  }
];

export default function ClientList({ searchQuery }: { searchQuery: string }) {
  const [selectedClient, setSelectedClient] = useState<number | null>(null);

  const filteredClients = mockClients.filter(client =>
    Object.values(client).some(value =>
      value.toString().toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <div className="space-y-4 sm:space-y-6">
      {/* Add Client Button */}
      <button className="w-full sm:w-auto flex items-center justify-center gap-2 px-4 sm:px-6 py-2.5 sm:py-3 bg-accent-500 text-white text-sm sm:text-base font-bold hover:bg-accent-600 transition-colors">
        <Plus className="h-4 w-4 sm:h-5 sm:w-5" />
        Add New Client
      </button>

      {/* Client Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-6">
        {filteredClients.map(client => (
          <div
            key={client.id}
            onClick={() => setSelectedClient(client.id)}
            className={`
              relative bg-white border-2 sm:border-4 border-black p-4 sm:p-6 cursor-pointer
              transition-all duration-200 hover:translate-x-1 hover:translate-y-1
              ${selectedClient === client.id ? 'shadow-none' : 'shadow-[2px_2px_0px_0px_rgba(0,0,0,1)] sm:shadow-[4px_4px_0px_0px_rgba(0,0,0,1)]'}
            `}
          >
            {/* Status Badge */}
            <div className={`
              absolute top-2 sm:top-4 right-2 sm:right-4 px-2 sm:px-3 py-0.5 sm:py-1 text-xs sm:text-sm font-bold
              ${client.status === 'active' 
                ? 'bg-emerald-500 text-white' 
                : 'bg-yellow-500 text-white'
              }
            `}>
              {client.status.toUpperCase()}
            </div>

            <h3 className="text-lg sm:text-xl font-bold mb-3 sm:mb-4 pr-20 sm:pr-24">{client.name}</h3>
            
            <div className="space-y-1.5 sm:space-y-2">
              <div className="flex items-center gap-2 text-gray-600 text-sm sm:text-base break-all">
                <Mail className="h-4 w-4 flex-shrink-0" />
                {client.email}
              </div>
              <div className="flex items-center gap-2 text-gray-600 text-sm sm:text-base">
                <Phone className="h-4 w-4 flex-shrink-0" />
                {client.phone}
              </div>
              <div className="flex items-center gap-2 text-gray-600 text-sm sm:text-base">
                <MapPin className="h-4 w-4 flex-shrink-0" />
                {client.location}
              </div>
            </div>

            <div className="mt-3 sm:mt-4 pt-3 sm:pt-4 border-t border-gray-200 sm:border-t-2 flex justify-between">
              <div>
                <div className="text-xs sm:text-sm text-gray-500">Total Trips</div>
                <div className="text-lg sm:text-xl font-bold">{client.trips}</div>
              </div>
              <div>
                <div className="text-xs sm:text-sm text-gray-500">Revenue</div>
                <div className="text-lg sm:text-xl font-bold">${client.revenue.toLocaleString()}</div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Client Details Modal */}
      {selectedClient && (
        <ClientDetails
          client={mockClients.find(c => c.id === selectedClient)!}
          onClose={() => setSelectedClient(null)}
        />
      )}
    </div>
  );
}