export default function ArticleLoader() {
  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24">
      <div className="max-w-4xl mx-auto px-4">
        <div className="animate-pulse space-y-8">
          {/* Category */}
          <div className="h-8 bg-white/5 rounded-full w-32" />

          {/* Title */}
          <div className="space-y-4">
            <div className="h-12 bg-white/5 rounded-lg w-3/4" />
            <div className="h-12 bg-white/5 rounded-lg w-1/2" />
          </div>

          {/* Meta */}
          <div className="flex gap-4">
            <div className="h-6 bg-white/5 rounded w-32" />
            <div className="h-6 bg-white/5 rounded w-32" />
            <div className="h-6 bg-white/5 rounded w-32" />
          </div>

          {/* Featured Image */}
          <div className="aspect-[21/9] bg-white/5 rounded-2xl" />

          {/* Content */}
          <div className="space-y-4">
            {[...Array(6)].map((_, i) => (
              <div key={i} className="h-4 bg-white/5 rounded w-full" />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}