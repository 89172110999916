import { motion } from 'framer-motion';
import GoogleSignIn from '../auth/GoogleSignIn';

interface AuthBlurProps {
  children: React.ReactNode;
  isAuthenticated: boolean;
}

export default function AuthBlur({ children, isAuthenticated }: AuthBlurProps) {
  if (isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <div className="relative">
      {/* Blurred Content */}
      <div className="filter blur-md pointer-events-none">
        {children}
      </div>

      {/* Auth Overlay */}
      <div className="absolute inset-0 flex items-center justify-center">
        <motion.div 
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          className="bg-gray-900/90 backdrop-blur-lg p-8 rounded-2xl border border-white/10 shadow-2xl max-w-md w-full mx-4"
        >
          <h3 className="text-2xl font-bold text-white text-center mb-4">
            Sign in to View Your Recommendations
          </h3>
          <p className="text-gray-300 text-center mb-6">
            Create a free account to VIEW your 5 personalized travel recommendations and start planning your perfect trip!
          </p>
          <div className="flex justify-center">
            <GoogleSignIn />
          </div>
          <p className="text-sm text-gray-400 text-center mt-4">
            No credit card required. Sign in to access all features.
          </p>
        </motion.div>
      </div>
    </div>
  );
}