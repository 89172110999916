import { LucideIcon } from 'lucide-react';
import { useEffect, useRef } from 'react';
import anime from 'animejs';

interface InfoCardProps {
  icon: LucideIcon;
  title: string;
  value: string;
  gradient: string;
  delay?: number;
}

export default function InfoCard({ icon: Icon, title, value, gradient, delay = 0 }: InfoCardProps) {
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (cardRef.current) {
      anime({
        targets: cardRef.current,
        translateY: [20, 0],
        opacity: [0, 1],
        delay,
        duration: 800,
        easing: 'easeOutElastic(1, .8)'
      });
    }
  }, [delay]);

  return (
    <div 
      ref={cardRef}
      className="relative group transform transition-all duration-300 hover:scale-105"
    >
      {/* Card container */}
      <div className="relative h-full rounded-2xl bg-gray-900/60 backdrop-blur-sm border border-white/10 overflow-hidden">
        {/* Gradient overlay */}
        <div 
          className={`
            absolute inset-0 opacity-5 group-hover:opacity-10 transition-opacity duration-300
            bg-gradient-to-br ${gradient}
          `}
        />

        {/* Content */}
        <div className="relative p-6">
          {/* Header */}
          <div className="flex items-center gap-3 mb-4">
            <div 
              className={`
                p-3 rounded-xl bg-gradient-to-br ${gradient}
                shadow-lg group-hover:shadow-xl transition-all duration-300
              `}
            >
              <Icon className="h-5 w-5 text-white" />
            </div>
            <h3 className="text-lg font-semibold text-white">
              {title}
            </h3>
          </div>

          {/* Value */}
          <p className="text-gray-300 text-base leading-relaxed">
            {value}
          </p>

          {/* Decorative corner accent */}
          <div 
            className={`
              absolute -top-12 -right-12 w-24 h-24 rotate-45
              bg-gradient-to-br ${gradient} opacity-10
              group-hover:opacity-20 transition-opacity duration-300
            `}
          />
        </div>
      </div>
    </div>
  );
}