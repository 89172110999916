import { useState } from 'react';
import { format, isValid } from 'date-fns';
import {
  DndContext,
  DragOverlay,
  useSensor,
  useSensors,
  PointerSensor,
  KeyboardSensor,
  TouchSensor,
  MeasuringStrategy,
  defaultDropAnimationSideEffects
} from '@dnd-kit/core';
import axios from 'axios';
import { useAuth } from '../../contexts/AuthContext';
import Alert from '../ui/Alert';
import { ScheduleView } from '../Schedule';
import DraggableItem from './DraggableItem';
import DragOverlayItem from './DragOverlay';
import ItineraryHeader from './ItineraryHeader';
import ItineraryDetails from './ItineraryDetails';
import LocalTips from './LocalTips';
import { ChevronDown, ChevronUp } from 'lucide-react';
import type { Itinerary } from '../../types/itinerary';
import type { DraggableItemData, DroppableData } from '../../types/dnd';

interface ItineraryListProps {
  itineraries: Itinerary[];
  onRefresh: () => void;
}

export default function ItineraryList({ itineraries, onRefresh }: ItineraryListProps) {
  const { user } = useAuth();
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeDragData, setActiveDragData] = useState<DraggableItemData | null>(null);
  const [showActivities, setShowActivities] = useState(true);
  const [showDining, setShowDining] = useState(true);

  const touchBackendOptions = {
    enableMouseEvents: true, // Support both touch and mouse
    delayTouchStart: 150, // Delay before drag starts
    touchSlop: 20, // Pixels touch can move during delay
    enableHoverOutsideTarget: true,
    ignoreContextMenu: true,
    scrollAngleRanges: [
      { start: 30, end: 150 }, // Vertical scroll
      { start: 210, end: 330 } // Horizontal scroll prevention
    ]
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 100,
        tolerance: 5,
        distance: 8,
      },
    }),
    useSensor(TouchSensor, touchBackendOptions),
    useSensor(KeyboardSensor)
  );

  const handleDelete = async (id: string) => {
    if (!user?.googleId) return;

    setDeletingId(id);
    try {
      await axios.delete(`/api/users/itineraries/${id}`, {
        headers: { Authorization: `Bearer ${user.googleId}` }
      });
      onRefresh();
      setAlertMessage('Itinerary deleted successfully');
      setShowAlert(true);
    } catch (error) {
      console.error('Error deleting itinerary:', error);
      setAlertMessage('Failed to delete itinerary');
      setShowAlert(true);
    } finally {
      setDeletingId(null);
    }
  };

  const handleUpdateSchedule = async (itineraryId: string, days: any[]) => {
    if (!user?.googleId) return;

    try {
      await axios.put(
        `/api/users/itineraries/${itineraryId}`,
        { days },
        { headers: { Authorization: `Bearer ${user.googleId}` } }
      );
      onRefresh();
      setAlertMessage('Schedule updated successfully');
      setShowAlert(true);
    } catch (error) {
      console.error('Error updating schedule:', error);
      setAlertMessage('Failed to update schedule');
      setShowAlert(true);
    }
  };

  const handleDragStart = (event: any) => {
    document.body.style.overflow = 'hidden';
    const scheduleContainer = document.querySelector('.schedule-container') as HTMLElement;
    if (scheduleContainer) {
      scheduleContainer.style.overflow = 'auto';
    }
    
    setActiveId(event.active.id);
    setActiveDragData(event.active.data.current);
  };

  const handleDragEnd = (event: any) => {
    document.body.style.overflow = 'auto';
    const scheduleContainer = document.querySelector('.schedule-container') as HTMLElement;
    if (scheduleContainer) {
      scheduleContainer.style.overflow = 'auto';
    }

    const { active, over } = event;
    
    if (over && active.id !== over.id) {
      const dropData = over.data.current as DroppableData;
      if (dropData?.date) {
        // Handle dropping onto schedule
        const itinerary = itineraries.find(i => expandedId === i._id);
        if (itinerary) {
          const days = [...(itinerary.days || [])];
          const dayIndex = days.findIndex(d => d.date === dropData.date);
          
          const newItem = {
            type: activeDragData?.type,
            title: activeDragData?.title,
            description: activeDragData?.description,
            startTime: '09:00', // Default time
            endTime: '10:00',
            location: activeDragData?.location
          };

          if (dayIndex >= 0) {
            days[dayIndex].schedule = [...days[dayIndex].schedule, newItem];
          } else {
            days.push({
              date: dropData.date,
              schedule: [newItem]
            });
          }

          handleUpdateSchedule(itinerary._id, days);
        }
      }
    }

    setActiveId(null);
    setActiveDragData(null);
  };

  const formatDate = (dateString: string) => {
    try {
      const date = new Date(dateString);
      if (!isValid(date)) {
        return 'Invalid date';
      }
      return format(date, 'MMM d, yyyy');
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'Invalid date';
    }
  };

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always
        }
      }}
    >
      <div className="space-y-6">
        {showAlert && (
          <Alert
            type={alertMessage.includes('success') ? 'success' : 'error'}
            message={alertMessage}
            onClose={() => setShowAlert(false)}
          />
        )}

        {itineraries.map((itinerary) => (
          <div
            key={itinerary._id}
            className="bg-gray-900/60 backdrop-blur-sm rounded-xl overflow-hidden border border-white/10 hover:border-accent-500/30 transition-all duration-300"
          >
            <div className="p-6">
              <ItineraryHeader
                title={itinerary.title}
                destination={itinerary.destination.name}
                startDate={itinerary.startDate}
                endDate={itinerary.endDate}
                daysCount={itinerary.days?.length || 0}
                isExpanded={expandedId === itinerary._id}
                isDeleting={deletingId === itinerary._id}
                onDelete={() => handleDelete(itinerary._id)}
                onToggleExpand={() => setExpandedId(expandedId === itinerary._id ? null : itinerary._id)}
              />

              {expandedId === itinerary._id && (
                <div className="mt-6 border-t border-white/10 pt-6 space-y-4">
                  {itinerary.description && (
                    <div className="bg-white/5 rounded-lg p-4">
                      <p className="text-gray-300">{itinerary.description}</p>
                    </div>
                  )}

                  <ItineraryDetails destination={itinerary.destination} />

                  {/* Draggable Activities */}
                  <div className="space-y-2">
                    <div className="flex items-center gap-2">
                      <h4 className="text-lg font-medium text-white flex items-center gap-2">
                        <button 
                          onClick={() => setShowActivities(!showActivities)}
                          className="p-1.5 bg-accent-500/10 text-accent-400 hover:text-gray-400 rounded-lg transition-all duration-200"
                        >
                          {showActivities ? (
                            <ChevronUp className="h-4 w-4 stroke-[2.5]" />
                          ) : (
                            <ChevronDown className="h-4 w-4 stroke-[2.5]" />
                          )}
                        </button>
                        Activities
                      </h4>
                    </div>
                    {showActivities && (
                      <div className="overflow-x-auto bg-emerald-500/5 rounded-lg p-4">
                        <div className="flex gap-3 pb-4 transition-transform duration-300 ease-in-out md:flex-row">
                          {itinerary.destination.savedActivitySuggestions?.map((activity) => (
                            <div className="w-full md:w-80 flex-shrink-0">
                              <DraggableItem
                                key={activity._id}
                                id={activity._id}
                                type="activity"
                                title={activity.title}
                                description={activity.description}
                                duration={activity.duration}
                                location={activity.location}
                                price={activity.price}
                                links={activity.links}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Draggable Dining */}
                  {itinerary.destination.savedDining?.length > 0 && (
                    <div className="space-y-2">
                      <div className="flex items-center gap-2">
                        <h4 className="text-lg font-medium text-white flex items-center gap-2">
                          <button 
                            onClick={() => setShowDining(!showDining)}
                            className="p-1.5 bg-accent-500/10 text-accent-400 hover:text-gray-400 rounded-lg transition-all duration-200"
                          >
                            {showDining ? (
                              <ChevronUp className="h-4 w-4 stroke-[2.5]" />
                            ) : (
                              <ChevronDown className="h-4 w-4 stroke-[2.5]" />
                            )}
                          </button>
                          Dining
                        </h4>
                      </div>
                      {showDining && (
                        <div className="overflow-x-auto bg-orange-500/5 rounded-lg p-4">
                          <div className="flex gap-3 pb-4 transition-transform duration-300 ease-in-out md:flex-row">
                            {itinerary.destination.savedDining.map((dining) => (
                              <div className="w-full md:w-80 flex-shrink-0">
                                <DraggableItem
                                  key={dining._id}
                                  id={dining._id}
                                  type="dining"
                                  title={dining.name}
                                  description={dining.description}
                                  location={dining.location}
                                  rating={dining.rating}
                                  links={dining.links}
                                  specialties={dining.specialties}
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  {/* Schedule */}
                  <div className="space-y-2">
                    <h4 className="text-lg font-medium text-white">Schedule</h4>
                    <ScheduleView
                      startDate={'2024-12-10T00:00:00.000Z'}
                      endDate={'2024-12-20T00:00:00.000Z'}
                      days={itinerary.days || []}
                      onUpdateSchedule={(days) => handleUpdateSchedule(itinerary._id, days)}
                    />
                  </div>

                  <LocalTips tips={itinerary.destination.localTips} />

                  <div className="text-sm text-gray-400">
                    Created on {formatDate(itinerary.createdAt)}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}

        {/* Drag Overlay */}
        <DragOverlay dropAnimation={null}>
          {activeId && activeDragData && (
            <DragOverlayItem {...activeDragData} />
          )}
        </DragOverlay>
      </div>
    </DndContext>
  );
}