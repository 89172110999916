import { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowRight, Plus, Info } from 'lucide-react';
import axios from 'axios';
import TravelRecommendations from '../components/TravelRecommendations';
import SEOHead from '../components/SEO/SEOHead';
import StructuredData from '../components/SEO/StructuredData';

export default function Share() {
  const { shareId } = useParams<{ shareId: string }>();
  const [sharedData, setSharedData] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadSharedData();
  }, [shareId]);

  const loadSharedData = async () => {
    try {
      if (!shareId) {
        throw new Error('Invalid share link');
      }

      const response = await axios.get(`/api/share/${shareId}`);
      setSharedData(response.data);
    } catch (err: any) {
      console.error('Error loading shared data:', err);
      setError(
        err.response?.status === 404 
          ? 'This share link has expired or does not exist.'
          : 'Unable to load shared recommendations. Please try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getPageTitle = () => {
    if (isLoading) return 'Loading Shared Recommendations | WanderWise';
    if (error) return 'Invalid Share Link | WanderWise';
    if (sharedData?.recommendation?.destinations?.length > 0) {
      const destinations = sharedData.recommendation.destinations
        .map((d: any) => d.name)
        .join(', ');
      return `Travel Plan: ${destinations} | WanderWise`;
    }
    return 'Shared Travel Recommendations | WanderWise';
  };

  const getPageDescription = () => {
    if (isLoading) return 'Loading shared travel recommendations...';
    if (error) return 'This share link has expired or does not exist.';
    if (sharedData?.recommendation?.travelStyle) {
      return `${sharedData.recommendation.travelStyle}. View these personalized travel recommendations and start planning your trip.`;
    }
    return 'View shared travel recommendations and start planning your trip with WanderWise.';
  };

  if (isLoading) {
    return (
      <>
        <SEOHead
          title={getPageTitle()}
          description={getPageDescription()}
          canonicalUrl={`https://wanderwise.com/share/${shareId}`}
        />
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <div className="w-16 h-16 border-4 border-accent-500 border-t-transparent rounded-full animate-spin mx-auto mb-4" />
              <p className="text-xl text-gray-300">
                Loading shared recommendations...
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (error || !sharedData) {
    return (
      <>
        <SEOHead
          title={getPageTitle()}
          description={getPageDescription()}
          canonicalUrl={`https://wanderwise.com/share/${shareId}`}
        />
        <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center">
              <h1 className="text-4xl font-bold text-white mb-4">
                Invalid Share Link
              </h1>
              <p className="text-xl text-gray-300 mb-8">
                {error || 'Unable to load shared recommendations.'}
              </p>
              <Link
                to="/"
                className="inline-flex items-center gap-2 px-6 py-3 bg-accent-500 text-white rounded-lg hover:bg-accent-600 transition-colors"
              >
                Get Your Own Recommendations
                <ArrowRight className="h-5 w-5" />
              </Link>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <SEOHead
        title={getPageTitle()}
        description={getPageDescription()}
        canonicalUrl={`https://wanderwise.com/share/${shareId}`}
      />
      <StructuredData
        type="WebPage"
        data={{
          name: getPageTitle(),
          description: getPageDescription(),
          url: `https://wanderwise.com/share/${shareId}`
        }}
      />
      
      {/* Get Your Own Banner */}
      <div className="sticky top-0 left-0 right-0 z-50 bg-gradient-to-r from-accent-500 to-primary-600 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2 sm:py-3">
          <div className="flex flex-col sm:flex-row items-center justify-between gap-2 sm:gap-4">
            <p className="text-sm sm:text-base font-medium text-center sm:text-left">
              Want personalized travel recommendations?
            </p>
            <Link
              to="/"
              className="flex items-center gap-2 px-4 py-1.5 bg-white/10 hover:bg-white/20 rounded-lg transition-colors text-sm whitespace-nowrap"
            >
              Get Your Destinations
              <ArrowRight className="h-4 w-4" />
            </Link>
          </div>
        </div>
      </div>

      {/* Trip Slip Notice */}
      <div className="sticky top-[72px] sm:top-12 left-0 right-0 z-50 bg-gradient-to-r from-accent-500 to-primary-600 text-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-2 sm:py-3">
          <div className="flex flex-wrap items-center gap-2 sm:gap-3 justify-center text-xs sm:text-sm text-accent-300">
            <Info className="h-4 w-4 flex-shrink-0" />
            <span>Click</span>
            <div className="flex items-center justify-center w-6 h-6 rounded-lg bg-accent-500/20">
              <Plus className="h-4 w-4" />
            </div>
            <span>on any destination to add it to your Trip Slip</span>
          </div>
        </div>
      </div>
      
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 pt-24 pb-24">
        <TravelRecommendations
          recommendation={sharedData.recommendation}
          surveyResponses={sharedData.surveyResponses}
          onStartOver={() => window.location.href = '/'}
        />
      </div>
    </>
  );
}