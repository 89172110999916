import { format, addDays } from 'date-fns';
import DateCard from './DateCard';

interface DateNavigationProps {
  startDate: string;
  endDate: string;
  selectedDate: string | null;
  onDateSelect: (date: string) => void;
  getItemCount: (date: string) => number;
}

export default function DateNavigation({
  startDate,
  endDate,
  selectedDate,
  onDateSelect,
  getItemCount
}: DateNavigationProps) {
  // Generate array of dates between start and end
  const dateRange: string[] = [];
  let currentDate = new Date(startDate);
  const endDateObj = new Date(endDate);
  
  while (currentDate <= endDateObj) {
    dateRange.push(currentDate.toISOString().split('T')[0]);
    currentDate = addDays(currentDate, 1);
  }

  return (
    <div className="flex gap-4 overflow-x-auto pb-4 -mx-4 px-4 scrollbar-thin scrollbar-thumb-accent-500/20 scrollbar-track-white/5">
      {dateRange.map((date) => {
        const itemCount = getItemCount(date);
        return (
          <DateCard
            key={date}
            date={date}
            isSelected={selectedDate === date}
            hasItems={itemCount > 0}
            onClick={() => onDateSelect(date)}
            itemCount={itemCount}
          />
        );
      })}
    </div>
  );
}