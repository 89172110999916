import { useParams } from 'react-router-dom';
import { useArticle } from '../hooks/useArticle';
import SEOHead from '../components/SEO/SEOHead';
import StructuredData from '../components/SEO/StructuredData';
import ArticleHeader from '../components/blog/ArticleHeader';
import ArticleContent from '../components/blog/ArticleContent';
import ArticleSidebar from '../components/blog/ArticleSidebar';
import ArticleLoader from '../components/blog/ArticleLoader';
import ArticleError from '../components/blog/ArticleError';

export default function BlogPost() {
  const { slug } = useParams<{ slug: string }>();
  const { article, isLoading, error } = useArticle(slug!);

  if (isLoading) {
    return <ArticleLoader />;
  }

  if (error || !article) {
    return <ArticleError error={error} />;
  }

  const pageData = {
    '@type': 'BlogPosting',
    headline: article.headline,
    description: article.metaDescription,
    image: article.image,
    datePublished: article.publishedAt,
    dateModified: article.updatedAt,
    author: {
      '@type': 'Organization',
      name: 'WanderWise'
    }
  };

  return (
    <>
      <SEOHead
        title={article.headline}
        description={article.metaDescription}
        canonicalUrl={`https://wanderwise.com/blog/${article.slug}`}
        ogImage={article.image}
      />
      <StructuredData type="BlogPosting" data={pageData} />

      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 py-24">
        <article className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-4xl mx-auto">
            <ArticleHeader article={article} />
            
            <div className="mt-8 grid grid-cols-1 lg:grid-cols-[1fr_300px] gap-8">
              <ArticleContent html={article.html} />
              <ArticleSidebar
                outline={article.outline}
                relatedPosts={article.relatedPosts}
                tags={article.tags}
              />
            </div>
          </div>
        </article>
      </div>
    </>
  );
}